import store from "redux/store";
import {GAME_EVENT_TYPES, GAME_STEPS, GAME_STEPS_WITH_PITCH} from "config/CONSTANTS";
import CardUtil from "utils/CardUtil";
import {i18nService} from "i18n";
import GameUtil from "utils/GameUtil";

let gameEventList = [];

function getT() {
    return i18nService.getFixedT({
        ns: 'pages/common/board/game_events',
        keyPrefix: 'services.game_events_service'
    });
}

function createCardForQuestion(question, questionteam) {
    return {id: question.card.id, team: questionteam, type: GAME_EVENT_TYPES.QUESTION, data: question};
}

function createCardForPitchBonus(data) {
    return {id: 'p' + data.step, type: GAME_EVENT_TYPES.PITCH_BONUS, data};
}

function createCardForPitchInvestmentReceived(data) {
    return {id: 'pb' + data.step, type: GAME_EVENT_TYPES.PITCH_INVESTMENT, data};
}

function createPhaseSeparator(data) {
    return {id: 'ss' + data.phase, type: GAME_EVENT_TYPES.PHASE_SEPARATOR, data};
}

function createUnpredictability(data) {
    return {id: 'u' + data.card.id + data.step, type: GAME_EVENT_TYPES.UNPREDICTABILITY, data};
}

function createInfo(data) {
    return {id: data, type: GAME_EVENT_TYPES.INFO, data};
}

const GameEventsComponentService = {
    touchEventsList() {
        const t = getT();

        gameEventList = [createInfo(t('begins_now'))];

        const isEsg = GameUtil.isCurrentGameEsg();
        const isDemonstration = store.getState().game.isDemonstration;
        const currentStep = store.getState().game.currentStep;
        const team = store.getState().session.showBoardForTeam;
        const teamData = store.getState().teams[team];
        const ranking = store.getState().game.ranking;

        const phaseSeparatorsAdded = [];

        for (let i = GAME_STEPS.ONBOARDING; i < GAME_STEPS.END_GAME && i <= currentStep; i++) {
            const phase = CardUtil.getGamePhaseForStep(i);
            let eventsToAdd = [];

            // pitch bonus
            if (teamData.pitchBonus[i])
                eventsToAdd.push(createCardForPitchBonus({bonus: teamData.pitchBonus[i], step: i}));


            // questions
            if (teamData?.questions?.[i]?.showResult === true)
                eventsToAdd.push(createCardForQuestion(teamData.questions[i], team))


            // pitch static investment
            if (!isDemonstration
                && GAME_STEPS_WITH_PITCH.includes(i)
                && currentStep > i
                && (i !== GameUtil.getLastPitchStep()  || ranking?.[team]?.show === true)) {

                const investmentReceived = GameUtil.getPitchInvestmentReceived(team, i);

                if (investmentReceived) {
                    eventsToAdd.push(createCardForPitchInvestmentReceived({
                        bonus: investmentReceived,
                        step: i,
                    }));
                }
            }


            // unpredictabilities
            if (teamData.unpredictabilities[i]?.reveal && teamData.unpredictabilities[i]?.revealOutcome === true)
                eventsToAdd.push(createUnpredictability(teamData.unpredictabilities[i]));


            // add separators
            if (eventsToAdd.length > 0 && !phaseSeparatorsAdded.includes(phase)) {
                eventsToAdd.unshift(createPhaseSeparator({phase}));
                phaseSeparatorsAdded.push(phase);
            }

            gameEventList = [...gameEventList, ...eventsToAdd];
        }


        if (currentStep >= GAME_STEPS.END_GAME) {
            let didRevealAllRankings = true;
            if (ranking) {
                const objValue = Object.values(ranking);

                for (let i = 0; i < objValue.length; i++) {
                    if (objValue[i]?.show !== true) {
                        didRevealAllRankings = false;
                        break;
                    }
                }
            } else {
                didRevealAllRankings = false;
            }

            if (didRevealAllRankings) {
                gameEventList.push(createInfo(t('finished')));
            }
        }

        return gameEventList;
    }
};

export default GameEventsComponentService;