import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    state: {
        hasShownQuestionInfoCard: false,
        hasFinishedConception: false,
        showAvailableForMarketChallenge: false,
        showAccountingChallenge: false,
        showStepChangeTransition: false,
    },

    advanceButtonState: {
        show: false,
        callback: null,
        isLoading: false,
    }
};

export const TournamentSlice = createSlice({
    name: 'tournament',
    initialState,
    reducers: {
        updateTournamentState(state, {payload}) {
            state.state = {...state.state, ...payload};
        },

        updateAdvanceButtonState(state, {payload}) {
            state.advanceButtonState = {...state.advanceButtonState, ...payload};
        }
    }
});

export const {
    updateTournamentState,
    updateAdvanceButtonState
} = TournamentSlice.actions;


export default TournamentSlice.reducer;