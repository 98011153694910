import React, {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BugReportModal from "../../BugReportModal/BugReportModal";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import AdjustVolumeModal from "../../AdjustVolumeModal/AdjustVolumeModal";
import ButtonToggleMobile from "../../../player/Lobby/components/ButtonToggleMobile";

export default function Footer({textClass = 'text-white-50'}) {
    const {t} = useTranslation('common');
    const now = (new Date()).getUTCFullYear();

    const volumeConfig = useSelector(state => state.session.volumeConfig);
    const isDemonstration = useSelector(state => state.game.isDemonstration);

    const [showBugReportModal, setShowBugReportModal] = useState(false);
    const handleShowBugReportModal = () => setShowBugReportModal(true);
    const handleCloseBugReportModal = () => setShowBugReportModal(false);

    const [showAdjustVolumeModal, setShowAdjustVolumeModal] = useState(false);
    const handleShowAdjustVolumeModal = () => setShowAdjustVolumeModal(true);
    const handleCloseAdjustVolumeModal = () => setShowAdjustVolumeModal(false);

    const volumeButtonStyle = useMemo(() => {
        const hasVolume = !volumeConfig.isMuted && volumeConfig.volume > 0;

        return {
            class: hasVolume ? textClass : 'btn-outline-danger',
            valueToDisplay: (!volumeConfig.isMuted ? Math.floor(volumeConfig.volume * 100) : 0),
            icon: hasVolume ? 'volume-up' : 'volume-mute'
        }
    }, [volumeConfig]);

    return (
        <>
            <footer id="app-footer" className={`position-relative bottom right text-center ${textClass}`}>
                <p className="mx-3 mb-0 p-0 small">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className={`text-decoration-underline ml-1 ${textClass}`}
                       target="_blank" rel="noreferrer">
                        Mundi Game Experiences</a>.

                    <br/>
                    {t('rights_reserved')}
                </p>

                <div className="d-flex flex-wrap justify-content-center" style={{gap: 5}}>
                    <button className={`btn p-0 btn-report-bug ${textClass}`}  onClick={handleShowBugReportModal}>

                        <FontAwesomeIcon icon={['fas', 'bug']}/> {t('btn_report_bug')}
                    </button>

                    {!isDemonstration && (
                        <ButtonToggleMobile className={`btn btn-toggle-mobile border-0 p-0 ${textClass}`}/>
                    )}

                    <button className={`btn btn-adjust-volume small py-0 px-1 ${volumeButtonStyle.class}`}
                            onClick={handleShowAdjustVolumeModal}>

                        <FontAwesomeIcon icon={['fas', volumeButtonStyle.icon]} className="mr-1"/>
                        {t('adjust_volume', {volume: volumeButtonStyle.valueToDisplay})}
                    </button>
                </div>
            </footer>

            {showBugReportModal && (<BugReportModal onClose={handleCloseBugReportModal}/>)}
            {showAdjustVolumeModal && (<AdjustVolumeModal onClose={handleCloseAdjustVolumeModal}/>)}
        </>
    );
}