import {
    CHALLENGE_TYPE,
    GAME_STEPS,
    GAME_STEPS_WITH_PITCH,
    GAME_STEPS_WITH_QUESTIONS,
    IS_LOCAL_ENV
} from "config/CONSTANTS";
import store from "redux/store";
import {i18nService} from "i18n";
import GameUtil from "utils/GameUtil";
import i18nUtil from "utils/i18nUtil";
import TournamentService from "services/common/TournamentService";
import SwalModalUtil from "utils/SwalModalUtil";
import OverlayUtil from "utils/OverlayUtil";
import Utils from "utils/Utils";
import SkillCardModel from "models/SkillCardModel";
import TeamService from "services/player/TeamService";
import ToastUtil from "utils/ToastUtil";
import {setSelectedMobileTab} from "redux/slices/sessionSlice";
import {MOBILE_GAME_TABS} from "config/MOBILE";

// if the money available for market is more than the set value, show a confirm before going to the next step
const MIN_AVAILABLE_TO_MARKET_TO_NOTIFY = 5000;

let _showAdvanceButton = false;


function getT() {
    return i18nService.getFixedT({
        lang: 'pt',
        ns: 'services/tournament/tournament_challenges'
    });
}

async function skipConceptionCallback() {
    OverlayUtil.show();

    const startup = Utils.randomFromArray(store.getState().cards.startups);
    const founder1 = Utils.randomFromArray(store.getState().cards.skills);
    const founder1Icon = SkillCardModel.randomIconNumber();
    const founder2 = Utils.randomFromArray(store.getState().cards.skills);
    const founder2Icon = SkillCardModel.randomIconNumber();

    const vp1 = Utils.randomFromArray(store.getState().cards.valuePropositions);
    const vp2 = Utils.randomFromArray(store.getState().cards.valuePropositions);

    await Promise.all([
        TeamService.updateStartup({startup: startup.id}),
        TeamService.updateFounder({founder: {id: founder1.id, number: 0, icon: founder1Icon}}),
        TeamService.updateFounder({founder: {id: founder2.id, number: 1, icon: founder2Icon}}),
        TeamService.updateValueProposition({valueProposition: {id: vp1.id, number: 0}}),
        TeamService.updateValueProposition({valueProposition: {id: vp2.id, number: 1}}),
    ]);

    OverlayUtil.hide();
}

async function advanceGame() {
    if (!await checkAvailableForMarket())
        return false;

    if (!await TournamentService.advanceGame()) {
        ToastUtil.toastDanger('Não foi possível avançar o jogo');
        return false;
    }

    // TournamentService.touchAdvanceButtonState({
    //     show: false,
    //     callback: null,
    // });

    store.dispatch(setSelectedMobileTab(MOBILE_GAME_TABS.CHALLENGES));

    return true;
}

async function checkAvailableForMarket() {
    const tournamentState = store.getState().tournament.state;

    if (!tournamentState.showAvailableForMarketChallenge || tournamentState.showAccountingChallenge)
        return true;


    const showBoardForTeam = store.getState().session.showBoardForTeam;
    const availableForMarket = store.getState().teams[showBoardForTeam].accounting?.totals?.availableForMarket;

    if (availableForMarket <= MIN_AVAILABLE_TO_MARKET_TO_NOTIFY)
        return true;

    const availableForMarketFormatted = i18nUtil.formatMoney(availableForMarket);

    return SwalModalUtil.confirmModal(
        ' Ainda existem recursos disponíveis para contratação.',
        `Você possui ${availableForMarketFormatted} créditos para mercado disponíveis. Deseja continuar assim mesmo?`,
        'Sim, continuar jogo',
        'Não, abrir o mercado').then(res => {

        if (res)
            return true;


        openMarket();

        return false;
    });
}

function advanceGameSecondaryButton() {
    if (store.getState().session.isMobile) {
        _showAdvanceButton = true;

        return {};
    }

    return {
        showSecondaryActionButton: true,
        showLoadingOnSecondaryClick: true,
        secondaryActionButtonText: 'Avançar jogo',
        secondaryActionButtonClass: 'btn btn-success shadow',
        secondaryActionCallback: advanceGame,
    }
}

async function advanceGameForMarketChallenge() {
    return new Promise(resolve => {
        const showBoardForTeam = store.getState().session.showBoardForTeam;
        const availableForMarket = store.getState().teams[showBoardForTeam].accounting?.totals?.availableForMarket;

        if (availableForMarket > MIN_AVAILABLE_TO_MARKET_TO_NOTIFY) {
            const availableForMarketFormatted = i18nUtil.formatMoney(availableForMarket);

            SwalModalUtil.confirmModal(
                ' Ainda existem recursos disponíveis para contratação.',
                `Você possui ${availableForMarketFormatted} créditos para mercado disponíveis. Deseja continuar assim mesmo?`,
                'Sim, continuar jogo',
                'Não, abrir o mercado').then(res => {

                if (res) {
                    resolve(advanceGame());
                } else {
                    openMarket();
                    resolve(false);
                }
            });
        } else {
            resolve(advanceGame());
        }
    });
}


function openMarket() {
    const btnToClick = store.getState().session.isMobile
        ? 'btn-hirings-details'
        : 'btn-open-skill-market';

    document.getElementById(btnToClick).click();
}

function tfg(key, params = {}) {
    return i18nUtil.translateForGame(getT(), key, params);
}

function conditionallyAppendIsMobile(text) {
    return store.getState().session.isMobile
        ? text + '_mobile'
        : text;
}


const ChallengesComponentService = {
    touchChallengesList(handleShowConceptionModal, handleShowTournamentPitchModal) {
        const t = getT();
        let res = [];

        const isEsg = GameUtil.isCurrentGameEsg();
        const currentStep = store.getState().game.currentStep;
        const tournamentState = store.getState().tournament.state;

        const showBoardForTeam = store.getState().session.showBoardForTeam;
        const teamData = store.getState().teams[showBoardForTeam];

        const signedAccounting = teamData.signedAccounting;
        const hiredAccountingService = teamData.hiredAccountingService;

        _showAdvanceButton = false;

        // -------------------- CONCEPTION --------------------
        if (currentStep === GAME_STEPS.CONCEPTION) {
            if (teamData && teamData.startup && teamData.founders && teamData.founders[0] && teamData.founders[1]
                && teamData.valuePropositions && teamData.valuePropositions[0] && teamData.valuePropositions[1]) {

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: i18nUtil.translateForGame(t, 'conception_done.title'),
                        description: i18nUtil.translateForGame(
                            t,
                            conditionallyAppendIsMobile('conception_done.mess' + 'age')
                        ),

                        showLoadingOnClick: false,

                        // primary action
                        actionButtonText: 'Alterar escolhas',
                        actionCallback: handleShowConceptionModal,

                        // secondary action
                        ...advanceGameSecondaryButton()
                    }
                });
            } else {
                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: tfg('conception.title'),
                        description: tfg('conception.message'),
                        showLoadingOnClick: false,

                        // primary action
                        actionButtonText: "Abrir escolhas",
                        actionCallback: handleShowConceptionModal,

                        // secondary action
                        showSecondaryActionButton: IS_LOCAL_ENV,
                        secondaryActionButtonText: 'Pular concepção',
                        secondaryActionCallback: skipConceptionCallback,
                    }
                });
            }
        }


        // -------------------- END GAME --------------------
        if (currentStep === GAME_STEPS.END_GAME) {
            const ranking = store.getState().game?.ranking?.[1];

            res.push({
                type: CHALLENGE_TYPE.TOURNAMENT_ENDING,
                data: {}
            });

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: 'Certificado e Resumo',
                    description: 'Antes de apresentar o resultado alcançado no torneio, temos uma boa notícia.  <br/><br/>  Em breve receberá por email um link para acessar o resumo da partida e outro o seu certificado de participação. <br/><br/> Mais que merecido!',
                }
            })

            if (ranking && ranking.globalRank) {
                const revenue = i18nUtil.formatMoney(ranking.revenue);

                res.push({
                    type: CHALLENGE_TYPE.TOURNAMENT_RANKING,
                    data: {
                        ranking: ranking.globalRank,
                        revenue
                    }
                });
            }


            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: 'Jogar um nova rodada',
                    description: i18nUtil.translateForGame(t, 'play_again.message'),
                    actionButtonText: 'Jogar novamente',
                    showLoadingOnClick: true,
                    actionCallback: () => {
                        return SwalModalUtil.confirmModal(
                            'A sessão atual será finalizada',
                            'Deseja continuar assim mesmo?',
                            'Sim, continuar',
                            'Ainda não'
                        ).then(async res => {
                            if (res)
                                return TournamentService.closeSession();
                        })
                    }
                }
            });

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: 'Este jogo terminou!',
                    description: 'Obrigado por jogar. Que possamos nos reencontrar em breve!',
                    actionButtonText: 'Finalizar sessão de jogo',
                    showLoadingOnClick: true,
                    actionCallback: async () => {
                        return TournamentService.closeSession();
                    }
                }
            });
        }


        // -------------------- PITCH --------------------
        if (GAME_STEPS_WITH_PITCH.includes(currentStep)) {
            const description = tfg('pitch_info.' + currentStep.toString() + '.message');

            const secondaryButtonData = !!teamData.pitchData?.[currentStep]
                ? advanceGameSecondaryButton()
                : {};

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: 'Pitch',
                    description,
                    actionButtonText: 'Escolher',
                    actionCallback: handleShowTournamentPitchModal,
                    ...secondaryButtonData
                }
            });
        }


        // -------------------- STEPS WITH QUESTIONS --------------------
        if (GAME_STEPS_WITH_QUESTIONS.includes(currentStep)) {

            if (teamData?.questions?.[currentStep]?.showResult !== true && !tournamentState.hasShownQuestionInfoCard) {
                const title = tfg('step_challenge_info.' + currentStep.toString() + '.title');
                const description = tfg('step_challenge_info.' + currentStep.toString() + '.message');
                const actionButtonText = 'Avançar';

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title,
                        description,
                        actionButtonText,
                        actionCallback: () => TournamentService.touchTournamentState({hasShownQuestionInfoCard: true}),
                    }
                });
            } else if (tournamentState.showAccountingChallenge) {
                let descriptionKey = 'accounting_info.' + currentStep.toString() + '.message';

                // these steps use a custom text for the mobile game
                if ((!isEsg && currentStep === GAME_STEPS.MVP) || (isEsg && currentStep === GAME_STEPS.BETA))
                    descriptionKey = conditionallyAppendIsMobile(descriptionKey);


                const title = tfg('accounting_info.' + currentStep.toString() + '.title');
                const description = tfg(descriptionKey);

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title,
                        description,
                        showLoadingOnClick: true,

                        // primary action
                        actionButtonText: tfg('accounting_info.show'),
                        actionCallback: async () => {
                            document.getElementById('btn-accounting-details').click()
                            return false;
                        },

                        // secondary action
                        ...advanceGameSecondaryButton()
                    }
                });
            } else if (tournamentState.showAvailableForMarketChallenge) {
                const lastQuestion = teamData?.questions?.[currentStep];
                const investmentConfig = lastQuestion?.card?.getInvestmentConfig();
                const wasQuestionCorrect = lastQuestion?.isCorrect === true;

                const hiredServices = teamData.services[currentStep];
                const hiredSkills = teamData.skills[currentStep];
                const canContinueGame = (hiredServices && hiredServices.length > 0) || (hiredSkills && hiredSkills.length > 0);

                const investmentReceived = wasQuestionCorrect
                    ? investmentConfig?.investmentRightAnswer
                    : investmentConfig?.investmentWrongAnswer;

                const parsedInvestment = i18nUtil.formatMoney(investmentReceived ?? 0);

                const title = tfg('market_info.' + currentStep.toString() + '.title', {amount: parsedInvestment});
                let description = tfg('market_info.' + currentStep.toString() + '.message', {amount: parsedInvestment});

                if (!canContinueGame)
                    description += '<br/><br/> <b class="text-warning">Contrate talentos e serviços para continuar o jogo</b>';

                const secondaryButton = canContinueGame
                    ? advanceGameSecondaryButton()
                    : {};


                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title,
                        description,
                        showLoadingOnClick: canContinueGame,

                        // primary action
                        actionButtonText: 'Contratar',
                        actionCallback: openMarket,


                        ...secondaryButton
                    },
                });
            } else if (!teamData.unpredictabilities[currentStep]) {
                const teamQuestion = teamData?.questions[currentStep];

                if (teamQuestion)
                    res.push({type: CHALLENGE_TYPE.QUESTION, data: teamQuestion});

                if (teamQuestion.showResult === true)
                    advanceGameSecondaryButton();

                // TODO: check if accounting message is being displayed

                // if (teamQuestion && (teamQuestion?.showResult !== true || currentStep.toString() === teamQuestion.step)) {
                //     res.push({type: CHALLENGE_TYPE.QUESTION, data: teamQuestion});
                // } else if (!GameUtil.isCurrentGameEsg() && teamQuestion?.showResult === true && (!signedAccounting[currentStep] && !hiredAccountingService)) {
                //     // appendAccountingMessage = true;
                // }
            } else {
                const teamUnpredictability = teamData?.unpredictabilities[currentStep];

                if (teamUnpredictability)
                    res.push({type: CHALLENGE_TYPE.UNPREDICTABILITY, data: teamUnpredictability});

                if (teamUnpredictability?.reveal && teamUnpredictability?.revealOutcome)
                    advanceGameSecondaryButton();
            }
        }


        TournamentService.touchAdvanceButtonState({
            show: _showAdvanceButton,
            callback: advanceGame,
        });

        return res;
    }
};

export default ChallengesComponentService;