import React from 'react';
import {useSelector} from "react-redux";
import Utils from "../../../../utils/Utils";
import {useTranslation} from "react-i18next";

export default function SessionInfo() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.header'});
    const theme = useSelector(state => state.theme);
    const gameUrl = useSelector(state => state.game.gameInfo.gameUrl);
    const callUrl = useSelector(state => state.game.gameInfo.callUrl);
    const sessionName = useSelector(state => state.game.gameInfo.name);
    const startAt = useSelector(state => state.game.gameInfo.startAt);

    return (
        <div className="d-flex justify-content-center align-items-center py-1 w-100">
            <img src={theme.logos.icon} alt="Mundi Game Experiences logo" className="mr-3" width={50}/>

            <div>
                <h4 className="m-0 font-weight-bold">{t('texts.page_title')}</h4>
                {sessionName && (<h5 className="mb-1 text-break session-name">{sessionName}</h5>)}
                {startAt && (<h6 className="mb-0">
                    {Utils.utcDateTimeToLocalDateStringWithoutSeconds(startAt)}
                </h6>)}
            </div>
        </div>
    )
}