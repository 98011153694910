import React from 'react';

import icon from 'assets/img/board/ic_investment_accounting_box.svg';
import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";

export default function SmgxSummaryContent() {
    const {t} = useTranslation('pages/common/board/accounting');

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.totals);

    return (
        <div className="box-content account">
            <h4 className="text-white mb-3">{t('texts.available_for_market')}</h4>

            <div className="market-value d-flex justify-content-between my-3 px-2">
                <img alt="Market value logo" src={icon}/>
                <p className="text-warning">{i18nUtil.formatMoney(accountingDetails.availableForMarket || 0)}</p>
            </div>

            <table>
                <tbody>
                <tr>
                    <td>{t('texts.capital')}</td>
                    <td>{i18nUtil.formatMoney(accountingDetails.capital || 0)}</td>
                </tr>
                <tr>
                    <td>{t('texts.expenses')}</td>
                    <td>{i18nUtil.formatMoney(accountingDetails.totalCost || 0)}</td>
                </tr>
                <tr>
                    <td>{t('texts.revenue')}</td>
                    <td>{i18nUtil.formatMoney(accountingDetails.revenue || 0)}</td>
                </tr>
                <tr>
                    <td>{t('texts.balance')}</td>
                    <td>{i18nUtil.formatMoney(accountingDetails.balance || 0)}</td>
                </tr>
                <tr>
                    <td>{t('texts.market_investment_rate')}</td>
                    <td>{i18nUtil.formatPercent(accountingDetails.marketInvestmentRate || 0)}</td>
                </tr>

                <tr>
                    <td>{t('texts.acceleration')}</td>
                    <td>{i18nUtil.formatPercent(accountingDetails.acceleration || 0)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}