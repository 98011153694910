import {useLayoutEffect, useRef, useState} from 'react';
import useResizeObserver from "@react-hook/resize-observer";

/**
 * Watches for size changes for an element (i.e. ResizeObserver for react)
 * 
 * Usage:
 * 
 * const [sizeTargetRef, { height, width }] = useElementSize();
 *
 * useEffect(() => {
 *      // your logic here
 * }, [height, width]);
 * 
 * @returns {[React.MutableRefObject<null>,{width: number, height: number}]}
 */
export default function useElementSize() {
    const sizeTargetRef = useRef(null);

    const [size, setSize] = useState({
        width: 0,
        height: 0,
    });

    const setRoundedSize = ({width, height}) => {
        setSize({width: Math.round(width), height: Math.round(height)});
    };

    useLayoutEffect(() => {
        sizeTargetRef.current && setRoundedSize(sizeTargetRef.current.getBoundingClientRect())
    }, [sizeTargetRef]);

    useResizeObserver(sizeTargetRef, entry => {
        const {inlineSize: width, blockSize: height} = entry.contentBoxSize[0];
        setRoundedSize({width, height});
    });

    return [sizeTargetRef, size];
}