import {useEffect, useState} from "react";
import {i18nService} from "../i18n";
import OverlayUtil from "../utils/OverlayUtil";

export default function I18nWrapper({children}) {
    const [isI18nInitialised, setIsI18nInitialised] = useState(false);

    useEffect(() => {
        async function initializeI18n() {
            OverlayUtil.show();
            await i18nService.init();
            setIsI18nInitialised(true);
            OverlayUtil.hide();
        }

        initializeI18n();
    }, []);

    return isI18nInitialised ? children : null;
}