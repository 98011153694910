import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import ValuePropositionsModal from "./ValuePropositionsModal";
import {Trans, useTranslation} from "react-i18next";
import CardPlaceholder from "../CardPlaceholder";
import ValuePropositionCard, {VALUE_PROPOSITION_LAYOUT} from "components/cards/ValuePropositionCard";

import "./ValuePropositionTab.scss";
import useTranslationForGame from "hooks/useTranslationForGame";

export default function ValuePropositionTab({onToggleModal, slideFrom}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.value_propositions_tab'});
    const tfg = useTranslationForGame(t);

    const isTournament = useSelector(state => state.game.isTournament);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const isMobile = useSelector(state => state.session.isMobile);
    const valuePropositions = useSelector(state => state.teams[showBoardForTeam].valuePropositions);

    const [valuePropositionDataToDisplayInForm, setValuePropositionDataToDisplayInForm] = useState(null);
    const [selectedValuePropositionDataToDisplayInForm, setSelectedValuePropositionDataToDisplayInForm] = useState(null);


    const [showValuePropositionsModal, setShowValuePropositionsModal] = useState(false);
    const handleCloseValuePropositionsModal = () => {
        setShowValuePropositionsModal(false);
        onToggleModal(false);
    }
    const handleShowValuePropositionsModal = (number) => {
        setValuePropositionDataToDisplayInForm(number);
        setShowValuePropositionsModal(true);
        setSelectedValuePropositionDataToDisplayInForm(valuePropositions[number] || null);
        onToggleModal(true);
    };

    const valuePropositionsAsArray = useMemo(() => {
        const result = Object.entries(valuePropositions).map(([index, card]) => {
            index = parseInt(index);

            return {index, card};
        });

        for (let i = 0; i < 2; i++) {
            if (!valuePropositions[i])
                result.unshift({index: i, isPlaceholder: true});
        }

        return result;
    }, [valuePropositions]);

    return (
        <>
            <div className="conception-modal-value-proposition-tab">
                <div className={"w-100 animate__animated animate__faster " + slideFrom}>
                    <h4>
                        <Trans t={t} i18nKey={tfg('texts.title', {}, true)}
                               components={{1: <span className='text-warning'/>}}/>
                    </h4>

                    {isTournament && (<p>{tfg('texts.description')}</p>)}
                </div>

                <div className={"w-100 animate__animated animate__faster pb-4 " + slideFrom}>
                    <div className="w-100 d-flex flex-wrap justify-content-center mt-4" style={{gap: '35px 0px'}}>
                        {valuePropositionsAsArray.map(vp => (
                            <div className="text-center text-dark" key={vp.index}>
                                {vp.isPlaceholder && (
                                    <CardPlaceholder color="info"
                                                     onClick={() => handleShowValuePropositionsModal(vp.index)}/>
                                )}

                                {!vp.isPlaceholder && (
                                    <ValuePropositionCard valueProposition={vp.card}
                                                          selectable={false}
                                                          layout={isMobile ? VALUE_PROPOSITION_LAYOUT.EXPANDABLE : VALUE_PROPOSITION_LAYOUT.FULL}/>
                                )}

                                <button
                                    className={"btn btn-info text-white " + (vp.isPlaceholder ? 'pulsating' : '')}
                                    onClick={() => handleShowValuePropositionsModal(vp.index)}>
                                    {!vp.isPlaceholder ? t('buttons.change') : t('buttons.select')}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showValuePropositionsModal && (<ValuePropositionsModal onClose={handleCloseValuePropositionsModal}
                                                                    number={valuePropositionDataToDisplayInForm}
                                                                    selected={selectedValuePropositionDataToDisplayInForm}/>)}
        </>
    );
}