import React, {useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18nUtil from "utils/i18nUtil";

import "./TeamStepInPodium.scss";
import {useSelector} from "react-redux";
import GameService from "services/gameMaster/GameService";
import ToastUtil from "utils/ToastUtil";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import AudioService from "services/common/AudioService";
import {SOUND_EFFECTS} from "config/AUDIO_FILES";
import useTranslationForGame from "hooks/useTranslationForGame";
import GameUtil from "utils/GameUtil";

const POSITIONS = [
    'first-pos', 'second-pos', 'third-pos', 'fourth-pos'
]

export default function TeamStepInPodium({teamRanking, onReveal, playAudio}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.team_ranking_modal.components.team_step_in_podium"});
    const tfg = useTranslationForGame(t);

    const {team, ranking, acceleration, monthsAdvanced, revenue, show} = teamRanking;
    const startup = useSelector(state => state.teams[team]?.startup);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const [isLoading, setIsLoading] = useState(false);
    const [revealedRanking, setRevealedRanking] = useState(false);
    const [playedAudio, setPlayedAudio] = useState(false);
    const [playingAudio, setPlayingAudio] = useState(false);

    const handleReveal = async () => {
        setIsLoading(true);

        let params = {};
        try {
            if (playAudio) {
                setPlayingAudio(true);

                const audio = AudioService.createAudio(SOUND_EFFECTS.RANKING.REVEAL);
                audio.addEventListener('ended', () => {
                    setPlayedAudio(true);
                    setPlayingAudio(false);
                });

                audio.play();
            } else {
                setPlayedAudio(true);
            }

            params = {team};
            if (await GameService.revealRanking(params)) {
                setRevealedRanking(true);
            } else {
                ToastUtil.toastDanger(t('toasts.reveal_error.title'), t('toasts.reveal_error.message'));
            }
        } catch (e) {
            console.error(e);
            ToastUtil.toastDanger(t('toasts.reveal_error.title'), t('toasts.reveal_error.message'));

            LoggerService.error(e, {
                action: 'reveal teams ranking in podium - control panel',
                params
            });
        } finally {
            setIsLoading(false);
        }
    }

    const pitchBonus = useMemo(() => GameUtil.getLastPitchInvestmentReceived(team), [teamRanking, isEsg]);
    const pitchStep = useMemo(() => GameUtil.getLastPitchStep(), []);

    useEffect(() => {
        if (playedAudio && revealedRanking && !isLoading) {
            onReveal(teamRanking);
            setRevealedRanking(false);
        }
    }, [isLoading, playedAudio, revealedRanking]);

    if (show && !playingAudio) {
        return (
            <div
                className={"team-step-in-podium animate__animated animate__fadeIn d-flex flex-column justify-content-end align-items-center text-center position-relative " + POSITIONS[ranking - 1]}>
                <div className={"trophy-icon mb-2 animate__animated animate__bounceIn text-ranking-" + ranking}>
                    {ranking === 1 && <FontAwesomeIcon icon={['fas', 'trophy']} className="fa-4x"/>}
                    {(ranking === 2 || ranking === 3) && <FontAwesomeIcon icon={['fas', 'medal']} className="fa-3x"/>}
                    {ranking === 4 && <FontAwesomeIcon icon={['fas', 'award']} className="fa-3x"/>}
                </div>

                <h3 className={'text-team-' + team}>
                    {t('texts.team', {team})}
                    <br/>
                    {startup?.title || ''}
                </h3>

                <div className={"podium-step  bg-team-" + team}>
                    <h4>{i18nUtil.ordinalSuffix(ranking)}</h4>
                </div>

                <div className="mt-2 totals">
                    <div className="animate__animated animate__faster animate__fadeInUp mb-1">
                        <b>{t('texts.pitch_investment', {pitch: i18nUtil.stepName(pitchStep)})}</b>
                        <p>{i18nUtil.formatMoney(pitchBonus)}</p>
                    </div>
                    <div className="animate__animated animate__faster animate__fadeInUp">
                        <b>{tfg('texts.revenue')}</b>
                        <p>{isEsg ? i18nUtil.formatDecimal(revenue) : i18nUtil.formatMoney(revenue)}</p>
                    </div>
                    <div className="animate__animated animate__faster animate__fadeInUp">
                        <b>{tfg('texts.acceleration')}</b>
                        <p>{i18nUtil.formatPercent(acceleration)}</p>
                    </div>
                    <div className="animate__animated animate__faster animate__fadeInUp">
                        <b>{t('texts.months_advanced')}</b>
                        <p>{monthsAdvanced}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={"team-step-in-podium d-flex flex-column justify-content-end text-center " + POSITIONS[ranking - 1]}>

                <h3><FontAwesomeIcon icon={['fas', 'question-circle']} className="fa-2x"/></h3>

                <div className={"podium-step podium-step-placeholder d-flex align-items-center "}>
                    <h4>{i18nUtil.ordinalSuffix(ranking)}</h4>
                </div>

                <div className="mt-2 totals">
                    <button className="btn btn-light text-dark font-weight-bold my-1 py-1"
                            onClick={handleReveal} disabled={isLoading || playingAudio}>
                        {(isLoading || playingAudio) && (
                            <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-1x fa-spin"/>)}
                        {!isLoading && !playingAudio && t('buttons.reveal')}
                    </button>
                </div>
            </div>
        )
    }
}