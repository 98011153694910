import {useEffect, useMemo, useState} from "react";

import "./Ranking.scss";
import {useSelector} from "react-redux";
import Desktop from "./partials/Desktop";
import Mobile from "./partials/Mobile";

export default function Ranking({data}) {
    const isMobile = useSelector(state => state.session.isMobile);
    const [firstToThirdPlace, setFirstToThirdPlace] = useState([]);
    const [fourthPlaces, setFourthPlaces] = useState([]);

    const teamsGroupedByRanking = useMemo(()=>{
        const groupedByRanking = {};

        Object.entries(data).forEach(([team, teamData]) => {
            if (!groupedByRanking[teamData.ranking])
                groupedByRanking[teamData.ranking] = [];

            groupedByRanking[teamData.ranking].push({...teamData, team: team});
        });

        return groupedByRanking;
    },[data]);

    useEffect(() => {
        const groupedByRanking = {};

        Object.entries(data).forEach(([team, teamData]) => {
            if (!groupedByRanking[teamData.ranking]) groupedByRanking[teamData.ranking] = [];
            groupedByRanking[teamData.ranking].push({...teamData, team: team});
        });

        const sortedForRanking = [];
        if (groupedByRanking[2]) sortedForRanking.push(...groupedByRanking[2]);
        if (groupedByRanking[1]) sortedForRanking.push(...groupedByRanking[1]);
        if (groupedByRanking[3]) sortedForRanking.push(...groupedByRanking[3]);

        setFirstToThirdPlace(sortedForRanking);
        setFourthPlaces(groupedByRanking[4] || []);

    }, [data]);

    return isMobile
        ? <Mobile teamsGroupedByRanking={teamsGroupedByRanking}/>
        : <Desktop teamsGroupedByRanking={teamsGroupedByRanking}/>
}