import React, {useMemo} from "react";

import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";
import ColumnView from "../components/ColumnView";

export default function Summary() {
    const {t} = useTranslation('pages/common/board/accounting', {keyPrefix: 'texts'});

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.totals);

    const columnViewData = useMemo(() => {
        const leftItems = [
            {
                title: t('available_for_market'),
                value: i18nUtil.formatMoney(accountingDetails.availableForMarket || 0)
            },
            {
                title: t('revenue'),
                value: i18nUtil.formatMoney(accountingDetails.revenue || 0)
            },
            {
                title: t('expenses'),
                value: i18nUtil.formatMoney(accountingDetails.totalCost || 0)
            },
            {
                title: t('in_bank_with_sign'),
                value: i18nUtil.formatMoney(accountingDetails.balance || 0)
            },
        ];

        const rightItems = [
            {
                title: t('capital'),
                value: i18nUtil.formatMoney(accountingDetails.capital || 0),
                valueClass: 'text-warning'
            },
            {
                title: t('months'),
                value: accountingDetails.monthsAdvanced
            },
            {
                title: t('acceleration'),
                value: i18nUtil.formatPercent(accountingDetails.acceleration || 0)
            },
            {
                title: t('market_investment_rate'),
                value: i18nUtil.formatPercent(accountingDetails.marketInvestmentRate || 0)
            }
        ];

        return {leftItems, rightItems};
    }, [accountingDetails, showBoardForTeam]);

    return (<div>
        <h5 className="text-uppercase text-warning mb-2">
            {t('totals')}
        </h5>

        <div className="mb-3">
            <ColumnView leftItems={columnViewData.leftItems} rightItems={columnViewData.rightItems}/>
        </div>
    </div>);
}