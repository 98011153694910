import {useSelector} from "react-redux";
import {useEffect} from "react";

/**
 *  Watches for 'state.session.isMobile'
 *
 * Toggles the classes 'mobile' and 'desktop' for the body element based on the boolean above
 *
 */
export default function IsMobileWatcher() {
    const isMobile = useSelector(state => state.session.isMobile);

    useEffect(() => {
        if (isMobile) {
            document.body.classList.add("mobile");
            document.body.classList.remove("desktop");
        } else {
            document.body.classList.remove("mobile");
            document.body.classList.add("desktop");
        }
    }, [isMobile]);


    return null;
}