import {ACCELERATION_BONUSES, GAME_STEPS} from "./CONSTANTS";


// ----------------------- ACCOUNTING -----------------------
const GAME_INITIAL_CAPITAL = 5000;
const GAME_INITIAL_ACCELERATION = -100;

const GAME_COST_PER_STEP = {
    [GAME_STEPS.MVP]: 2500,
    [GAME_STEPS.PITCH_1]: 2500,

    [GAME_STEPS.BETA]: 15000,
    [GAME_STEPS.GTM]: 15000,
    [GAME_STEPS.GROWTH_MODEL]: 15000,
    [GAME_STEPS.PITCH_2]: 15000,

    [GAME_STEPS.SALES_MACHINE]: 30000,
    [GAME_STEPS.CUSTOMER_SUCCESS]: 40000,
    [GAME_STEPS.PRODUCT_SCALABILITY]: 50000,
    [GAME_STEPS.PITCH_3]: 60000
};

const GAME_REVENUE_PER_STEP = {
    [GAME_STEPS.BETA]: 100,

    [GAME_STEPS.GTM]: 100,
    [GAME_STEPS.GROWTH_MODEL]: 100,
    [GAME_STEPS.PITCH_2]: 100,

    [GAME_STEPS.SALES_MACHINE]: 100,
    [GAME_STEPS.CUSTOMER_SUCCESS]: 100,
    [GAME_STEPS.PRODUCT_SCALABILITY]: 100,
    [GAME_STEPS.PITCH_3]: 100,
};

const GAME_MONTHS_CAPITAL_PER_STEP = {
    [GAME_STEPS.BETA]: {RIGHT: 60000, WRONG: 50000},
    [GAME_STEPS.GTM]: {RIGHT: 60000, WRONG: 50000},
    [GAME_STEPS.GROWTH_MODEL]: {RIGHT: 60000, WRONG: 50000},

    [GAME_STEPS.SALES_MACHINE]: {RIGHT: 60000, WRONG: 40000},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {RIGHT: 60000, WRONG: 40000},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {RIGHT: 60000, WRONG: 40000}
};

const GAME_MONTHS_TO_ADVANCE_PER_STEP = {
    [GAME_STEPS.CONCEPTION]: 1,
    [GAME_STEPS.MVP]: {RIGHT: 1, WRONG: 2},
    [GAME_STEPS.PITCH_1]: 1,

    [GAME_STEPS.BETA]: {RIGHT: 6, WRONG: 8},
    [GAME_STEPS.GTM]: {RIGHT: 4, WRONG: 6},
    [GAME_STEPS.GROWTH_MODEL]: {RIGHT: 4, WRONG: 5},
    [GAME_STEPS.PITCH_2]: 1,

    [GAME_STEPS.SALES_MACHINE]: {RIGHT: 5, WRONG: 6},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {RIGHT: 5, WRONG: 6},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {RIGHT: 5, WRONG: 6},
    [GAME_STEPS.PITCH_3]: 4,
};

const GAME_TEAM_SPEED_BY_ADVANCED_MONTHS = {
    [GAME_STEPS.CONCEPTION]: {FAST: 1, SLOW: 0},
    [GAME_STEPS.MVP]: {FAST: 2, SLOW: 3},
    [GAME_STEPS.PITCH_1]: {FAST: 3, SLOW: 4},

    [GAME_STEPS.BETA]: {FAST: 9, SLOW: 12},
    [GAME_STEPS.GTM]: {FAST: 13, SLOW: 18},
    [GAME_STEPS.GROWTH_MODEL]: {FAST: 17, SLOW: 23},
    [GAME_STEPS.PITCH_2]: {FAST: 18, SLOW: 24},

    [GAME_STEPS.SALES_MACHINE]: {FAST: 23, SLOW: 28},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {FAST: 28, SLOW: 34},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {FAST: 33, SLOW: 39},
    [GAME_STEPS.PITCH_3]: {FAST: 37, SLOW: 43},
};

const GAME_PITCH_INVESTMENT = {
    [GAME_STEPS.PITCH_1]: {1: 180000, 2: 180000, 3: 180000, 4: 180000},
    [GAME_STEPS.PITCH_2]: {1: 1000000, 2: 500000, 3: 250000, 4: 0},
    [GAME_STEPS.PITCH_3]: {1: 1000000, 2: 500000, 3: 250000, 4: 0},
};


// ----------------------- ACCELERATION BONUSES -----------------------
/** format is: {CARD_COUNT: ACCELERATION_BONUS} */
const ACCELERATION_BONUS_VALUES = {
    [ACCELERATION_BONUSES.IMPLEMENTATION]: {
        4: 10,
        8: 20
    },
    [ACCELERATION_BONUSES.MONITORING]: {
        2: 10,
        4: 20
    },
    [ACCELERATION_BONUSES.REPORTING]: {
        2: 10,
        4: 20
    }
};


// ----------------------- EXPORTS -----------------------
const ACCOUNTING_ESG_CONFIG = {
    ACCELERATION_BONUS_VALUES,
    GAME_COST_PER_STEP,
    GAME_REVENUE_PER_STEP,
    GAME_MONTHS_CAPITAL_PER_STEP,
    GAME_MONTHS_TO_ADVANCE_PER_STEP,
    GAME_TEAM_SPEED_BY_ADVANCED_MONTHS,
    GAME_INITIAL_CAPITAL,
    GAME_PITCH_INVESTMENT,
    GAME_INITIAL_ACCELERATION
};


export default ACCOUNTING_ESG_CONFIG;