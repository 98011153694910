import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import FoundersModal from "./FoundersModal";
import {Trans, useTranslation} from "react-i18next";
import CardPlaceholder from "../CardPlaceholder";
import FounderCard from "components/cards/FounderCard";
import "./FounderTab.scss";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function FounderTab({onToggleModal, slideFrom}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.founders_tab'});
    const tfg = useTranslationForGame(t);

    const isTournament = useSelector(state => state.game.isTournament);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);
    const foundersIcons = useSelector(state => state.teams[showBoardForTeam].foundersIcons);

    const [foundersDataToDisplayInForm, setFoundersDataToDisplayInForm] = useState(null);

    const [showFoundersModal, setShowFoundersModal] = useState(false);
    const handleCloseFoundersModal = () => {
        setShowFoundersModal(false);
        onToggleModal(false);
    }
    const handleShowFoundersModal = (number) => {
        setFoundersDataToDisplayInForm(number);
        setShowFoundersModal(true);
        onToggleModal(true);
    };

    const foundersAsArray = useMemo(() => {
        const result = Object.entries(founders).map(([index, card]) => {
            index = parseInt(index);

            return {index, card, icon: foundersIcons[index]};
        });

        for (let i = 0; i < 2; i++) {
            if (!founders[i])
                result.unshift({index: i, isPlaceholder: true});
        }

        return result;
    }, [founders, foundersIcons]);

    return (
        <>
            <div className="conception-modal-founder-tab">
                <div className={"w-100 animate__animated animate__faster " + slideFrom}>
                    <h4>
                        <Trans t={t} i18nKey={tfg('texts.title', {}, true)}
                               components={{1: <span className='text-warning'/>}}/>
                    </h4>

                    {isTournament && (<p>{tfg('texts.description')}</p>)}
                </div>

                <div className={"w-100 animate__animated animate__faster pb-4 " + slideFrom}>
                    <div className="w-100 d-flex flex-wrap justify-content-center mt-4" style={{gap: '35px 0px'}}>
                        {foundersAsArray.map(founder => (
                            <div key={founder.index}>
                                {founder.isPlaceholder && (
                                    <CardPlaceholder color={"warning"} onClick={() => handleShowFoundersModal(founder.index)}/>
                                )}

                                {!founder.isPlaceholder && (
                                    <FounderCard founder={founder.card} icon={founder.icon} selectable={false}/>
                                )}

                                <button
                                    className={"btn btn-warning text-white " + (founder.isPlaceholder ? 'pulsating' : '')}
                                    onClick={() => handleShowFoundersModal(founder.index)}>
                                    {!founder.isPlaceholder ? t('buttons.change') : t('buttons.select')}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showFoundersModal && (
                <FoundersModal onClose={handleCloseFoundersModal} number={foundersDataToDisplayInForm}/>)}
        </>
    );
}