import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'assets/css/App.scss';
import 'assets/js/FontAwesome';
import 'fullscreen-api-polyfill';

import store from "./redux/store";
import {Provider} from 'react-redux';

import {default as awsExports} from "./aws-exports";
import Amplify from "@aws-amplify/core";
import GlobalErrorBoundary from "./GlobalErrorBoundary";

Amplify.configure(awsExports);

// override console.error to remove the pesky findDOMNode error
console.error = (function () {
    const error = console.error;

    return function () {
        try {
            if (arguments[1] === 'findDOMNode') return;
        } catch (e) {
        }

        error.apply(console, arguments);
    }
})();

// possible fix for app breaking because browser extensions change the DOM
// credits to: https://github.com/facebook/react/issues/17256#issuecomment-829685981
if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
            if (console) {
                console.warn('Cannot remove a child from a different parent', child, this);
            }
            return child;
        }
        return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
                console.warn(
                    'Cannot insert before a reference node from a different parent',
                    referenceNode,
                    this
                );
            }
            return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
    };
}

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);

root.render(
    <GlobalErrorBoundary>
        <Provider store={store}>
            <App/>
        </Provider>
    </GlobalErrorBoundary>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
