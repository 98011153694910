import React from 'react';
import {useTranslation} from "react-i18next";
import GameMasterInfo from "../components/GameMasterInfo";
import PlayersTable from "../components/PlayersTable";
import Messages from "./Messages";
import ButtonLeave from "../components/ButtonLeave";
import ButtonToggleFullscreen from "../../../../components/ButtonToggleFullscreen";

export default function SidebarContent() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.header'});

    return (<div className="container pb-4">
        <GameMasterInfo/>
        <hr/>

        <h5>{t('texts.players_in_session')}</h5>
        <PlayersTable/>
        <hr/>

        <Messages/>

        <hr/>

        <div className="d-flex flex-column" style={{gap: 10}}>
            <ButtonToggleFullscreen className="btn btn-link btn-lg font-weight-bold p-1 w-100 text-left"/>
            <ButtonLeave className="btn btn-link btn-lg font-weight-bold p-1 w-100 text-left"/>
        </div>
    </div>);
}