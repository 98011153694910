import React from "react";
import gmIcon from "../../../../assets/img/board/ic_gamemaster.svg";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function GameMasterInfo({textClass = 'h5 mb-0', iconWidth = 50}) {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.user_list'});

    const gameMaster = useSelector(state => state.game.gameInfo.gameMaster);


    return (<div className="game-master-info-container d-flex align-items-center pt-1 pb-2 pl-2">
        <img alt="Game master icon" src={gmIcon} className="mr-3" width={iconWidth}/>

        <div>
            <h3 className={`text-warning ${textClass}`}>{t("texts.game_master")}</h3>
            <h3 className={textClass}>{gameMaster || '-'}</h3>
        </div>
    </div>);
}