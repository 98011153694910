import React, {useMemo} from "react";

import TeamStepInPodium from "../componentsDesktop/TeamStepInPodium";
import {useTranslation} from "react-i18next";

export default function Desktop({teamsGroupedByRanking}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.ranking'});

    const teamsByPosition = useMemo(()=>{
        const sortedForRanking = [];
        if (teamsGroupedByRanking[2]) sortedForRanking.push(...teamsGroupedByRanking[2]);
        if (teamsGroupedByRanking[1]) sortedForRanking.push(...teamsGroupedByRanking[1]);
        if (teamsGroupedByRanking[3]) sortedForRanking.push(...teamsGroupedByRanking[3]);

        return {
            right: sortedForRanking,
            left: teamsGroupedByRanking[4] || []
        }
    }, []);

    return (
        <div
            className="ranking-player d-flex w-100 justify-content-center flex-column align-items-center text-white  border-warning">
            <div className="w-100">
                <div className="box-head bg-warning text-white text-uppercase">
                    {t('texts.podium')}
                </div>
            </div>

            <div className="d-flex ">
                <div className="d-flex  align-items-end">
                    {teamsByPosition.left.map(teamRanking => <TeamStepInPodium teamRanking={teamRanking}
                                                                            key={teamRanking.team}/>)}
                </div>

                <div className="d-flex  align-items-end ml-4">
                    {teamsByPosition.right.map(teamRanking => <TeamStepInPodium teamRanking={teamRanking}
                                                                       key={teamRanking.team}/>)}
                </div>
            </div>
        </div>
    );
}