import React, {useEffect, useMemo, useState} from 'react';
import SummaryCardLayout from 'components/cards/layouts/SummaryCardLayout';

import './AccountSummaryCard.scss';
import {GAME_STEPS} from "config/CONSTANTS";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AccountingDetailsModal from "./components/AccountingDetailsModal";
import {useTranslation} from "react-i18next";
import GameUtil from "utils/GameUtil";
import SmgxSummaryContent from "./components/SmgxSummaryContent";
import EsgSummaryContent from "./components/EsgSummaryContent";
import useTranslationForGame from "hooks/useTranslationForGame";

export default function AccountSummaryCard() {
    const {t} = useTranslation('pages/common/board/accounting');
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentStep = useSelector(state => state.game.currentStep);
    const signedAccounting = useSelector(state => state.teams[showBoardForTeam].signedAccounting);
    const hiredAccountingService = useSelector(state => state.teams[showBoardForTeam].hiredAccountingService);
    const questions = useSelector(state => state.teams[showBoardForTeam]?.questions);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const hasVisitedAccounting = useSelector(state => state.demo.state.hasVisitedAccounting);
    const pulseAccounting = useSelector(state => state.demo.state.pulseAccounting);

    const pulseAccountingForTournament = useSelector(state => state.tournament.state.showAccountingChallenge);

    const [showContent, setShowContent] = useState(false);
    const [showAccountingModal, setShowAccountingModal] = useState(false);

    const handleShowAccountingModal = () => setShowAccountingModal(true);
    const handleCloseAccountingModal = () => setShowAccountingModal(false);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);
    const hideDetailsButton = useMemo(() => isEsg && currentStep < GAME_STEPS.BETA, [isEsg, currentStep]);

    const cardClass = useMemo(() => {
        return (showContent ? 'border-danger ' : 'not-unlocked')
            + (showContent && ((isDemonstration && pulseAccounting) || pulseAccountingForTournament) && !hasVisitedAccounting
                ? 'pulsating '
                : '');
    }, [showContent, pulseAccounting, pulseAccountingForTournament, hasVisitedAccounting, isDemonstration]);

    const needToCheckAccounting = useMemo(() => {
        if (GameUtil.isCurrentGameEsg())
            return false;

        for (let i = 0; i <= currentStep; i++) {
            const teamQuestion = questions[i];
            if (teamQuestion && teamQuestion?.showResult === true && (!signedAccounting[i] && !hiredAccountingService)) {
                return true;
            }
        }

        return false;
    }, [questions, hiredAccountingService, signedAccounting, currentStep]);

    useEffect(() => {
        setShowContent(currentStep > GAME_STEPS.CONCEPTION);
    }, [currentStep]);

    return (
        <>
            <SummaryCardLayout>
                <div className={'box account-summary-card mt-3 ' + cardClass}>
                    <div className={"box-head text-uppercase " + (showContent ? 'bg-danger' : 'transparent')}>
                        {showContent && tfg('texts.title')}
                        {!showContent && '???'}
                    </div>


                    {showContent && (
                        <button id="btn-accounting-details" type="button" onClick={handleShowAccountingModal}
                                hidden={hideDetailsButton}
                                className={"btn btn-outline-info btn-sm position-absolute top right mt-1 mr-2 p-1 border-0 radius-4 "
                                    + (needToCheckAccounting ? 'pulsating' : '')}>

                            {t('buttons.details')}
                        </button>
                    )}


                    {showContent && (isEsg ? <EsgSummaryContent/> : <SmgxSummaryContent/>)}


                    {!showContent && (
                        <div className="box-content justify-content-center align-items-center h-100 pb-5 d-flex">
                            <FontAwesomeIcon icon={['fas', 'lock']} className="fa-3x mb-5 text-danger"/>
                        </div>
                    )}
                </div>
            </SummaryCardLayout>


            {showAccountingModal && (<AccountingDetailsModal onClose={handleCloseAccountingModal}/>)}
        </>
    );
}