import React, {useMemo, useState} from "react";
import Utils from "utils/Utils";
import SkillCard from "components/cards/SkillCard";
import {useSelector} from "react-redux";
import CardUtil from "utils/CardUtil";
import {useTranslation} from "react-i18next";
import ToastUtil from "utils/ToastUtil";
import CardService from "services/common/CardService";
import SkillCardModel from "models/SkillCardModel";
import MarketModal from "./components/MarketModal";
import DemoSessionService from "services/common/DemoSessionService";
import {SKILL_GENDER_GROUP, SKILL_ICONS_METADATA} from "config/SKILL_ICONS";
import {Modal} from "react-bootstrap";
import {GAME_CARD_TYPE} from "config/CONSTANTS";

export default function SkillMarket({onClose}) {
    const {t} = useTranslation('pages/common/board/market_modal', {keyPrefix: 'components.skills'});
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    const skills = useSelector(state => state.cards.skills);
    const availableForMarket = useSelector(state => state.teams[showBoardForTeam].accounting?.totals?.availableForMarket);
    const canHireCards = useSelector(state => state.game.canHireCards);
    const isFinished = useSelector(state => state.game.isFinished);
    const hiredSkills = useSelector(state => state.teams[showBoardForTeam].skills);
    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);

    const [hiringInProgressFor, setHiringInProgressFor] = useState({});

    const teamFoundersIds = useMemo(() => {
        return Object.values(founders).reduce((prev, curr) => {
            return {...prev, [curr.id]: true};
        }, {});
    }, [founders]);

    const skillIcons = useMemo(() => {
        const newIcons = {};

        skills.forEach(skill => {
            const randomIconNumber = SkillCardModel.randomIconNumber();
            newIcons[skill.id] = SkillCardModel.getFullPathForIcon(randomIconNumber);
        });

        return newIcons
    }, [skills, hiredSkills]);

    const skillGenders = useMemo(() => {
        const newGenders = {};
        const otherGenderCount = Math.floor(skills.length * 20 / 100);

        for (let i = 0; i < otherGenderCount; i++) {
            const randomSkill = skills[Utils.randomInt(0, skills.length - 1)];

            if (!newGenders[randomSkill.id])
                newGenders[randomSkill.id] = SKILL_GENDER_GROUP.OTHER;
            else
                i--;
        }

        skills.forEach(skill => {
            const randomIconNumber = SkillCardModel.randomIconNumber();

            if (!newGenders[skill.id])
                newGenders[skill.id] = SKILL_ICONS_METADATA[randomIconNumber].gender;
        });

        return newGenders;
    }, [skills, hiredSkills])

    const hiredSkillsCount = useMemo(() => {
        const counter = {};

        CardUtil.hiredCardsToArray(hiredSkills).forEach(hiredSkill => {
            if (!counter[hiredSkill.card.id])
                counter[hiredSkill.card.id] = 0;

            counter[hiredSkill.card.id]++;
        });

        return counter;
    }, [hiredSkills]);

    const forceRerender = useMemo(() => {
        return Utils.uuid();
    }, [availableForMarket, hiringInProgressFor, hiredSkillsCount, hiredSkills]);


    const handleSetHiringInProgressFor = (card, isInProgress = false) => {
        const inProgressFor = {...hiringInProgressFor};
        if (isInProgress) inProgressFor[card.id] = true;
        else delete inProgressFor[card.id];

        setHiringInProgressFor(inProgressFor);
    };

    const handleHire = async (skill, iconNumber, gender) => {
        if (isDemonstration) {
            DemoSessionService.hireSkill({skill, iconNumber, gender});

            ToastUtil.toastSuccess(
                t('toasts.hire_success.title'),
                t('toasts.hire_success.message', {skill: skill.title}),
                {soundEffect: false}
            );

            return;
        }

        handleSetHiringInProgressFor(skill, true);

        const hired = await CardService.hireSkill(skill.id, iconNumber, gender);
        if (hired) {
            ToastUtil.toastSuccess(
                t('toasts.hire_success.title'),
                t('toasts.hire_success.message', {skill: skill.title}),
                {soundEffect: false}
            );
        } else {
            ToastUtil.toastDanger(t('toasts.hire_error.title'), t('toasts.hire_error.message'));
        }

        handleSetHiringInProgressFor(skill, false);
    };

    const handleOpenServiceMarket = () => {
        onClose();
        document.getElementById('btn-open-service-market').click();
    }

    const handleRender = (card) => {
        return (
            <SkillCard key={card.id}
                       skill={card}
                       icon={skillIcons[card.id]}
                       gender={skillGenders[card.id]}
                       isLoading={hiringInProgressFor[card.id] || false}
                       hasCreditToHire={availableForMarket >= card.price}
                       hirable={!isFinished && canHireCards}
                       hiredCount={hiredSkillsCount[card.id] || 0}
                       isFounder={!!teamFoundersIds[card.id]}
                       onHire={handleHire}/>
        );
    }

    return (<MarketModal onClose={onClose}
                         cards={skills}
                         handleRenderItem={handleRender}
                         forceRerender={forceRerender}
                         type={GAME_CARD_TYPE.SKILL}>

            <Modal.Title>{t('texts.title')}</Modal.Title>

            <p className="mb-1 small">{t('texts.click_info')}</p>

            <button onClick={handleOpenServiceMarket} className="btn btn-warning px-2 py-0 rounded">
                {t('buttons.open_service_market')}
            </button>
        </MarketModal>
    );
}