import React, {useEffect, useMemo, useState} from "react";
import {Portal} from "react-portal";

import "./AccessCodeModal.scss";
import {useNavigate} from "react-router-dom";
import OverlayUtil from "../../../../utils/OverlayUtil";
import TournamentService from "../../../../services/common/TournamentService";
import {TOURNAMENT_ROUTES} from "../../../../navigation/ROUTES";
import store from "../../../../redux/store";
import ToastUtil from "../../../../utils/ToastUtil";
import SwalModalUtil from "../../../../utils/SwalModalUtil";
import AuthService from "../../../../services/player/AuthService";

const TIMER_TIME = 60; // in seconds

export default function AccessCodeModal({gameSessionId, email}) {
    const navigate = useNavigate();

    const [isIncorrect, setIsIncorrect] = useState(false);
    const [accessCode, setAccessCode] = useState('');

    const [elapsedTime, setElapsedTime] = useState(TIMER_TIME);
    const [timerInterval, setTimerInterval] = useState(undefined);
    const [canSendMailAgain, setCanSendMailAgain] = useState(false);

    const handleConfirm = async () => {
        OverlayUtil.toggleLoadingOverlay(true, 'Confirmando código');
        setIsIncorrect(false);

        const params = {
            accessCode,
            gameSessionId,
            userId: store.getState().session.user.id
        }

        const result = await TournamentService.validateAccessCode(window.location.pathname, gameSessionId, params);

        if (result === false) {
            setIsIncorrect(true);
        } else {
            navigate(TOURNAMENT_ROUTES.LOBBY);
        }

        OverlayUtil.hide();
    }

    const handleSendMailAgain = async () => {
        setCanSendMailAgain(false);
        handleInitTimer();

        const response = await TournamentService.requestAccessCode({
            gameSessionId,
            email,
            sendMail: true
        });

        if (response?.status === true) {
            ToastUtil.toastSuccess('Um novo e-mail foi enviado');
        } else {
            ToastUtil.toastDanger('Não foi possível completar a sua solicitação', 'Por favor, tente novamente ou entre em contato conosco');
        }
    }

    const handleQuit = ()=>{
        SwalModalUtil.confirmModal(
            'Deseja sair desta sessão?',
            'Você poderá retornar a qualquer momento',
            'Sim, sair',
            'Continuar na sessão'
        ).then(result => {
            if (result) {
                AuthService.leave();
                window.location.reload();
            }
        })
    }

    const handleInitTimer = () => {
        clearTimerInterval();
        setElapsedTime(TIMER_TIME);
        setTimerInterval(setInterval(touchTimer, 1000));
    }

    const clearTimerInterval = () => {
        clearInterval(timerInterval);
    }

    const touchTimer = () => setElapsedTime(prev => prev - 1);

    const handleSetAccessCode = ev => {
        setIsIncorrect(false);
        setAccessCode(ev.target.value);
    }

    useEffect(() => {
        if (elapsedTime <= 0) {
            setCanSendMailAgain(true);
            clearTimerInterval();
        }
    })

    useEffect(() => {
        handleInitTimer();
        return clearTimerInterval;
    }, []);

    const elapsedTimeText = useMemo(() => {
        return Math.min(Math.max(elapsedTime, 0), TIMER_TIME);
    }, [elapsedTime]);

    return (<Portal>
        <div id="access-code-modal" className="animate__animated animate__fadeIn text-center">
            <div className="p-4 text-white d-flex flex-column justify-content-center align-items-center h-100 w-100">
                <div className="text-center">
                    <h4 className="mb-5 text-warning">Antes de continuarmos</h4>

                    <div className="form-group text-center">
                        <label>Informe o código que você recebeu por e-mail</label>
                        <input value={accessCode} onChange={handleSetAccessCode}
                               maxLength={6} className="form-control" required/>
                    </div>
                </div>

                <div className="text-danger mb-3 font-weight-bold" hidden={!isIncorrect}>
                    Código incorreto
                </div>

                <div className="buttons gutters-1 text-center">
                    <button type="button" className="btn btn-success mb-4" onClick={handleConfirm}>
                        Continuar
                    </button>
                </div>

                <button type="button" className="btn btn-outline-secondary border-0 mb-2" onClick={handleQuit}>
                    Sair
                </button>

                <div className="text-light my-2 mt-4">
                    O envio do e-mail pode demorar alguns instantes. Por favor, verifique sua caixa de spam antes de
                    solocitar um novo código.
                </div>

                <div className="text-light">
                    {canSendMailAgain && (<>
                        <button onClick={handleSendMailAgain} className="btn btn-sm btn-outline-secondary border-0">
                            Enviar e-mail novamente
                        </button>
                    </>)}

                    {!canSendMailAgain && (<>
                        Aguarde para reenviar o código novamente:
                        <span className="ml-1">{elapsedTimeText}</span>
                    </>)}
                </div>
            </div>
        </div>
    </Portal>);
}

