import {batch, useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import OverlayUtil from "../utils/OverlayUtil";
import EventProcessor from "../services/common/gameEvents/EventProcessor";
import TeamEventSubscriber from "../subscribers/TeamEventSubscriber";
import TournamentEventSubscriber from "../subscribers/TournamentEventSubscriber";
import GameEventSubscriber from "../subscribers/GameEventSubscriber";
import {useNavigate} from "react-router-dom";
import {reset} from "../redux/slices/teamsSlice";
import {setHasLoadedGameData, setHasLoadedTeamData, setHasSyncError} from "../redux/slices/sessionSlice";
import {COMMON_ROUTES} from "../navigation/ROUTES";
import {removeAllPlayers} from "../redux/slices/gameSlice";


/**
 *  Watches for when the user data goes out of sync with the server
 *
 * It uses the `store.getState().session.hasSyncError` attribute and resets it after reloading the game data;
 *
 * If the user is out of sync, reloads the game data without reloading the app;
 * - Reset slices;
 * - Reset websocket connections;
 * - Go to the landing page (Lobby or Control Board);
 *
 */
export default function SyncWatcher() {
    const hasSyncError = useSelector(state => state.session.hasSyncError);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasSyncError)
            return;

        (async () => {
            OverlayUtil.toggleLoadingOverlay(true, "Sincronizando dados do jogo...");

            EventProcessor.pause();

            // reset websockets
            await Promise.all([
                TeamEventSubscriber.unsubscribeFromAll(),
                GameEventSubscriber.unsubscribe(),
                TournamentEventSubscriber.unsubscribe(),
            ]);

            EventProcessor.clearQueue();

            // reset stores
            batch(() => {
                dispatch(reset({}));
                dispatch(removeAllPlayers());
                dispatch(setHasLoadedGameData(false));
                dispatch(setHasLoadedTeamData(false));
                dispatch(setHasSyncError(false));
            });


            // reset
            navigate(COMMON_ROUTES.SOFT_RELOAD);
        })();
    }, [hasSyncError]);


    return null;
}