import React, {useMemo} from "react";

import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";
import ACCOUNTING_ESG_CONFIG from "config/ACCOUNTING_ESG_CONFIG";
import {GAME_STEPS} from "config/CONSTANTS";
import useTranslationForGame from "hooks/useTranslationForGame";
import ColumnView from "../components/ColumnView";

export default function TotalsSummary() {
    const {t} = useTranslation('pages/common/board/accounting', {keyPrefix: 'texts'});
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.totals);
    const pitch1Bonus = ACCOUNTING_ESG_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_1][1];

    const balance = useMemo(() => {
        const spent = (accountingDetails.servicesAndSkillsInvestment || 0) + (accountingDetails.extraCosts || 0);
        return pitch1Bonus - spent;
    }, [accountingDetails, pitch1Bonus]);

    const impactElementClass = useMemo(() => {
        return accountingDetails.acceleration < 0 ? 'text-danger' : 'text-success';
    }, [accountingDetails]);

    const columnViewData = useMemo(() => {
        const leftItems = [
            {
                title: t('budget'),
                value: i18nUtil.formatMoney(pitch1Bonus)
            },
            {
                title: t('spent_in_market'),
                value: i18nUtil.formatMoney(accountingDetails.servicesAndSkillsInvestment || 0)
            },
            {
                title: t('extra_costs'),
                value: i18nUtil.formatMoney(accountingDetails.extraCosts || 0)
            },
            {
                title: t('balance'),
                value: i18nUtil.formatMoney(balance)
            },
        ];

        const rightItems = [
            {
                title: t('available_for_market'),
                value: i18nUtil.formatMoney(accountingDetails.availableForMarket || 0),
                valueClass: 'text-warning'
            },
            {
                title: t('months'),
                value: accountingDetails.monthsAdvanced
            },
            {
                title: tfg('revenue'),
                value: i18nUtil.formatDecimal(accountingDetails.revenue || 0)
            },
            {
                title: tfg('acceleration'),
                value: i18nUtil.formatPercent(accountingDetails.acceleration || 0),
                valueClass: `font-weight-bold ${impactElementClass}`
            },
        ];

        return {leftItems, rightItems};
    }, [accountingDetails, showBoardForTeam, impactElementClass, balance]);


    return (<div>
        <h5 className="text-uppercase text-warning mb-2">
            {t('totals')}
        </h5>

        <div className="mb-3">
            <ColumnView leftItems={columnViewData.leftItems} rightItems={columnViewData.rightItems}/>
        </div>
    </div>);
}