import React, {useEffect, useMemo, useRef, useState} from "react";
import {Modal} from "react-bootstrap";

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import AccountingService from "services/common/AccountingService";

import LoggerService from "services/common/LoggerService";
import "./JourneyMapModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import GameUtil from "../../../utils/GameUtil";
import DemoSessionService from "../../../services/common/DemoSessionService";
import {GAME_STEPS} from "../../../config/CONSTANTS";

const T1Icon = '<g><defs><style>.clst-t1-1{fill:#e86559;}.clst-t1-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t1-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t1-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t1-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';
const T2Icon = '<g><defs><style>.clst-t2-1{fill:#f4a347;}.clst-t2-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t2-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t2-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t2-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';
const T3Icon = '<g><defs><style>.clst-t3-1{fill:#80b7d6;}.clst-t3-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t3-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t3-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t3-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';
const T4Icon = '<g><defs><style>.clst-t4-1{fill:#4ea772;}.clst-t4-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t4-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t4-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t4-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';

const TEAM_PINS = [T1Icon, T2Icon, T3Icon, T4Icon];

const MAX_MONTHS_ESG = 24;
const MAX_MONTHS_SMGX = 48;

export default function JourneyMapModal({onClose}) {
    const board = useRef();
    const {t, i18n} = useTranslation('pages/common/board/journey_map', {keyPrefix: 'components.modal'});
    const t2 = useTranslation('common').t;

    const currentStep = useSelector(state => state.game.currentStep);
    const teamCount = useSelector(state => state.game.teamCount);
    const team1Questions = useSelector(state => state.teams['1'].questions);
    const team2Questions = useSelector(state => state.teams['2'].questions);
    const team3Questions = useSelector(state => state.teams['3'].questions);
    const team4Questions = useSelector(state => state.teams['4'].questions);

    const isMobile = useSelector(state => state.session.isMobile);
    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const hasPlayedJourneyVideo = useSelector(state => state.demo.state.hasPlayedJourneyVideo);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [rotate, setRotate] = useState(false);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);
    const boardToShow = useMemo(() => GameUtil.getJourneyMap(), []);

    const handleBoardLoaded = () => {
        setHasLoaded(true);
        board.current.style.opacity = 1;
    }

    const handleClose = () => {
        if (isDemonstration && currentStep === GAME_STEPS.ONBOARDING) {
            DemoSessionService.stopVideo();
            DemoSessionService.start();
        }

        onClose();
    }

    // touch effect that highlights the current step
    useEffect(() => {
        if (!hasLoaded) return;

        try {
            const domEl = board.current.contentDocument;

            const steps = domEl.getElementsByClassName('st6');
            for (let i = 0; i < steps.length; i++) {
                steps[i].style.display = 'none';
            }

            let stepToDisplay = currentStep - 1;
            if (stepToDisplay < 10) stepToDisplay = '0' + stepToDisplay.toString();

            const stepElement = domEl.getElementById('step-' + stepToDisplay);
            if (stepElement) stepElement.style.display = '';

            if (isEsg) {
                const stepFills = domEl.getElementsByClassName('st62');
                for (let i = 0; i < stepFills.length; i++) {
                    stepFills[i].style.fill = '';
                }

                const pitchFills = domEl.getElementsByClassName('st37');
                for (let i = 0; i < pitchFills.length; i++) {
                    pitchFills[i].style.fill = '';
                }

                const currentStepFill = domEl.getElementById('etapa-' + (currentStep - 1) + '-rect');
                if (currentStepFill) currentStepFill.style.fill = '#ff931e';
            }
        } catch (e) {
            LoggerService.error(e, 'Error updating journey map');
        }
    }, [hasLoaded, currentStep, isEsg]);


    // touch teams' pins
    useEffect(() => {
        if (!hasLoaded) return;

        try {
            const domEl = board.current.contentDocument;
            const ranking = AccountingService.calculateMonthsAdvanced();
            const maxMonths = isEsg ? MAX_MONTHS_ESG : MAX_MONTHS_SMGX;

            for (let i = 1; i < teamCount + 1; i++) {
                const existingPinEl = domEl.getElementById('mark-t-' + (i));
                if (existingPinEl) existingPinEl.remove();

                let newPinPosition = ranking[i];

                if (newPinPosition > maxMonths)
                    newPinPosition = maxMonths;
                else if (newPinPosition < 10)
                    newPinPosition = '0' + newPinPosition.toString();


                let newPinPositionEl = domEl.getElementById(`pm-mo-${newPinPosition}-${i}`);
                newPinPositionEl = newPinPositionEl ?? domEl.getElementById(`pm-mo-00-${i}`);

                if (newPinPositionEl) {
                    let newPin = TEAM_PINS[i - 1]
                        .replaceAll('#{cx}', newPinPositionEl.getAttribute('cx'))
                        .replaceAll('#{cy}', newPinPositionEl.getAttribute('cy'));

                    const newEl = document.createElementNS("http://www.w3.org/2000/svg", "g");
                    newEl.innerHTML = newPin;
                    newEl.id = 'mark-t-' + i;

                    newPinPositionEl.parentNode.append(newEl);
                }
            }
        } catch (e) {
            LoggerService.error(e, 'Error updating journey map');
        }
    }, [hasLoaded, currentStep, team1Questions, team2Questions, team3Questions, team4Questions, teamCount]);

    useEffect(() => {
        if (isDemonstration && !hasPlayedJourneyVideo)
            DemoSessionService.playJourneyVideo();
    }, []);


    const wrapperClazs = isMobile && rotate ? 'rotate' : '';


    return (<Modal id="modal-journey-map" show={true} size={"xl"} onHide={handleClose} backdropClassName="opacity-95">
        <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title>
                {t("texts.title")}

                {isDemonstration && hasPlayedJourneyVideo && currentStep === GAME_STEPS.ONBOARDING && (
                    <button className="btn btn-sm btn-outline-secondary border-0  p-0 ml-3"
                            onClick={DemoSessionService.playJourneyVideo}>

                        <FontAwesomeIcon icon={['fas', 'circle-play']} className="mr-1"/>
                        {t2('watch_again')}
                    </button>
                )}
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className="bg-dark">
            <div
                className={`board-wrapper position-relative justify-content-center align-items-center ${wrapperClazs}`}>
                {!hasLoaded && (
                    <FontAwesomeIcon icon={['fas', 'circle-notch']}
                                     className="position-absolute m-auto top bottom left right fa-6x text-muted fa-spin"/>)}

                {boardToShow && (<object style={{opacity: 0}} height="100%" width="100%"
                                         className="animate__animated animate__fadeIn animate__faster"
                                         onLoad={handleBoardLoaded}
                                         id="journey-board"
                                         type="image/svg+xml"
                                         data={boardToShow}
                                         ref={board}/>)}
            </div>

            {isMobile && (<button className="btn btn-secondary position-absolute"
                                  onClick={() => setRotate(!rotate)} style={{bottom: 15, right: 20}}>

                <FontAwesomeIcon icon={['fas', 'sync-alt']}/>
            </button>)}
        </Modal.Body>
    </Modal>)
}