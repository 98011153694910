import React, {useState} from 'react';
import Sidebar from "../../../../components/Sidebar";
import {useSelector} from "react-redux";

import "./Header.scss";
import SidebarContent from "./SidebarContent";

export default function Header() {
    const theme = useSelector(state => state.theme);
    const [showSidebar, setShowSidebar] = useState(false);

    return (<>
        <nav id="lobby-mobile-header" className="navbar w-100 navbar-dark bg-dark text-white">
            <div className="d-flex justify-content-between align-items-center w-100">
                <div>
                    <button className="navbar-toggler text-white border-white" onClick={() => setShowSidebar(true)}>
                        <span className="navbar-toggler-icon"/>
                    </button>
                </div>

                <img src={theme.logos.icon} alt="Mundi Game Experiences logo" className="ml-2"/>
            </div>
        </nav>

        <Sidebar show={showSidebar} onClose={() => setShowSidebar(false)}>
            <SidebarContent/>
        </Sidebar>
    </>)
}