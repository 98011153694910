import React, {useState} from "react";

import "./GameInfo.scss";
import {useSelector} from "react-redux";
import ClientInfoModal from "./components/ClientInfoModal";
import ToastUtil from "utils/ToastUtil";
import OverlayUtil from "utils/OverlayUtil";
import GameService from "services/gameMaster/GameService";
import {SMGX_GAME_ADMIN_URL} from "config/CONSTANTS";
import LoggerService from "services/common/LoggerService";
import {Trans, useTranslation} from "react-i18next";

export default function GameInfo() {
    const {t} = useTranslation('pages/game_master/control_panel/game_info');

    const gameInfo = useSelector(state => state.game.gameInfo);
    const isTemporary = useSelector(state => state.game.isTemporary);
    const maxPlayerCount = useSelector(state => state.game.maxPlayerCount);

    const [showClientInfoModal, setShowClientInfoModal] = useState(false);
    const handleClientInfoModalClose = () => setShowClientInfoModal(false);
    const handleClientInfoModalShow = () => setShowClientInfoModal(true);

    const handleUpdateConfig = (params) => {
        OverlayUtil.toggleLoadingOverlay(true, t('overlays.updating_game_config'));

        return GameService.updateGameConfig(params).then(result => {
            if (result)
                ToastUtil.toastSuccess(t('toasts.game_config_updated.title'), t('toasts.game_config_updated.message'));
            else
                ToastUtil.toastDanger(t('toasts.game_config_update_error.title'), t('toasts.game_config_update_error.message'));
        }).catch(reason => {
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.game_config_update_error.title'), t('toasts.game_config_update_error.message'));

            LoggerService.error(reason, {
                action: 'update access control - control panel',
                params
            });
        }).finally(() => {
            OverlayUtil.toggleLoadingOverlay(false);
        });
    }



    return (
        <>
            <div className="alert alert-info" hidden={!isTemporary}>
                <Trans t={t} i18nKey={'texts.test_session_info'}
                       values={{maxPlayerCount}}
                       components={{b: <b/>}}/>
            </div>

            <div className="pr-5 w-00 game-info">
                <h4>{t('texts.title')}</h4>

                <div className="d-flex justify-content-between align-items-center">
                    <div className="flex-fill d-flex justify-content-between pr-5 mr-xl-5 align-items-center">
                        <div className="form-group">
                            <label>{t('texts.version')}</label>
                            <p className="m-0">{gameInfo.version || 'v.1.0.0'}</p>
                        </div>

                        <div className="form-group">
                            <label>{t('texts.language')}</label>
                            <p className="m-0 text-uppercase">{gameInfo.language || '-'}</p>
                        </div>

                        <div className="form-group">
                            <label>{t('texts.owner')}</label>
                            <p className="m-0">{gameInfo.owner || '-'}</p>
                        </div>


                        <div className="form-group">
                            <label>{t('texts.client')}</label>
                            {gameInfo.client.name && (
                                <button type="button" className="btn btn-link py-0 px-1 d-block"
                                        onClick={handleClientInfoModalShow}>
                                    {gameInfo.client.name || '-'}
                                </button>
                            )}

                            {!gameInfo.client.name && (<p className="m-0">-</p>)}
                        </div>

                    </div>

                    <div className="border-left text-center pl-2 pl-md-3">
                        <a href={SMGX_GAME_ADMIN_URL} rel='noreferrer' target="_blank">
                            {t('buttons.manage')}
                            <br/>
                            {t('buttons.view_game_pack')}
                        </a>
                    </div>
                </div>
            </div>

            <hr/>

            {showClientInfoModal && (<ClientInfoModal onClose={handleClientInfoModalClose}/>)}
        </>
    );
}