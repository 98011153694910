import React, {useEffect, useMemo, useRef, useState} from 'react';

import './Accounting.scss';
import "../../components/Accounting/components/AccountingDetailsStyle.scss";

import {useTranslation} from "react-i18next";
import GameUtil from "utils/GameUtil";
import SmgxAccountingDetails from "../../components/Accounting/SmgxAccountingDetails";
import EsgAccountingDetails from "./components/EsgAccountingDetails";

const TIMEOUT_TO_SCROLL = 800; // in ms

export default function Accounting() {
    const {t} = useTranslation('pages/common/board/accounting');

    const containerRef = useRef(null);
    const [timer, setTimer] = useState(null);
    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);


    useEffect(() => {
        clearTimeout(timer);

        setTimer(setTimeout(() => {
            if (containerRef.current) {
                containerRef.current.querySelector('.tab-pane.active .table-responsive')?.scroll({
                    top: 0,
                    left: 99999,
                    behavior: "smooth",
                });
            }
        }, TIMEOUT_TO_SCROLL));

        return () => clearTimeout(timer);
    }, [containerRef]);

    return (
        <div className="text-white account-summary-card" ref={containerRef}>
            {isEsg ? <EsgAccountingDetails showBonus={true}/> : <SmgxAccountingDetails/>}
        </div>
    );
}