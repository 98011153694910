import React from "react";

import "./TournamentPitchChallenge.scss";

/**
 *
 * @param data Pitch step (i.e. GAME_STEPS.PITCH_#)
 * @returns {JSX.Element}
 * @constructor
 */
export default  function TournamentPitchChallenge ({data}) {

    return (<div>

        </div> );
}

