import React, {useEffect, useState} from 'react';
import {GAME_STEPS, GAME_STEPS_WITH_PITCH} from "config/CONSTANTS";
import SessionPersistenceUtil, {PLAYER_KEY} from "utils/SessionPersistenceUtil";
import SwalModalUtil from "utils/SwalModalUtil";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PlayerFormModal from "../../PlayerFormModal/PlayerFormModal";
import NpsFormModal from "../../NpsFormModal/NpsFormModal";
import i18nUtil from "utils/i18nUtil";
import AudioService from "services/common/AudioService";
import {SOUND_EFFECTS} from "config/AUDIO_FILES";
import GameUtil from "utils/GameUtil";
import useTranslationForGame from "hooks/useTranslationForGame";

const SHOW_CHECK_IN_MODAL_ON_PITCH_STEPS = [
    GAME_STEPS.PITCH_1, GAME_STEPS.PITCH_2, GAME_STEPS.PITCH_3
];

let initializedPitchBonusInfo = false;
let showedPitchBonusFor = [];

export default function PopUpNotificationsWatcher() {
    const {t} = useTranslation('pages/common/board/notifications');
    const tfg = useTranslationForGame(t);

    const currentStep = useSelector(state => state.game.currentStep);
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const currentUser = useSelector(state => state.session.user);
    const allowCertificateRequest = useSelector(state => state.game.allowCertificateRequest);

    const isTournament = useSelector(state => state.game.isTournament);
    const showStepChangeTransition = useSelector(state => state.tournament.state.showStepChangeTransition);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const ranking = useSelector(state => state.game.ranking);

    const [showPlayerCertificatesRequestModal, setShowPlayerCertificatesRequestModal] = useState(false);
    const [showNpsModal, setShowNpsModal] = useState(false);


    // check in & nps messages
    useEffect(() => {
        if (isGameMaster || !allowCertificateRequest || isTournament)
            return;

        if ((!currentUser.certificatesRequested || currentUser.certificatesRequested === 0) &&
            ((currentStep === GAME_STEPS.ONBOARDING && currentUser.team) || SHOW_CHECK_IN_MODAL_ON_PITCH_STEPS.includes(currentStep))
            && !SessionPersistenceUtil.get(PLAYER_KEY)?.shown_check_in) {

            SwalModalUtil.confirmModal(
                t('check_in_now.title'),
                t('check_in_now.message'),
                t('check_in_now.confirm_button'),
                t('check_in_now.cancel_button')
            ).then(result => {
                if (result)
                    setShowPlayerCertificatesRequestModal(true);

                SessionPersistenceUtil.update(PLAYER_KEY, {shown_check_in: true});
            })
        }

        if (currentStep === GAME_STEPS.END_GAME && !SessionPersistenceUtil.get(PLAYER_KEY)?.shown_nps_form) {
            SwalModalUtil.confirmModal(
                t('submit_nps_now.title'),
                t('submit_nps_now.message'),
                t('submit_nps_now.confirm_button'),
                t('submit_nps_now.cancel_button')
            ).then(result => {
                if (result)
                    setShowNpsModal(true);

                SessionPersistenceUtil.update(PLAYER_KEY, {shown_nps_form: true});
            })
        }
    }, [isTournament, currentStep, currentUser, isGameMaster]);


    // pitch investment messages
    useEffect(() => {
        if (showStepChangeTransition)
            return;


        // initial state - avoid showing popup on page reload
        if (!initializedPitchBonusInfo) {
            initializedPitchBonusInfo = true;
            showedPitchBonusFor.push(...GAME_STEPS_WITH_PITCH.filter(x => x <= currentStep));

            return;
        }


        // show only once
        if (showedPitchBonusFor.includes(currentStep - 1))
            return;


        // see if we should show or not the message
        let show = false;
        let pitchToShow = null;


        // show for all Pitch 1 & Pitch 2 for SMGX that's not a pocket session
        if (currentStep === GAME_STEPS.PITCH_1 + 1 || currentStep === GAME_STEPS.PITCH_2 + 1) {
            show = true;
            pitchToShow = currentStep - 1;
        }

        // show for end game after revealing team podium
        if (currentStep === GAME_STEPS.END_GAME) {
            show = ranking[showBoardForTeam]?.show === true;
            pitchToShow = GameUtil.getLastPitchStep();
        }


        if (!show || !pitchToShow)
            return;


        // display message
        const investmentReceived = show
            ? GameUtil.getPitchInvestmentReceived(showBoardForTeam, pitchToShow)
            : false;


        // ignore when 'investment === 0'
        if (!investmentReceived)
            return;


        const investment = i18nUtil.formatMoney(investmentReceived);

        let title, message, buttonText;

        if (isTournament) {
            title = tfg(`tournament_investment_received.${pitchToShow}.title`, {investment});
            message = tfg(`tournament_investment_received.${pitchToShow}.message`, {investment});
            buttonText = t(`tournament_investment_received.${pitchToShow}.confirm_button`);
        } else {
            title = i18nUtil.stepName(pitchToShow).toUpperCase();
            message = t('investment_received.message', {investment});
            buttonText = t('investment_received.confirm_button');
        }

        SwalModalUtil
            .infoModal(title, message, buttonText)
            .then(() => AudioService.play(SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED));

    }, [currentStep, ranking, showBoardForTeam, showStepChangeTransition]);


    // html content

    if (showPlayerCertificatesRequestModal)
        return (<PlayerFormModal player={!isGameMaster ? currentUser : null}
                                 onClose={() => setShowPlayerCertificatesRequestModal(false)}/>);

    if (showNpsModal)
        return (<NpsFormModal player={!isGameMaster ? currentUser : null} onClose={() => setShowNpsModal(false)}/>)


    return null;
}