import React, {memo, useMemo, useState} from "react";
import {GAME_STEPS, GAME_STEPS_WITH_QUESTIONS} from "config/CONSTANTS";
import i18nUtil from "utils/i18nUtil";
import store from "redux/store";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TeamService from "services/player/TeamService";
import ToastUtil from "utils/ToastUtil";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import AudioService from "../../../../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../../../../config/AUDIO_FILES";
import GameUtil from "../../../../../../utils/GameUtil";
import DemoSessionService from "../../../../../../services/common/DemoSessionService";

const StepDetailsItem = ({team, step, stepAccounting}) => {
    const {t} = useTranslation(['pages/common/board/accounting', 'common'], {keyPrefix: "components.accounting_details_modal.components.step_details"});
    const t2 = useTranslation(['pages/common/board/accounting', 'common'], {keyPrefix: "components.accounting_details_modal.components.phase_details"}).t;


    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const signedAccounting = useSelector(state => state.teams[team].signedAccounting[step]);
    const hiredAccountingService = useSelector(state => state.teams[team].hiredAccountingService);
    const currentStep = useSelector(state => state.game.currentStep);
    const questionForStep = useSelector(state => state.teams[team].questions[step]);

    const [isLoading, setIsLoading] = useState(false);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    if (step === GAME_STEPS.ONBOARDING) return null;

    const handleSign = () => {
        if (isDemonstration) {
            setIsLoading(true);

            const audio = AudioService.createAudio(SOUND_EFFECTS.ACCOUNTING.ACCOUNTING_SIGNED);
            new Promise(resolve => {
                audio.onended = resolve;
                audio.play();
            }).then(() => {
                const signature = store.getState().session.user.nickname.toLowerCase();
                DemoSessionService.signAccounting(signature);
            }).finally(() => setIsLoading(false));

            return;
        }

        setIsLoading(true);

        let signature = !store.getState().session.isGameMaster
            ? store.getState().session.user.nickname
            : store.getState().game.gameInfo.gameMaster;

        try {
            if (!signature || signature.length < 3) {
                signature = t('texts.signed').toLowerCase();
            } else {
                const slittedSignature = signature.split(' ');
                try {
                    if (slittedSignature.length > 1) {
                        signature = slittedSignature[slittedSignature.length - 1];
                        signature = signature.length > 10 ? signature[0] : signature;
                        signature += ' ' + slittedSignature[0][0] + '.';
                    } else {
                        signature = slittedSignature[0];
                    }
                } catch (e) {
                    signature = slittedSignature[slittedSignature.length - 1];
                }
            }
        } catch (e) {
            signature = t('texts.signed').toLowerCase();
        }

        AudioService.play(SOUND_EFFECTS.ACCOUNTING.ACCOUNTING_SIGNED);

        const params = {step, team, signature};

        TeamService.signAccounting(params).then(result => {
            if (!result) ToastUtil.toastDanger(t('toasts.sign_error.title'), t('toasts.sign_error.message'));
        }).catch(error => {
            console.error(error);
            ToastUtil.toastDanger(t('toasts.sign_error.title'), t('toasts.sign_error.message'));
            LoggerService.error(error, {
                action: 'sign accounting - accounting details modal',
                params
            });
        }).finally(() => setIsLoading(false));
    };

    return (<tr>
        <td>{i18nUtil.stepName(step)}</td>
        <td>{i18nUtil.formatMoney(stepAccounting.costPerMonth || 0)}</td>
        <td>{stepAccounting.monthsAdvanced || 0}</td>
        <td>{i18nUtil.formatMoney(stepAccounting.totalCost || 0)}</td>
        <td>{i18nUtil.formatMoney(stepAccounting.servicesAndSkillsInvestment || 0)}</td>
        <td>{i18nUtil.formatMoney(stepAccounting.revenue || 0)}</td>
        <td>{i18nUtil.formatMoney(stepAccounting.balance || 0)}</td>

        <td className="text-center">
            {isEsg || !GAME_STEPS_WITH_QUESTIONS.includes(step) && (
                <span className="text-muted small">{t('texts.no_need')}</span>)}

            {!isEsg && GAME_STEPS_WITH_QUESTIONS.includes(step) && (<>
                {questionForStep?.showResult !== true && (
                    <span className="text-muted small">-</span>
                )}

                {(questionForStep?.showResult === true || step < currentStep) && !signedAccounting && hiredAccountingService && (
                    <span className="text-info"
                          style={{fontSize: '75%'}}>- {t('texts.accountant').toLowerCase()} -</span>)}

                {signedAccounting && (
                    <span className="signature">{signedAccounting}</span>)}
            </>)}

            {!isEsg && GAME_STEPS_WITH_QUESTIONS.includes(step) && !hiredAccountingService && !signedAccounting
                && questionForStep?.showResult === true && (
                    <button onClick={handleSign} disabled={isLoading}
                            className={"btn btn-info text-white px-1 py-0 " + (isLoading ? '' : 'pulsating')}>
                        {!isLoading && t('buttons.sign')}
                        {isLoading && (<FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-spin"/>)}
                    </button>
                )}
        </td>
    </tr>)
};

export default memo(StepDetailsItem);