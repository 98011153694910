import store from "../redux/store";
import {setHasIdleError} from "../redux/slices/sessionSlice";
import {useIdleTimer} from "react-idle-timer";

const TIMEOUT = 60 * 60 * 1000; // 1 hour

/**
 *  Watches for when the user goes idle.
 *
 * If the user stays idle for more than 1 hour, then `setHasIdleError(true)`
 *
 */
export default function ActivityWatcher() {
    const idleTimer = useIdleTimer({
        timeout: TIMEOUT,
        onIdle: () => store.dispatch(setHasIdleError(true)),
        debounce: 500,
        eventsThrottle: 500,
        stopOnIdle: true,
    });

    return null;
}