import React from 'react';

import Summary from "./smgxComponents/Summary";
import Tabs from "./smgxComponents/Tabs";

export default function SmgxAccountingDetails() {
    return (<>
        <Summary/>
        <hr className="border-light"/>
        <Tabs/>
    </>);
}
