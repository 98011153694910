import {createSlice} from '@reduxjs/toolkit'

import {MOBILE_GAME_TABS} from "config/MOBILE";

export const DEFAULT_VOLUME_CONFIG = {
    volume: 0.45,
    isMuted: false,
    enableButtonClicks: true
}

const initialState = {
    isGameMaster: false,
    showBoardForTeam: null,
    token: null,
    team: null,
    user: {
        id: '',
        name: '',
        nickname: '',
        localPlayers: [],
    },

    isMobile: false,
    selectedMobileTab: MOBILE_GAME_TABS.CHALLENGES,

    hasConnectionError: false,
    hasIdleError: false,
    hasFatalError: false,
    hasSyncError: false,

    hasLoadedGameData: false,
    hasLoadedTeamData: false,
    hasGamePackLoadError: false,

    notifications: {},

    lang: 'en',
    volumeConfig: DEFAULT_VOLUME_CONFIG
};

export const SessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setUser(state, {payload}) {
            state.user = {
                ...state.user,
                ...payload
            };
        },

        setToken(state, {payload}) {
            state.token = payload;
        },

        setTeam(state, {payload}) {
            state.team = payload;
        },

        setIsMobile(state, {payload}) {
            state.isMobile = payload;
        },

        setSelectedMobileTab(state, {payload}) {
            state.selectedMobileTab = payload;
        },

        setIsGameMaster(state, {payload}) {
            state.isGameMaster = payload;
        },

        setHasLoadedGameData(state, {payload}) {
            state.hasLoadedGameData = payload;
        },

        setHasLoadedTeamData(state, {payload}) {
            state.hasLoadedTeamData = payload;
        },

        setHasConnectionError(state, {payload}) {
            state.hasConnectionError = payload;
        },

        setHasIdleError(state, {payload}) {
            state.hasIdleError = payload;
        },

        setHasSyncError(state, {payload}) {
            state.hasSyncError = payload;
        },

        setHasGamePackLoadError(state, {payload}) {
            state.hasGamePackLoadError = payload;
        },

        setShowBoardForTeam(state, {payload}) {
            state.showBoardForTeam = payload;
        },
        setLang(state, {payload}) {
            state.lang = payload;
        },
        setHasFatalError(state, {payload}) {
            state.hasFatalError = payload;
        },
        setVolumeConfig(state, {payload}) {
            state.volumeConfig = {...state.volumeConfig, ...payload};
        },
        setNotifications(state, {payload}) {
            state.notifications = {...state.notifications, ...payload};
        }
    }
});

export const {
    setUser,
    setToken,
    setTeam,
    setIsMobile,
    setSelectedMobileTab,
    setIsGameMaster,
    setHasLoadedGameData,
    setHasLoadedTeamData,
    setHasConnectionError,
    setHasIdleError,
    setHasSyncError,
    setHasGamePackLoadError,
    setShowBoardForTeam,
    setLang,
    setHasFatalError,
    setVolumeConfig,
    setNotifications
} = SessionSlice.actions;
export default SessionSlice.reducer;