import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function SelectedItemBadge({onRemove, data}) {
    const handleClick = () => {
        onRemove(data);
    }

    return (<button className="btn btn-secondary font-weight-bold px-2 py-1 selected-item-badge" onClick={handleClick}>
        <FontAwesomeIcon icon={['far', 'minus-square']} className="text-danger mr-1"/>
        {data.title}
    </button>)
}