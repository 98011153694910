import React, {useState} from 'react';
import {IS_LOCAL_ENV} from "../../../../config/CONSTANTS";
import {useTranslation} from "react-i18next";
import TournamentService from "../../../../services/common/TournamentService";
import ToastUtil from "../../../../utils/ToastUtil";
import SessionPersistenceUtil, {PLAYER_KEY} from "../../../../utils/SessionPersistenceUtil";
import AuthService from "../../../../services/player/AuthService";
import TutorialModal from "../../../tournament/TutorialModal/TutorialModal";
import HelpModal from "../../../tournament/HelpModal/HelpModal";


export default function TournamentButtons({buttonsClass}) {
    const {t} = useTranslation('pages/common/board/header');

    const [isLoading, setIsLoading] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    const handleRestart = async () => {
        setIsLoading(true);

        if (!await TournamentService.restartGame()) {
            setIsLoading(false);
            ToastUtil.toastDanger('Não foi possível avançar o jogo');
        }

        setIsLoading(false);
    }

    const handleQuitGame = () => {
        const gameLink = SessionPersistenceUtil.get(PLAYER_KEY).gameUrl;

        AuthService.leave();
        window.location.replace(gameLink);
    }

    buttonsClass += ' tournament-button';

    return (<>
        {IS_LOCAL_ENV && (<>
            <button className={buttonsClass} onClick={handleRestart} disabled={isLoading}>
                Reiniciar sessão
            </button>
        </>)}


        <button className={buttonsClass} onClick={() => setShowHelpModal(true)}>
            Precisa de ajuda?
        </button>

        <button className={buttonsClass} onClick={() => setShowTutorialModal(true)}>
            Rever tutorial
        </button>

        <button className={buttonsClass} onClick={handleQuitGame}>
            {t('buttons.quit')}
        </button>

        {showTutorialModal && (<TutorialModal onClose={() => setShowTutorialModal(false)}/>)}
        {showHelpModal && (<HelpModal onClose={() => setShowHelpModal(false)}/>)}
    </>)
}