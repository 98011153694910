import React, {useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18nUtil from "utils/i18nUtil";

import "./TeamStepInPodium.scss";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "hooks/useTranslationForGame";
import GameUtil from "utils/GameUtil";

export default function TeamStepInPodium({teamRanking}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.ranking.components.team_step_in_podium.texts'});
    const tfg = useTranslationForGame(t);

    const {team, ranking, acceleration, monthsAdvanced, revenue, show} = teamRanking;
    const startup = useSelector(state => state.teams[team]?.startup);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    if (!show) {
        return (<div
            className={`team-step-in-podium-player rounded d-flex justify-content-between align-items-center not-revealed`}>
            <h3 className="mr-3">{i18nUtil.ordinalSuffix(ranking)}</h3>
            <h1><FontAwesomeIcon icon={['fas', 'question-circle']}/></h1>
        </div>)
    }


    return (<div className={`team-step-in-podium-player rounded bg-team-${team}`}>
        <div className="d-flex justify-content-center align-items-center flex-wrap text-center">
            <h2 className="mb-0">{i18nUtil.ordinalSuffix(ranking)}</h2>

            <h5 className="flex-fill mb-0">
                {t('team', {team})}
                <br/>
                {startup?.title || ''}
            </h5>

            <h1 className="animate__animated animate__bounceIn mb-0">
                {ranking === 1 && <FontAwesomeIcon icon={['fas', 'trophy']}/>}
                {(ranking === 2 || ranking === 3) && <FontAwesomeIcon icon={['fas', 'medal']}/>}
                {ranking === 4 && <FontAwesomeIcon icon={['fas', 'award']}/>}
            </h1>
        </div>

        <hr className="border-light my-2"/>

        <div className="d-flex flex-wrap justify-content-center text-center align-items-center"
             style={{gap: '5px 15px'}}>
            <div>
                <b>{tfg('revenue')}</b>
                <p>{isEsg ? i18nUtil.formatDecimal(revenue) : i18nUtil.formatMoney(revenue)}</p>
            </div>
            <div>
                <b>{tfg('acceleration')}</b>
                <p>{i18nUtil.formatPercent(acceleration)}</p>
            </div>
            <div>
                <b>{t('months_advanced')}</b>
                <p>{monthsAdvanced}</p>
            </div>
        </div>
    </div>);
}