import React from "react";

import "./QuestionChallenge.scss";
import {useSelector} from "react-redux";
import DemoSessionService from "services/common/DemoSessionService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function QuestionChallengeButtons({
                                                     showExplanation,
                                                     isAnswerable,
                                                     isLoading,
                                                     onAdvanceClick,
                                                     onShowExplanationClick
                                                 }) {

    const {t} = useTranslation('common');

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const hasPlayedQuestionVideo = useSelector(state => state.demo.state.hasPlayedQuestionVideo);
    const isTournament = useSelector(state => state.game.isTournament);
    const isMobile = useSelector(state => state.session.isMobile);

    return (<>
        {isTournament && !isAnswerable && (
            <div className={"question-tournament-buttons"}>
                <button className="btn btn-success border-0 mr-2" hidden={isMobile}
                        onClick={onAdvanceClick}
                        disabled={isLoading}>

                    Continuar o jogo
                </button>

                <button className={`btn btn-outline-secondary`}
                        onClick={onShowExplanationClick}
                        disabled={isLoading}>

                    {showExplanation && t('show_question')}
                    {!showExplanation && t('show_explanation')}
                </button>
            </div>
        )}

        {isDemonstration && !isAnswerable && (
            <button className="btn btn-sm btn-outline-secondary border-0 mt-3"
                    onClick={DemoSessionService.playQuestionVideo}
                    disabled={!hasPlayedQuestionVideo}
                    style={{opacity: hasPlayedQuestionVideo ? 1 : 0}}>

                <FontAwesomeIcon icon={['fas', 'circle-play']} className="mr-1"/>
                {t('watch_again')}
            </button>
        )}
    </>)
}


