import React, {memo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StepButton = ({title, icon, isCurrent, isMissingData, onClick, clickable = true}) => {

    const clasz = isCurrent ? 'current' : '';

    return (
        <div className={`step-button flex-fill ${clasz}`}>
            <div className="step-status-icon">
                {isMissingData && (
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-2 text-danger"/>)}
                {!isMissingData && (
                    <FontAwesomeIcon icon={['fas', 'check-circle']} className="mr-2 text-success"/>)}
            </div>

            <button type="button" className={"btn px-0 py-1 text-white " + (clickable ? '' : 'no-pointer-events')}
                    onClick={onClick}>
                <img alt="Button icon" src={icon} className="mb-1"/>
                <br/>

                <span className="no-wrap d-none d-md-block">{title}</span>
            </button>
        </div>
    );
}

export default memo(StepButton);