import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SessionReduxService from "services/redux/SessionReduxService";
import {GAME_MASTER_ROUTES, PLAYER_ROUTES} from "navigation/ROUTES";
import {useNavigate} from "react-router-dom";

import "./SidebarContent.scss";
import PlayerActionsButtons from "components/PlayerActionsButtons";
import Footer from "../../components/Footer";
import ButtonToggleFullscreen from "components/ButtonToggleFullscreen";
import GameEvents from "./components/GameEvents";
import TournamentButtons from "../../components/TournamentButtons";

export default function SidebarContent({onClose}) {
    const {t} = useTranslation('pages/common/board/header');

    const navigate = useNavigate();
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const isTournament = useSelector(state => state.game.isTournament);

    const handleBackToLobby = () => {
        if (isGameMaster) {
            SessionReduxService.updateShowBoardForTeam(null);
            navigate(GAME_MASTER_ROUTES.CONTROL_PANEL);
        } else {
            navigate(PLAYER_ROUTES.LOBBY);
        }
    }


    return (<div className="board-sidebar-content-container container pb-4" style={{zIndex: 4}}>
            <div className="test">
                {!isTournament && (<>
                    <PlayerActionsButtons checkinButtonClass="btn btn-link btn-lg font-weight-bold p-1 w-50 mb-2"
                                          npsButtonClass="btn btn-link btn-lg font-weight-bold p-1 w-50 mb-2"
                                          editPlayerButtonClass="btn btn-link btn-lg font-weight-bold p-1 w-50 mb-2"/>

                </>)}

                {isTournament && (
                    <TournamentButtons buttonsClass="btn btn-link btn-lg font-weight-bold w-100 px-1"/>)}
            </div>

            <hr/>
            <ButtonToggleFullscreen className="btn btn-link btn-lg font-weight-bold p-1 w-100"/>


            {!isTournament && (<>
                <button className="btn btn-link btn-lg font-weight-bold p-1 w-100 mt-3" onClick={handleBackToLobby}>
                    <FontAwesomeIcon icon={['fas', 'sign-out-alt']} className="mr-2"/>
                    <Trans t={t}
                           i18nKey={!isGameMaster ? 'texts.back_to_lobby_inline' : 'texts.back_to_control_panel_inline'}/>
                </button>
            </>)}

            <hr/>
            <Footer textClass={""}/>

            <hr/>
            <GameEvents/>
        </div>
    );
}