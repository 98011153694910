import {useCallback} from "react";
import {GAMES} from "../config/CONSTANTS";
import {useSelector} from "react-redux";


export default function useTranslationForGame(t) {
    const game = useSelector(state => state.game.gameInfo.game);

    return useCallback((key, params = {}, returnKeyOnly = false) => {
        key = game === GAMES.ESG ? key + '_esg' : key;

        return returnKeyOnly ? key : t(key, params);
    }, []);
}