import store from "redux/store";
import {
    addService,
    addSkill,
    removeService,
    removeSkill,
    setPitchBonus,
    setPitchData,
    setQuestionForStep,
    setQuestions,
    setServices,
    setSignedAccounting,
    setSkills,
    setUnpredictabilities,
    setUnpredictabilityForStep,
    updateFounder,
    updateFounderIcon,
    updateQuestion,
    updateStartup,
    updateUnpredictability,
    updateValueProposition
} from "redux/slices/teamsSlice";

import Utils from "utils/Utils";
import ToastUtil from "../../utils/ToastUtil";
import {i18n} from "../../i18n";
import EventsUtil from "../../utils/EventsUtil";
import {GAME_EVENTS} from "../../config/EVENTS";
import CardUtil from "../../utils/CardUtil";
import i18nUtil from "../../utils/i18nUtil";

let ignoreToasts = false;

async function toastIfIsCurrentTeam(team, toast, messageParams = {}, customT = false) {
    if (ignoreToasts)
        return;

    const currentTeam = store.getState().session.showBoardForTeam;
    const isTournament = store.getState().game.isTournament;

    if (isTournament)
        return;

    const t = await getT();

    if (currentTeam && currentTeam.toString() === team.toString()) {
        const title = customT
            ? i18nUtil.translateForGame(t, toast + '.title')
            : t(toast + '.title');

        const message = customT
            ? i18nUtil.translateForGame(t, toast + '.message', messageParams)
            : t(toast + '.message', messageParams);

        ToastUtil.toastInfo(title, message);
    }
}

async function getT() {
    return i18n.loadNamespaces('services/redux/team_redux_service')
        .then(() => i18n.getFixedT(null, 'services/redux/team_redux_service', 'toasts'));
}

const TeamReduxService = {
    toggleIgnoreToasts(ignore) {
        ignoreToasts = ignore;
    },

    updateAllTeamData(team, data) {
        ignoreToasts = true;

        if (Utils.isset(data.valuePropositions)) {
            for (let i = 0; i < 2; i++) {
                if (Utils.isset(data.valuePropositions[i]))
                    this.updateValueProposition(team, i, data.valuePropositions[i]);
            }
        }

        if (data.founders) {
            for (let i = 0; i < 2; i++) {
                if (data?.founders?.[i]) {
                    this.updateFounder(team, i, data.founders[i]);
                    this.updateFounderIcon(team, i, data.foundersIcons[i]);
                }
            }
        }

        if (data.skills) this.setSkills(team, data.skills);
        if (data.services) this.setServices(team, data.services);
        if (Utils.isset(data.startup)) this.updateStartup(team, data.startup);
        if (Utils.isset(data.questions)) this.setQuestions(team, data.questions);
        if (Utils.isset(data.unpredictabilities)) this.setUnpredictabilities(team, data.unpredictabilities);

        if (Utils.isset(data.signedAccounting)) {
            Object.entries(data.signedAccounting).forEach(([step, data]) => {
                TeamReduxService.updateSignedAccounting(team, step, data);
            });
        }

        if (data.pitchData) {
            Object.entries(data.pitchData).forEach(([step, data]) => {
                TeamReduxService.updatePitchData(team, step, data);
            });
        }

        ignoreToasts = false;
    },

    updateStartup(team, startup) {
        store.dispatch(updateStartup({team, startup}));
        toastIfIsCurrentTeam(team, 'startup_updated', {}, true);
        return true;
    },

    updateFounder(team, number, founder) {
        store.dispatch(updateFounder({team, number, founder}));
        toastIfIsCurrentTeam(team, 'founder_updated', {}, true);
        return true;
    },


    updateFounderIcon(team, number, icon) {
        store.dispatch(updateFounderIcon({team, number, icon}));
        return true;
    },

    updateValueProposition(team, number, valueProposition) {
        store.dispatch(updateValueProposition({team, number, valueProposition}));
        toastIfIsCurrentTeam(team, 'value_proposition_updated', {}, true);
        return true;
    },

    setQuestions(team, questions) {
        store.dispatch(setQuestions({team, questions}));
        return true;
    },

    setQuestionForStep(team, step, question) {
        store.dispatch(setQuestionForStep({team, step, question}));
        return true;
    },

    updateQuestion(team, step, data) {
        store.dispatch(updateQuestion({team, step, data}));
        if (data.answer) toastIfIsCurrentTeam(team, 'question_answer_updated');

        EventsUtil.publish(GAME_EVENTS.QUESTION_CARD_UPDATED, store.getState().teams[team].questions[step]);

        return true;
    },

    setSkills(team, data) {
        store.dispatch(setSkills({team, data}));
        return true;
    },

    setServices(team, data) {
        store.dispatch(setServices({team, data}));
        return true;
    },

    addSkill(team, step, data) {
        store.dispatch(addSkill({team, step, data}));
        toastIfIsCurrentTeam(team, 'skill_hired', {skill: data.card.title});
        return true;
    },

    refundSkill(team, step, hiringId) {
        const hiringData = CardUtil.getCardById(store.getState().teams[team].skills[step] ?? [], hiringId);

        if (hiringData)
            toastIfIsCurrentTeam(team, 'skill_refunded', {skill: hiringData.card.title});

        store.dispatch(removeSkill({team, step, hiringId}));
        return true;
    },

    addService(team, step, data) {
        store.dispatch(addService({team, step, data}));
        toastIfIsCurrentTeam(team, 'service_hired', {service: data.card.title});
        return true;
    },

    refundService(team, step, hiringId) {
        const hiringData = CardUtil.getCardById(store.getState().teams[team].services[step] ?? [], hiringId);

        if (hiringData)
            toastIfIsCurrentTeam(team, 'service_refunded', {service: hiringData.card.title});

        store.dispatch(removeService({team, step, hiringId}));
        return true;
    },

    setUnpredictabilities(team, data) {
        store.dispatch(setUnpredictabilities({team, data}));
        return true;
    },

    setUnpredictabilityForStep(team, step, data) {
        store.dispatch(setUnpredictabilityForStep({team, step, data}));

        EventsUtil.publish(GAME_EVENTS.UNPREDICTABILITY_CARD_UPDATED, store.getState().teams[team].unpredictabilities[step]);

        return true;
    },

    updateUnpredictability(team, step, data) {
        store.dispatch(updateUnpredictability({team, step, data}));

        EventsUtil.publish(GAME_EVENTS.UNPREDICTABILITY_CARD_UPDATED, store.getState().teams[team].unpredictabilities[step]);

        return true;
    },

    updateSignedAccounting(team, step, data) {
        store.dispatch(setSignedAccounting({team, step, data}));
        return true;
    },

    updatePitchData(team, step, data) {
        store.dispatch(setPitchData({team, step, data}));
        store.dispatch(setPitchBonus({team, step, amount: data.bonus}));

        return true;
    }

};

export default TeamReduxService;