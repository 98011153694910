import {library} from '@fortawesome/fontawesome-svg-core';


// icons
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowRight,
    faArrowUp,
    faAward,
    faBug,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faCheckCircle,
    faCircleNotch,
    faCircleUser,
    faClipboardList,
    faCogs,
    faCoins,
    faCopy,
    faCrown,
    faDesktop,
    faDollarSign,
    faEdit,
    faEllipsisH,
    faExclamationCircle,
    faExternalLinkAlt,
    faFastForward,
    faFileSignature,
    faHandsClapping,
    faHistory,
    faIdCard,
    faInfoCircle,
    faLink,
    faLock,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faMap,
    faMaximize,
    faMedal,
    faMinimize,
    faMinus,
    faMobile,
    faPause,
    faPauseCircle,
    faPercent,
    faPhoneAlt,
    faPlay,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faQuestion,
    faQuestionCircle,
    faSignal,
    faSignOutAlt,
    faStar,
    faSyncAlt,
    faTachometerAlt,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTools,
    faTrash,
    faTrophy,
    faUser,
    faUserEdit,
    faUsers,
    faVolumeMute,
    faVolumeUp
} from "@fortawesome/free-solid-svg-icons";

import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faHandPointLeft, faMinusSquare} from "@fortawesome/free-regular-svg-icons";


library.add([
    faSignal,
    faExclamationCircle,
    faCoins,
    faClipboardList,
    faMagnifyingGlassPlus,
    faCopy,
    faWhatsapp,
    faMagnifyingGlassMinus,
    faHistory,
    faInfoCircle,
    faMinus,
    faHandsClapping,
    faCircleUser,
    faIdCard,
    faStar,
    faMobile,
    faDesktop,
    faBug,
    faThumbsUp,
    faTrash,
    faTachometerAlt,
    faSyncAlt,
    faCaretLeft,
    faHandPointLeft,
    faMedal,
    faVolumeMute,
    faArrowUp,
    faVolumeUp,
    faMinimize,
    faMaximize,
    faArrowDown,
    faPause,
    faAward,
    faFileSignature,
    faCaretRight,
    faPercent,
    faQuestion,
    faQuestionCircle,
    faCogs,
    faEdit,
    faLink,
    faLock,
    faCrown,
    faMinusSquare,
    faCircleNotch,
    faDollarSign,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowRight,
    faCheck,
    faTrophy,
    faCheckCircle,
    faEllipsisH,
    faPhoneAlt,
    faAngleDoubleLeft,
    faPlay,
    faPlus,
    faFastForward,
    faPlusCircle,
    faSignOutAlt,
    faTimesCircle,
    faTools,
    faUser,
    faUserEdit,
    faUsers,
    faMap,
    faPlayCircle,
    faTimes,
    faPauseCircle,
    faExternalLinkAlt
]);