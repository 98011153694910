import React from 'react';
import {Modal} from 'react-bootstrap';
import {Trans, useTranslation} from "react-i18next";
import ValuePropositionCard, {VALUE_PROPOSITION_LAYOUT} from "components/cards/ValuePropositionCard";
import "./ValuePropositionConfirmModal.scss";

export default function ValuePropositionConfirmModal({currentCard, selectedCard, onSelect, onClose}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.value_propositions_modal'});

    return (<Modal show={true} onHide={onClose} size="xl" className="fade value-proposition-confirm-modal">
        <Modal.Header closeButton={true}>
            <Modal.Title>
                {t('texts.confirm_selection')}
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex flex-column align-items-center">
            <ValuePropositionCard valueProposition={selectedCard}
                                  layout={VALUE_PROPOSITION_LAYOUT.FULL}
                                  selectable={false}/>

            <div className="w-100 small px-3 mb-2 text-center">
                <Trans t={t} i18nKey={'texts.change'} components={{1: <b/>}}
                       values={{from: currentCard?.title || t('texts.none'), to: selectedCard.title}}/>
            </div>


        </Modal.Body>

        <Modal.Footer className="justify-content-center flex-wrap position-sticky bottom">
            <button className={"btn btn-primary"} onClick={onSelect}>
                {t('buttons.select')}
            </button>

            <button className={"btn btn-outline-secondary text-dark"} onClick={onClose}>
                {t('buttons.cancel')}
            </button>
        </Modal.Footer>
    </Modal>);
}
