import React, {useMemo} from "react";

import "./ColumnView.scss";

export const COLUMN_VIEW_STYLES = {
    WHITE_WARNING: 1,
    INFO_WHITE: 2,
}

export default function ColumnView({leftItems, rightItems, style = COLUMN_VIEW_STYLES.WHITE_WARNING}) {
    const renderedItems = useMemo(() => {
        const result = [];

        for (let i = 0; i < leftItems.length || i < rightItems.length; i++) {
            const leftItem = leftItems[i];
            const rightItem = rightItems[i];

            result.push((<div className="column-view-row" key={i}>
                {leftItem && (
                    <div key={leftItem.value}>
                        <p>{leftItem.title}</p>
                        <p className={leftItem.valueClass ?? ''}>{leftItem.value}</p>
                    </div>
                )}

                {rightItem && (
                    <div>
                        <p>{rightItem.title}</p>
                        <p className={rightItem.valueClass ?? ''}>{rightItem.value}</p>
                    </div>
                )}

            </div>));
        }

        return result;
    }, [leftItems, rightItems]);

    return (<div className={`column-view style-${style}`}>
        {renderedItems}
    </div>);
}