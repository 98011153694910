import Option from "./SidebarContent/components/Option";
import {MOBILE_GAME_TABS} from "config/MOBILE";
import React from "react";
import "./TabsFooter.scss";

export default function TabsFooter() {
    return (
        <div className="tabs-footer">
            <Option tab={MOBILE_GAME_TABS.CHALLENGES} showNotification={true}/>
            <Option tab={MOBILE_GAME_TABS.STARTUP_PROFILE} showNotification={true}/>
            <Option tab={MOBILE_GAME_TABS.JOURNEY_MAP} showNotification={true}/>
            <Option tab={MOBILE_GAME_TABS.ACCOUNTING} id="btn-accounting-details" showNotification={true}/>
            <Option tab={MOBILE_GAME_TABS.HIRED_CARDS} id="btn-hirings-details" showNotification={true}/>
        </div>
    );
}