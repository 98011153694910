import React, {useEffect} from "react";
import FadeIn from "assets/plugins/react-fade-in/FadeIn";

import Header from "./components/Header/Header";
import PlayerList from "./components/PlayerList/PlayerList";
import GameInfo from "./components/GameInfo/GameInfo";

import "./ControlPanel.scss";
import GameConfig from "./components/GameConfig/GameConfig";
import TeamControl from "./components/TeamControl/TeamControl";
import GameControl from "./components/GameControl/GameControl";
import OverlayUtil from "utils/OverlayUtil";
import GameSessionService from "services/common/GameSessionService";
import GameEventSubscriber from "subscribers/GameEventSubscriber";
import store from "redux/store";
import StepStatus from "./components/StepStatus/StepStatus";
import Footer from "./components/Footer";
import EventProcessor from "services/common/gameEvents/EventProcessor";
import TeamEventSubscriber from "subscribers/TeamEventSubscriber";
import {useSelector} from "react-redux";
import Board from "../../common/Board/Board";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import SessionReduxService from "../../../services/redux/SessionReduxService";
import useForceUpdate from "../../../hooks/useForceUpdate";
import AccountingService from "../../../services/common/AccountingService";
import {setIsMobile} from "../../../redux/slices/sessionSlice";


export default function ControlPanel() {
    const {t} = useTranslation('pages/game_master/control_panel', {useSuspense: true});

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const teamCount = useSelector(state => state.game.teamCount);
    const customization = useSelector(state => state.game.customization);
    const forceUpdate = useForceUpdate();

    const handleGoToErrorPage = () => {
        return SessionReduxService.updateHasFatalError(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            OverlayUtil.toggleLoadingOverlay(true, t('overlays.loading_data'));

            EventProcessor.pause();

            try {
                const subscribedToEvents = await GameEventSubscriber.subscribe();
                if (!subscribedToEvents) return handleGoToErrorPage();

                for (const value of [1, 2, 3, 4]) {
                    if (!await TeamEventSubscriber.subscribe(value))
                        return handleGoToErrorPage();
                }

                const hasFetchedGameData = await GameSessionService.fetchAllGameData();
                if (!hasFetchedGameData) return handleGoToErrorPage();

                return EventProcessor.resume();
            } catch (e) {
                console.error(e);
                LoggerService.error(e, {action: 'load game data - control panel'});
                SessionReduxService.updateHasFatalError(true);
            } finally {
                OverlayUtil.toggleLoadingOverlay(false);
            }
        };

        if (store.getState().session.hasLoadedGameData === false) {
            fetchData();
            document.title = t('tab_title')
        }
    }, []);


    useEffect(() => {
        for (let i = 1; i < teamCount + 1; i++) {
            AccountingService.calculateForTeam(i);
        }
    }, [teamCount])

    // re-render on customization change
    useEffect(() => forceUpdate(), [customization]);

    // force desktop view for game masters
    useEffect(() => {
        store.dispatch(setIsMobile(false));
    }, []);

    if (!showBoardForTeam) {
        return (
            <div id="control-panel" className="d-flex align-items-center flex-column m-auto w-100 h-100">
                <Header/>

                <FadeIn className="container-custom flex-fill px-5 mt-5 pb-2 w-100">
                    <div className="row ">
                        <div className="col-8">
                            <GameInfo/>
                            <GameConfig/>
                            <StepStatus/>
                            <GameControl/>
                        </div>

                        <div className="col-4">
                            <PlayerList/>
                            <TeamControl/>
                        </div>
                    </div>

                    <Footer/>
                </FadeIn>
            </div>
        );
    } else {
        return <Board/>;
    }
}