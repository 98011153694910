import {CHALLENGE_TYPE, GAME_STEPS, GAME_STEPS_WITH_PITCH} from "config/CONSTANTS";
import store from "redux/store";
import CardUtil from "utils/CardUtil";
import {i18nService} from "i18n";
import GameUtil from "utils/GameUtil";
import i18nUtil from "utils/i18nUtil";

function getT() {
    return i18nService.getFixedT({
        ns: 'pages/common/board/challenges',
        keyPrefix: 'services.challenges_service'
    });
}

function handleShowAccounting() {
    document.getElementById('btn-accounting-details')?.click();
}

const ChallengesComponentService = {
    touchChallengesList(handleShowConceptionModal) {
        let res = [];

        const t = getT();

        const currentStep = store.getState().game.currentStep;
        const ranking = store.getState().game.ranking;
        const showBoardForTeam = store.getState().session.showBoardForTeam;
        const teamData = store.getState().teams[showBoardForTeam];

        const signedAccounting = teamData.signedAccounting;
        const hiredAccountingService = teamData.hiredAccountingService;

        const isMobile = store.getState().session.isMobile;

        if (currentStep === GAME_STEPS.CONCEPTION) {
            if (teamData && teamData.startup && teamData.founders && teamData.founders[0] && teamData.founders[1]
                && teamData.valuePropositions && teamData.valuePropositions[0] && teamData.valuePropositions[1]) {
                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: i18nUtil.translateForGame(t, 'conception_done.title'),
                        description: i18nUtil.translateForGame(t, 'conception_done.message'),
                        actionButtonText: t('conception_done.action'),
                        actionCallback: handleShowConceptionModal,
                    }
                });
            } else {
                if (store.getState().game.usePreSelectedStartups === true) {
                    res.push({
                        type: CHALLENGE_TYPE.OBJECTIVE,
                        data: {
                            title: i18nUtil.translateForGame(t, 'conception_pre_defined.title'),
                            description: i18nUtil.translateForGame(t, 'conception_pre_defined.message'),
                            actionButtonText: t('conception_pre_defined.action'),
                            actionCallback: handleShowConceptionModal
                        }
                    });
                } else {
                    res.push({
                        type: CHALLENGE_TYPE.OBJECTIVE,
                        data: {
                            title: i18nUtil.translateForGame(t, 'conception_all.title'),
                            description: i18nUtil.translateForGame(t, 'conception_all.message'),
                            actionButtonText: t('conception_all.action'),
                            actionCallback: handleShowConceptionModal,
                            isConception: true,
                        }
                    });
                }
            }
        } else if (currentStep === GAME_STEPS.END_GAME) {
            if (ranking[showBoardForTeam] && ranking[showBoardForTeam].show) res.push({
                type: CHALLENGE_TYPE.TEAM_RANKING,
                data: ranking[showBoardForTeam]
            });

            if (Object.values(ranking).length) res.push({type: CHALLENGE_TYPE.RANKING, data: ranking});

            let didRevealAllRankings = true;
            if (ranking && Object.values(ranking)) {
                const objValue = Object.values(ranking);

                for (let i = 0; i < objValue.length; i++) {
                    if (objValue[i]?.show !== true) {
                        didRevealAllRankings = false;
                        break;
                    }
                }
            } else {
                didRevealAllRankings = false;
            }

            let message = '';
            let title = '';
            if (!Object.values(ranking).length) {
                title = t('game_finished_no_podium.title')
                message = t('game_finished_no_podium.message');
            } else if (!didRevealAllRankings) {
                title = t('end_game.title');
                message = t('end_game.message');
            } else {
                title = t('game_finished.title')
                message = t('game_finished.message');
            }

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {title, description: message}
            });
        } else if (GAME_STEPS_WITH_PITCH.includes(currentStep)) {
            const pitches = store.getState().cards.pitches;
            const pitch = CardUtil.getPitchForPhase(pitches, store.getState().game.currentPhase);

            if (pitch)
                res.push({type: CHALLENGE_TYPE.PITCH_CARD, data: pitch});
        }


        // add questions
        if (currentStep !== GAME_STEPS.END_GAME) {
            let appendAccountingMessage = false;

            for (let i = 0; i <= currentStep; i++) {
                const teamQuestion = teamData?.questions[i];

                if (teamQuestion && (teamQuestion?.showResult !== true || teamQuestion?.revealedAt === currentStep)) {
                    res.push({type: CHALLENGE_TYPE.QUESTION, data: teamQuestion});
                }

                if (!GameUtil.isCurrentGameEsg() && teamQuestion?.revealedAt === currentStep && (!signedAccounting[i] && !hiredAccountingService)) {
                    appendAccountingMessage = true;
                }
            }

            for (let i = 0; i <= currentStep; i++) {
                const teamUnpredictability = teamData?.unpredictabilities[i];

                if (!teamUnpredictability)
                    continue;

                const isForCurrentStep = teamUnpredictability.step.toString() === currentStep.toString();
                const revealedAtCurrentStep = teamUnpredictability.revealedAt === currentStep;
                const hasNotBeenRevealed = teamUnpredictability.reveal !== true || teamUnpredictability.revealOutcome !== true;

                if (isForCurrentStep || hasNotBeenRevealed || revealedAtCurrentStep) {
                    res.push({type: CHALLENGE_TYPE.UNPREDICTABILITY, data: teamUnpredictability});
                }
            }

            if (appendAccountingMessage) {
                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: t('check_accounting.title'),
                        description: isMobile ? t('check_accounting.message_mobile') : t('check_accounting.message'),
                        isAccounting: true,
                        actionButtonText: t('check_accounting.action'),
                        actionCallback: handleShowAccounting,
                    }
                });
            }
        }

        return res;
    }
};

export default ChallengesComponentService;