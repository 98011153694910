import React, {useMemo, useState} from "react"
import {useSelector} from "react-redux";
import CardUtil from "utils/CardUtil";
import {useTranslation} from "react-i18next";
import ToastUtil from "utils/ToastUtil";
import CardService from "services/common/CardService";
import MarketModal from "./components/MarketModal";
import DemoSessionService from "services/common/DemoSessionService";
import {Modal} from "react-bootstrap";
import ServiceCard from "components/cards/ServiceCard";
import {GAME_CARD_TYPE} from "config/CONSTANTS";
import Utils from "utils/Utils";

export default function ServiceMarket({onClose}) {
    const {t} = useTranslation('pages/common/board/market_modal', {keyPrefix: 'components.services'});
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    const services = useSelector(state => state.cards.services);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);

    const availableForMarket = useSelector(state => state.teams[showBoardForTeam].accounting?.totals?.availableForMarket);
    const canHireCards = useSelector(state => state.game.canHireCards);
    const isFinished = useSelector(state => state.game.isFinished);
    const hiredServices = useSelector(state => state.teams[showBoardForTeam].services);
    const hiredSkills = useSelector(state => state.teams[showBoardForTeam].skills);
    const isDemonstration = useSelector(state => state.game.isDemonstration);

    const [hiringInProgressFor, setHiringInProgressFor] = useState({});

    const hirableServices = useMemo(() => {
        const hiredServicesIds = CardUtil.hiredCardsToArrayOfGameCards(hiredServices).map(card => card.id);

        return isFinished ? [...services] : [...services.filter(s => !hiredServicesIds.includes(s.id))];
    }, [isFinished, services, hiredServices]);

    const skillsToCheckRequirements = useMemo(() => {
        const allSkills = CardUtil.hiredCardsToArrayOfGameCards(hiredSkills);
        if (founders[0]) allSkills.push(founders[0]);
        if (founders[1]) allSkills.push(founders[1]);

        return allSkills;
    }, [hiredSkills, founders]);

    const handleCheckIfMeetsRequirement = (card) => {
        if (!card.hasRequirements())
            return true;

        return CardUtil.checkIfHasRequirementsForService(skillsToCheckRequirements, hiredServices, card);
    };


    const servicesRequirementsInfo = useMemo(() => {
        const requirementsInfo = {};

        hirableServices.forEach(service => {
            if (service.hasRequirements())
                requirementsInfo[service.id] = handleCheckIfMeetsRequirement(service);
        });

        return requirementsInfo;
    }, [hirableServices, skillsToCheckRequirements]);


    const forceRerender = useMemo(() => {
        return Utils.uuid();
    }, [availableForMarket, hiringInProgressFor, hirableServices, servicesRequirementsInfo]);

    const handleSetHiringInProgressFor = (card, isInProgress = false) => {
        const inProgressFor = {...hiringInProgressFor};
        if (isInProgress) inProgressFor[card.id] = true;
        else delete inProgressFor[card.id];

        setHiringInProgressFor(inProgressFor);
    };

    const handleHire = async (service) => {
        if (isDemonstration) {
            DemoSessionService.hireService({service});

            return;
        }

        handleSetHiringInProgressFor(service, true);

        const hired = await CardService.hireService(service.id);
        if (hired) {
            ToastUtil.toastSuccess(
                t('toasts.hire_success.title'),
                t('toasts.hire_success.message', {service: service.title}),
                {soundEffect: false}
            );
        } else {
            ToastUtil.toastDanger(t('toasts.hire_error.title'), t('toasts.hire_error.message'));
        }

        handleSetHiringInProgressFor(service, false);
    };

    const handleOpenSkillMarket = () => {
        onClose();
        document.getElementById('btn-open-skill-market').click();
    }

    const handleRender = (card) => {
        return (<ServiceCard key={card.id}
                             service={card}
                             isLoading={hiringInProgressFor[card.id] || false}
                             hasCreditToHire={availableForMarket >= card.price}
                             hirable={!isFinished && canHireCards}
                             meetRequirements={servicesRequirementsInfo[card.id] ?? true}
                             onHire={handleHire}/>
        );
    }


    return (<MarketModal onClose={onClose}
                         cards={hirableServices}
                         handleRenderItem={handleRender}
                         forceRerender={forceRerender}
                         type={GAME_CARD_TYPE.SERVICE}>

            <Modal.Title>{t('texts.title')}</Modal.Title>
            <p className="mb-0 small">{t('texts.click_info')}</p>
            <p className="mb-1 small">{t('texts.hire_info')}</p>

            <button onClick={handleOpenSkillMarket} className="btn btn-warning px-2 py-0 rounded">
                {t('buttons.open_skill_market')}
            </button>
        </MarketModal>
    );
}