import React, {useState} from "react";
import store from "../../../../redux/store";
import AdvanceButtons from "../components/AdvanceButtons";
import {useSelector} from "react-redux";
import ToastUtil from "../../../../utils/ToastUtil";

const DEFAULT_DATA = {
    name: '',
    position: '',
}

export default function CertificateStep({data, onAdvance, onBack, isLastStep}) {
    const canRequestCertificate = useSelector(state => state.game.tournamentInfo.canRequestCertificate);

    const [certificate, setCertificate] = useState({...DEFAULT_DATA, ...(data ?? {})});

    const buildData = (ignoreErrors = false) => {
        const playerData = store.getState().session.user;
        const position = certificate.position.trim();

        const data = {
            name: certificate.name.trim(),
            email: playerData.email
        };

        if (!!position === true)
            data.position = position;


        if (!!data.name !== true && !ignoreErrors) {
            setCertificate({
                name: data.name,
                position: data.position ?? ''
            });

            ToastUtil.toastDanger('Verifique os dados informados!');

            return false;
        } else {
            return {certificate: data};
        }
    }

    const handleSubmit = ev => {
        ev.preventDefault();

        if (!canRequestCertificate) {
            onAdvance({});
            return;
        }

        const data = buildData();

        if (data)
            onAdvance(data);
    }

    const handleBack = () => {
        onBack(buildData(true));
    }

    const handleUpdateField = (field, ev) => {
        setCertificate({
            ...certificate,
            [field]: ev.target.value
        });
    }

    return (<form onSubmit={handleSubmit} className="animate__animated animate__fadeIn">

        {canRequestCertificate && (<>
            <h4 className="mb-5 text-warning">
                Que tal já solicitar o seu certificado?
            </h4>

            <p className="mb-1">
                Preencha os campos abaixo para garantir o seu certificado. Ele será enviado em até 2 horas
                após você finalizar a sessão.
            </p>

            <p className="mb-4 text-info">
                Para garantir que o certificado seja gerado, você deve jogar a sessão até o final!
            </p>

            <div className="row">
                <div className="col-12 col-md-6 mb-2">
                    <input value={certificate.name} name="name" className="form-control" type="text"
                           autoFocus={true}
                           placeholder="Nome completo *"
                           onChange={ev => handleUpdateField('name', ev)}
                           required/>
                </div>

                <div className="col-12 col-md-6 mb-2">
                    <input value={certificate.position} name="position" className="form-control" type="text"
                           onChange={ev => handleUpdateField('position', ev)}
                           placeholder="Cargo"/>
                </div>
            </div>
        </>)}

        {!canRequestCertificate && (<>
            <h4 className="mb-5 text-warning">
                Parece que esta não é a sua primeira sessão!
            </h4>

            <h5 className="mb-3">
                Você pode solicitar somente um certificado de participação, para cada torneio.
            </h5>

            <h5 className="mb-3">
                Para ver o seu certificado, confira o seu e-mail! Nós o enviamos quando você finalizou a sua primeira
                sessão, neste torneio.
            </h5>

            <h5 className="mb-5">
                Por isso, vamos pular a solicitação do certificado neste momento.
            </h5>
        </>)}

        <AdvanceButtons onBack={handleBack} isLastStep={isLastStep} isFirstStep={false}/>
    </form>);
}