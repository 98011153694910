import React from "react";

export default function AdvanceButtons({isFirstStep, isLastStep, onBack}) {
    return (<div className="d-flex flex-column flex-md-row justify-content-center align-items-md-center mt-4"
                 style={{gap: 15}}>

        <button type="submit" className="btn btn-success">
            {isLastStep ? 'Iniciar jogo agora!' : 'Continuar'}
        </button>

        <button type="button" className="btn btn-sm btn-outline-secondary border-0"
                onClick={onBack} disabled={isFirstStep}>
            Voltar
        </button>
    </div>);
}