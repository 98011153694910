import {GAMES} from "./CONSTANTS";

export const MOBILE_GAME_TABS = {
    CHALLENGES: 'challenges',
    STARTUP_PROFILE: 'start_profile',
    JOURNEY_MAP: 'journey_map',
    ACCOUNTING: 'accounting',
    HIRED_CARDS: 'hired_cards',
    EVENTS_LOG: 'events_log'
}

export const MOBILE_TAB_ICON = {
    [MOBILE_GAME_TABS.CHALLENGES]: 'clipboard-list',
    [MOBILE_GAME_TABS.STARTUP_PROFILE]: 'id-card',
    [MOBILE_GAME_TABS.JOURNEY_MAP]: 'map',
    [MOBILE_GAME_TABS.ACCOUNTING]: {
        [GAMES.ESG]: 'desktop',
        [GAMES.SMGX]: 'coins'
    },
    [MOBILE_GAME_TABS.HIRED_CARDS]: 'users',
    [MOBILE_GAME_TABS.EVENTS_LOG]: 'history',
}