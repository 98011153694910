import React from 'react';
import {Modal} from "react-bootstrap";

export default function MobileModalFooter({scrollContainer = '.modal.show', onClose}) {
    const handleScrollToTop = () => {
        document.querySelector(scrollContainer)?.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <Modal.Footer className="position-sticky bottom p-0 d-flex border-top" style={{borderRadius: 0, zIndex: 3}}>
            <button className="btn btn-secondary px-0 flex-fill btn-top" onClick={handleScrollToTop}>
                Topo
            </button>

            <span className="border-right">&nbsp;</span>

            <button className="btn btn-secondary px-0 flex-fill" onClick={onClose}>
                Fechar
            </button>
        </Modal.Footer>
    )
}