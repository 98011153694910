import React, {useEffect, useMemo} from "react";
import {GAME_EVENT_TYPES} from "config/CONSTANTS";
import InfoEvent from "./components/InfoEvent";
import PhaseEventSeparator from "./components/PhaseEventSeparator";
import UnpredictabilityCard from "./components/UnpredictabilityCard/UnpredictabilityCard";
import QuestionCardPreview from "./components/QuestionCard/QuestionCardPreview";
import PitchBonusCard from "./components/PitchBonusCard";
import PitchCapitalCard from "./components/PitchCapitalCard";
import GameEventsComponentService from "./services/GameEventsComponentService";
import {useSelector} from "react-redux";
import {createSelector} from '@reduxjs/toolkit';
import Utils from "utils/Utils";

function selectTeamAttributesToWatch() {
    return createSelector(
        [(state) => state.teams, (state) => state.gameSession.teamCount],
        getTeamsAttr
    );
}

function getTeamsAttr(teams, teamCount) {
    const res = {};

    for (let i = 1; i <= teamCount; i++) {
        res[i] = Utils.objFilterKeys(
            teams[i],
            ['questions', 'unpredictabilities', 'accelerationBonuses']
        );
    }

    return res;
}


export default function EventsList({onListUpdated, className = ""}) {
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentStep = useSelector(state => state.game.currentStep);
    const currentTeamAccounting = useSelector(state => state.teams[showBoardForTeam].accounting);
    const ranking = useSelector(state => state.game.ranking);
    const teamAttributesToWatch = useSelector(selectTeamAttributesToWatch);

    const eventsToDisplay = useMemo(() => {
        return GameEventsComponentService.touchEventsList()
    }, [currentTeamAccounting, currentStep, ranking, teamAttributesToWatch]);


    useEffect(() => {
        if (onListUpdated)
            setTimeout(onListUpdated, 1);
    }, [eventsToDisplay]);


    return (<div className={className}>
        {eventsToDisplay.map((ev) => {
            return (
                <div key={ev.id}>
                    {ev.type === GAME_EVENT_TYPES.INFO && (
                        <InfoEvent data={ev.data}/>)}

                    {ev.type === GAME_EVENT_TYPES.PHASE_SEPARATOR && (
                        <PhaseEventSeparator data={ev.data}/>)}

                    {ev.type === GAME_EVENT_TYPES.UNPREDICTABILITY && (
                        <UnpredictabilityCard data={ev.data}/>)}

                    {ev.type === GAME_EVENT_TYPES.QUESTION && (
                        <QuestionCardPreview questionData={ev}/>)}

                    {ev.type === GAME_EVENT_TYPES.PITCH_BONUS && (
                        <PitchBonusCard data={ev.data}/>)}

                    {ev.type === GAME_EVENT_TYPES.PITCH_INVESTMENT && (
                        <PitchCapitalCard data={ev.data}/>)}
                </div>
            )
        })}
    </div>)
}