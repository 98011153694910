import React, {useEffect, useRef, useState} from "react";
import SimpleBar from "simplebar-react";

import "./GameEvents.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EventsList from "../components/GameEvents/EventsList";

export default function GameEvents() {
    const scrollBarRef = useRef();

    const [pendingTimeout, setPendingTimeout] = useState(undefined);

    const scrollTo = (pos, withEffect = true) => {
        scrollBarRef.current.getScrollElement().scrollTo({
            left: pos,
            behavior: withEffect ? "smooth" : 'auto'
        });
    };

    const handleScrollToEnd = () => {
        if (scrollBarRef.current)
            scrollBarRef.current.getScrollElement().scrollLeft = scrollBarRef.current.getScrollElement().scrollWidth;
    };

    const handleScrollLeft = () => {
        const el = scrollBarRef.current.getScrollElement();
        scrollTo(el.scrollLeft - el.offsetWidth);
    };

    const handleScrollRight = () => {
        const el = scrollBarRef.current.getScrollElement();
        scrollTo(el.scrollLeft + el.offsetWidth);
    };

    const handleListUpdated = () => {
        clearTimeout(pendingTimeout);

        setPendingTimeout(setTimeout(() => {
            requestAnimationFrame(handleScrollToEnd);
        }, 500));
    }

    useEffect(() => {
        setTimeout(() => requestAnimationFrame(handleScrollToEnd));

        // we need to reset the width of the scroll container
        // without doing it, the width stays the same and can cause a visible overflow
        // couldn't find a proper solution for this using the scroll plugin API
        const resizeListener = function (ev) {
            if (scrollBarRef)
                scrollBarRef.current.placeholderEl.style.width = 'auto';
        }

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
            clearTimeout(pendingTimeout);
        }
    }, []);


    return (<div className="game-events desktop w-100">
        <div className="h-100 px-4 py-3 w-100 d-flex justify-content-between">
            <div className="m-auto">
                <button type="button" className="btn btn-link p-0 pr-2 mr-2" onClick={handleScrollLeft}>
                    <FontAwesomeIcon icon={['fas', 'caret-left']} className="fa-4x"/>
                </button>
            </div>

            <SimpleBar ref={scrollBarRef} style={{overflowY: 'visible', height: '100%'}} className="flex-fill">
                <EventsList onListUpdated={handleListUpdated} className="h-100 d-flex flex-fill"/>
            </SimpleBar>

            <div className="d-flex justify-content-center align-items-center position-relative h-100">
                <button type="button" className="btn btn-link p-0 pl-2 ml-2" onClick={handleScrollRight}>
                    <FontAwesomeIcon icon={['fas', 'caret-right']} className="fa-4x"/>
                </button>
            </div>
        </div>
    </div>);
}