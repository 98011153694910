import React, {useEffect, useState} from "react";

import "./ObjectiveChallenge.scss";
import ObjectiveCard from "components/cards/ObjectiveCard";
import {useSelector} from "react-redux";
import DemoSessionService from "services/common/DemoSessionService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function ObjectiveChallenge({data}) {
    const {
        title,
        description,

        // main action
        actionButtonText,
        actionCallback = false,

        // secondary action
        showSecondaryActionButton = false,
        secondaryActionButtonText,
        secondaryActionCallback = false,
        secondaryActionButtonClass = 'btn btn-outline-secondary border-0',


        isConception = false,
        isDemoIntro = false,
        isTournamentMonitor = false,

        // on state change
        onShow = false,
        onHide = false,
        showLoadingOnClick = false,
        showLoadingOnSecondaryClick = null,
    } = data;

    const {t} = useTranslation('common');

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const hasPlayedIntroVideo = useSelector(state => state.demo.state.hasPlayedIntroVideo);

    const [isLoading, setIsLoading] = useState(false);

    const handleSkipConception = async () => {
        DemoSessionService.skipConception();
    };

    const handlePrimaryClick = async () => handleClick(actionCallback, showLoadingOnClick);
    const handleSecondaryClick = async () => handleClick(secondaryActionCallback, showLoadingOnClick || showLoadingOnSecondaryClick !== false);

    const handleClick = async (callback, showLoading) => {
        if (!callback)
            return;

        if (showLoading)
            setIsLoading(true);

        const status = await callback();

        if (status === false && showLoading)
            setIsLoading(false);
    }

    useEffect(() => {
        if (onShow)
            onShow();

        if (onHide)
            return onHide;
    }, []);

    return (
        <div className="objective-challenge h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <ObjectiveCard title={title}
                           description={description}
                           actionCallback={handlePrimaryClick}
                           actionButtonText={actionButtonText}
                           isLoading={isLoading}/>

            {isConception && isDemonstration && (
                <button className="btn btn-sm btn-outline-secondary border-0 mt-3" onClick={handleSkipConception}>
                    {t('skip_conception')}
                </button>
            )}

            {showSecondaryActionButton && (
                <button className={secondaryActionButtonClass + ' mt-3 rounded'}
                        onClick={handleSecondaryClick}
                        disabled={isLoading}>

                    {secondaryActionButtonText}
                </button>
            )}

            {isDemonstration && isDemoIntro && hasPlayedIntroVideo && (
                <button className="btn btn-sm btn-outline-secondary border-0 mt-3"
                        onClick={DemoSessionService.playWelcomeVideo}>

                    <FontAwesomeIcon icon={['fas', 'circle-play']} className="mr-1"/>
                    {t('watch_again')}
                </button>
            )}
        </div>
    );
}