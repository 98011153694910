import React, {useEffect, useMemo, useState} from "react";
import {Header} from "../componentsMobile/Header";
import {GAME_STEPS} from "config/CONSTANTS";
import {useSelector} from "react-redux";
import store from "redux/store";
import {setSelectedMobileTab} from "redux/slices/sessionSlice";
import StartupProfile from "../componentsMobile/StartupProfile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HiredCards from "../componentsMobile/HiredCards/HiredCards";
import {Challenges} from "../components/Challenges/Challenges";
import JourneyMapSummaryCard from "../componentsMobile/JourneyMapSummaryCard";
import Accounting from "../componentsMobile/Accounting/Accounting";
import {MOBILE_GAME_TABS, MOBILE_TAB_ICON} from "config/MOBILE";
import TabsFooter from "../componentsMobile/TabsFooter";
import NotificationWatcher from "../componentsMobile/NotificationWatcher";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "hooks/useTranslationForGame";
import ButtonSpinner from "components/ButtonSpinner";
import useElementSize from "hooks/useElementSize";
import useWindowSize from "hooks/useWindowSize";

// offset used to show or not the continue button at the bottom
const CONTINUE_AT_BOTTOM_OFFSET = -100; // in px


export default function Mobile() {
    const {t} = useTranslation('pages/common/board/mobile_tabs');
    const tfg = useTranslationForGame(t);

    const [sizeTargetRef, elementSize] = useElementSize();
    const windowSize = useWindowSize();

    const currentStep = useSelector(state => state.game.currentStep);
    const selectedMobileTab = useSelector(state => state.session.selectedMobileTab);
    const game = useSelector(state => state.game.gameInfo.game);
    const advanceButtonState = useSelector(state => state.tournament.advanceButtonState);

    const [isLoading, setIsLoading] = useState(false);

    const icon = useMemo(() => {
        if (typeof MOBILE_TAB_ICON[selectedMobileTab] === 'object')
            return MOBILE_TAB_ICON[selectedMobileTab]?.[game] ?? 'question-circle'

        return MOBILE_TAB_ICON[selectedMobileTab];
    }, [selectedMobileTab, game]);

    const showContinueAtBottom = useMemo(() => {
        const heightDiff = windowSize.height - elementSize.height - CONTINUE_AT_BOTTOM_OFFSET;

        return heightDiff < 0;
    }, [advanceButtonState, elementSize, windowSize]);

    const handleAdvanceClick = async () => {
        setIsLoading(true);

        advanceButtonState.callback().finally(() => {
            setIsLoading(false);
        })
    }


    useEffect(() => {
        store.dispatch(setSelectedMobileTab(MOBILE_GAME_TABS.CHALLENGES));
    }, []);


    return (<div id="board" className="mobile min-vh-100 position-relative">
        <Header/>
        <NotificationWatcher/>

        <div key={selectedMobileTab} ref={sizeTargetRef}
             className="board container w-100 container py-3 animate__animated animate__fast animate__fadeIn"
             style={{zIndex: currentStep === GAME_STEPS.CONCEPTION ? 5 : 2}}>

            <h3 className="mb-4 text-white text-center">
                <FontAwesomeIcon icon={['fas', icon]} className="mr-2"/>
                {tfg(selectedMobileTab)}
            </h3>

            {advanceButtonState?.show && (
                <div className="w-100 text-center animate__animated animate__fadeIn animate__faster mb-4">
                    <ButtonSpinner showAnimation={isLoading} className="btn btn-success  btn-advance-mobile"
                                   onClick={handleAdvanceClick}>

                        Avançar jogo
                    </ButtonSpinner>
                </div>
            )}


            {selectedMobileTab === MOBILE_GAME_TABS.CHALLENGES && (<Challenges/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.STARTUP_PROFILE && (<StartupProfile/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.HIRED_CARDS && (<HiredCards/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.JOURNEY_MAP && (<JourneyMapSummaryCard/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.ACCOUNTING && (<Accounting/>)}


            {selectedMobileTab === MOBILE_GAME_TABS.CHALLENGES && advanceButtonState?.show && showContinueAtBottom && (
                <div className="w-100 text-center animate__animated animate__fadeIn animate__faster mb-4">
                    <ButtonSpinner showAnimation={isLoading} className="btn btn-success  btn-advance-mobile"
                                   onClick={handleAdvanceClick}>

                        Avançar jogo
                    </ButtonSpinner>
                </div>
            )}
        </div>

        <TabsFooter/>
    </div>);
}