import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function RemainingQuestionsInfo({count}) {
    const {t} = useTranslation('pages/common/board/challenges');

    const isMobile = useSelector(state => state.session.isMobile);

    const clasz = isMobile
        ? 'w-100 text-center'
        : 'ml-4';


    if (!count?.total)
        return null;

    return (<p className={`remaining-questions-info text-white small mb-4 ${clasz}`}>
        {t('texts.questions_answered', count)}
    </p>)
}