import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";

import "./TournamentPitchModal.scss";
import logo from "assets/img/logos/svg/logo_only.svg";
import {useSelector} from "react-redux";
import Item from "./components/Item";
import i18nUtil from "../../../utils/i18nUtil";
import SelectedItemBadge from "./components/SelectedItemBadge";
import AudioService from "../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../config/AUDIO_FILES";
import ButtonSpinner from "../../../components/ButtonSpinner";
import TournamentService from "../../../services/common/TournamentService";
import {GAMES} from "../../../config/CONSTANTS";

import tournament_pitch_esg from "data/tournament_pitch_esg.json";
import tournament_pitch_smgx from "data/tournament_pitch_smgx.json";
import TOURNAMENT_CONFIG from "../../../config/TOURNAMENT_CONFIG";
import MobileModalFooter from "../../../components/MobileModalFooter";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../hooks/useTranslationForGame";
import GameUtil from "../../../utils/GameUtil";

export default function TournamentPitchModal({onClose}) {
    const {t} = useTranslation('services/tournament/tournament_challenges');
    const tfg = useTranslationForGame(t);

    const isMobile = useSelector(state => state.session.isMobile);
    const currentStep = useSelector(state => state.game.currentStep);
    const game = useSelector(state => state.game.gameInfo.game);
    const pitchData = useSelector(state => state.teams[TOURNAMENT_CONFIG.TEAM].pitchData?.[currentStep]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [revealOutcome, setRevealOutcome] = useState(!!pitchData);

    const selectedOptionsIds = useMemo(() => {
        return selectedOptions.reduce((prev, curr) => {
            prev[curr.id] = true;

            return prev;
        }, {});
    }, [selectedOptions]);

    const options = useMemo(() => {
        const newOptions = game === GAMES.ESG ? tournament_pitch_esg : tournament_pitch_smgx;

        return newOptions[currentStep];
    }, []);

    const optionsToShow = useMemo(() => {
        if (selectedOptions.length === 5) {
            setTimeout(() => {
                document.querySelector('.pitch-modal .btn-top')?.click();
            }, 250);

            return selectedOptions;
        }

        return options.sort((a, b) => a.title.localeCompare(b.title));
    }, [selectedOptions]);

    const handleToggle = (option) => {
        if (isLoading)
            return;

        let newOptions = [...selectedOptions];

        if (selectedOptionsIds[option.id])
            newOptions = newOptions.filter(c => c.id !== option.id);
        else
            newOptions.push(option)

        setSelectedOptions(newOptions);
    }

    const handleReveal = async () => {
        setIsLoading(true);

        const bonus = Math.max(selectedOptions.reduce((prev, curr) => prev + curr.points, 0), 0);

        const params = {
            step: currentStep,
            data: {
                selectedOptions: selectedOptions.map(x => x.id),
                bonus
            }
        }


        const revealEffectPromise = AudioService.play(SOUND_EFFECTS.QUESTIONS.DRUM_ROLL, 250);
        const updateAnswerPromise = TournamentService.updatePitchData(params);
        await Promise.all([revealEffectPromise, updateAnswerPromise]);


        const resultAudioToPlay = bonus > 0
            ? SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED
            : SOUND_EFFECTS.QUESTIONS.WRONG_ANSWER;

        setIsLoading(false);

        AudioService.play(resultAudioToPlay);
    }

    const handleContinueGame = () => {
        setIsLoading(true);
        TournamentService.advanceGame().finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (!!pitchData && !isLoading) {
            setRevealOutcome(true);
            setSelectedOptions(options.filter(x => pitchData.selectedOptions.includes(x.id)));
        }
    }, [isLoading, pitchData]);


    const handleScrollToTop = () => {
        document.querySelector('.pitch-modal')?.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <Modal show={true} size={'xl'} onHide={onClose} className={"pitch-modal"} scrollable={!isMobile}>

            <Modal.Header closeButton className={`bg-dark text-white`}>
                <div className="modal-header-content w-100 d-flex flex-column flex-md-row align-items-md-center">
                    <img alt="Mundi Game Experiences logo" src={logo} className="mr-4 d-none d-md-inline-block"/>

                    <div>
                        <Modal.Title>{i18nUtil.stepName(currentStep)}</Modal.Title>

                        <p className="mb-0 mt-1 small">
                            {tfg(`pitch_info.${currentStep}.message_summary`)}
                        </p>
                    </div>

                </div>
            </Modal.Header>

            <Modal.Body>
                {!revealOutcome && (
                    <div
                        className="w-100 p-2 text-white bg-primary rounded d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">

                        <p className="small text-center mr-3 mb-2 mb-md-0">
                            Selecionados: {selectedOptions.length}/5
                        </p>

                        <div style={{gap: 5}}
                             className="selected-items-container d-flex flex-wrap justify-content-center align-content-center">

                            {selectedOptions.map((option) => (<SelectedItemBadge
                                key={option.id}
                                data={option}
                                onRemove={handleToggle}
                            />))}


                            {selectedOptions.length === 0 && (
                                <span className="text-muted">nenhum</span>
                            )}
                        </div>
                    </div>
                )}

                {!revealOutcome && selectedOptions.length === 5 && (
                    <div className="w-100 text-center mb-5 pt-2 animate__animated animate__fadeInDown animate__faster">
                        <ButtonSpinner className="btn btn-success rounded" onClick={handleReveal}
                                       showAnimation={isLoading}>
                            Confirmar escolhas e continuar
                        </ButtonSpinner>
                    </div>
                )}

                {revealOutcome && (<>
                    <div className="w-100 text-center mb-5 pt-3 animate__animated animate__fadeInDown animate__faster">
                        <h5>Com base nas suas respostas, você recebeu um bônus de</h5>
                        <h3 className="text-danger font-weight-bold">{pitchData.bonus}%</h3>
                        <h5>de {GameUtil.isCurrentGameEsg() ? 'impacto' : 'aceleração'}</h5>


                        <ButtonSpinner className="btn btn-primary btn-sm mt-3"
                                       showAnimation={isLoading}
                                       onClick={handleContinueGame}>

                            Continuar jogo
                        </ButtonSpinner>

                        <hr/>
                    </div>

                    <h5 className="mb-3 text-center">Suas escolhas</h5>
                </>)}


                <div className="d-flex justify-content-center align-items-stretch flex-wrap">
                    {optionsToShow.map((option) => (<Item
                        key={option.id}
                        isSelected={!!selectedOptionsIds[option.id]}
                        revealOutcome={revealOutcome}
                        onToggle={handleToggle}
                        data={option}
                    />))}
                </div>
            </Modal.Body>

            {isMobile && (<MobileModalFooter onClose={onClose} scrollContainer={'.pitch-modal'}/>)}
        </Modal>
    );
}