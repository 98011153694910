import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import StartupCard from "components/cards/StartupCard";

import "./StartupProfile.scss";
import FounderCard from "components/cards/FounderCard";
import ValuePropositionCard, {VALUE_PROPOSITION_LAYOUT} from "components/cards/ValuePropositionCard";
import useTranslationForGame from "hooks/useTranslationForGame";

export default function StartupProfile({onClose}) {
    const {t} = useTranslation('pages/common/board/startup', {keyPrefix: 'components.profile_modal'});
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const startup = useSelector(state => state.teams[showBoardForTeam].startup);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);
    const foundersIcons = useSelector(state => state.teams[showBoardForTeam].foundersIcons);
    const valuePropositions = useSelector(state => state.teams[showBoardForTeam].valuePropositions);

    return (
        <div className="startup-profile d-flex flex-column justify-content-center align-items-center container">
            {!startup && !founders[0] && !founders[1] && !valuePropositions[0] && !valuePropositions[1] && (
                <p className="text-center text-muted">{t("texts.empty")}</p>
            )}

            {startup && (
                <StartupCard startup={startup} selectable={false}/>
            )}

            <hr className="w-100" hidden={!founders[0] && !founders[1]}/>

            {founders[0] && (
                <FounderCard founder={founders[0]} icon={foundersIcons[0]} selectable={false}/>
            )}

            {founders[1] && (
                <FounderCard founder={founders[1]} icon={foundersIcons[1]} selectable={false}/>
            )}

            <hr className="w-100" hidden={!valuePropositions[0] && !valuePropositions[1]}/>

            {valuePropositions[0] && (
                <ValuePropositionCard valueProposition={valuePropositions[0]}
                                      selectable={false}
                                      layout={VALUE_PROPOSITION_LAYOUT.EXPANDABLE}/>
            )}

            {valuePropositions[1] && (
                <ValuePropositionCard valueProposition={valuePropositions[1]}
                                      selectable={false}
                                      layout={VALUE_PROPOSITION_LAYOUT.EXPANDABLE}/>
            )}
        </div>
    );
}
