import React, {useEffect, useMemo, useState} from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";

import icon from "assets/img/board/ic_pitch_2.svg";

import "./Item.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Item({onToggle, isSelected, revealOutcome, data}) {
    const {title, description} = data;

    const [showConfirm, setShowConfirm] = useState(false);

    const toggleConfirm = (toggle) => {
        if (revealOutcome)
            return;

        setShowConfirm(toggle);
    }

    const handleConfirm = (event) => {
        event.stopPropagation();

        onToggle(data);
        setShowConfirm(false);
    }

    const cardClass = useMemo(() => {
        let res = '';

        if (showConfirm)
            res += 'show-confirm';

        if (!revealOutcome)
            res += ' selectable';

        return res;
    }, [showConfirm, revealOutcome]);

    useEffect(() => {
        if (revealOutcome)
            setShowConfirm(false);
    }, []);

    return (
        <GameCardLayout>
            <div
                className={`box bg-white pitch-option animate__animated animate__faster animate__fadeIn h-100 d-flex flex-column ${cardClass}`}>

                <div className="box-skill-head d-flex align-items-center">
                    <img className="skill-face position-absolute" src={icon} alt="skill icon"/>
                    <div>{title}</div>
                </div>

                <div className="box-content skill-content flex-fill" onClick={() => toggleConfirm(true)}
                     onMouseLeave={() => toggleConfirm(false)}>

                    <div className="position-relative overflow-hidden h-100">
                        <p className="mb-0 mt-1 card-description">{description}</p>

                        <div
                            className={"confirm-overlay position-absolute top bottom left right justify-content-center align-items-center text-center"}>

                            <button type="submit" className="btn btn-primary" onClick={handleConfirm}>
                                {isSelected ? 'Remover' : 'Selecionar'}
                            </button>
                        </div>

                        <div
                            className={"loading-overlay position-absolute top bottom left right  justify-content-center align-items-center text-center"}>

                            <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-spin fa-3x"/>
                        </div>
                    </div>
                </div>

                <div className="box-footer text-light d-flex justify-content-center px-2 py-1 align-items-center"
                     style={{opacity: isSelected || revealOutcome ? 1 : 0}}>

                    {revealOutcome && data.points > 0 && (
                        <b className="text-success">+{data.points}%</b>
                    )}

                    {revealOutcome && data.points === 0 && (
                        <b className="text-muted">+{data.points}%</b>
                    )}

                    {revealOutcome && data.points < 0 && (
                        <b className="text-danger">{data.points}%</b>
                    )}


                    {!revealOutcome && (
                        "Selecionado"
                    )}
                </div>

            </div>
        </GameCardLayout>
    );
}