import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

import OverlayUtil from "utils/OverlayUtil";
import TeamService from "services/player/TeamService";
import ToastUtil from "utils/ToastUtil";
import ModalForm from "../ModalForm";
import store from "redux/store";
import LoggerService from "services/common/LoggerService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./ValuePropositionsModal.scss";
import {Trans, useTranslation} from "react-i18next";
import ValuePropositionCard, {VALUE_PROPOSITION_LAYOUT} from "components/cards/ValuePropositionCard";
import useTranslationForGame from "hooks/useTranslationForGame";
import DemoSessionService from "services/common/DemoSessionService";
import DemoPlaceholder from "../DemoPlaceholder";
import ValuePropositionConfirmModal from "./ValuePropositionConfirmModal";


export default function ValuePropositionsModal({onClose, number, selected}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.value_propositions_modal'});
    const tfg = useTranslationForGame(t);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const isMobile = useSelector(state => state.session.isMobile);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const valuePropositions = useSelector(state => state.cards.valuePropositions);
    const selectedValuePropositions = useSelector(state => state.teams[showBoardForTeam].valuePropositions);

    const [selectedCard, setSelectedCard] = useState(null);

    const valuePropositionsToDisplay = useMemo(() => {
        const selectedVps = [];

        if (selectedValuePropositions[0])
            selectedVps.push(selectedValuePropositions[0]);
        if (selectedValuePropositions[1])
            selectedVps.push(selectedValuePropositions[1]);

        let toDisplay = valuePropositions;

        if (isDemonstration) {
            // show only half of the startups
            toDisplay = valuePropositions.slice(0, Math.round(valuePropositions.length / 2));
        }

        return toDisplay.filter(vp => !selectedVps.includes(vp));
    }, [valuePropositions, selectedValuePropositions]);

    const handleSelectValueProposition = () => {
        if (!selectedCard)
            return;

        if (isDemonstration) {
            DemoSessionService.setValueProposition(number, selectedCard);
            ToastUtil.toastSuccess(t('toasts.updated_success.title'), tfg('toasts.updated_success.message'));
            onClose();

            return;
        }

        OverlayUtil.toggleLoadingOverlay(true, tfg('overlays.updating'));

        const params = {valueProposition: {id: selectedCard.id, number}};
        if (isGameMaster) params.team = store.getState().session.showBoardForTeam;

        TeamService.updateValueProposition(params).then(value => {
            if (value) {
                ToastUtil.toastSuccess(t('toasts.updated_success.title'), tfg('toasts.updated_success.message'));
                onClose();
            } else {
                ToastUtil.toastDanger(t('toasts.updated_error.title'), tfg('toasts.updated_error.message'));
            }
        }).catch(reason => {
            console.error('Could not update value proposition');
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.updated_error.title'), tfg('toasts.updated_error.message'));

            LoggerService.error(reason, {
                action: 'update team value proposition - team board',
                params: params
            });
        }).finally(() => {
            OverlayUtil.toggleLoadingOverlay(false);
        });
    }

    const handleShowConfirmForCard = (card) => {
        setSelectedCard(card);
    }

    useEffect(() => {
        if (!selectedCard)
            return;

        if (selectedValuePropositions[0] === selectedCard || selectedValuePropositions[1] === selectedCard)
            setSelectedCard(null);
    }, [selectedValuePropositions]);

    return (<>
        <ModalForm showModal={true} onClose={onClose} title={tfg('texts.title')} subtitle={t("texts.subtitle")}
                   modalClass={`value-proposition-conception-modal ${selectedCard && isMobile ? 'hidden' : ''}`}>
            <div className="row">
                <div className={`${isMobile ? 'col-12' : 'col-8'} value-propositions-list`}>
                    <div className="d-flex justify-content-center align-items-stretch flex-wrap">
                        {valuePropositionsToDisplay.map(vp => {
                            return (
                                <div key={vp.id} className="text-left mb-2">
                                    <ValuePropositionCard valueProposition={vp}
                                                          selectable={true}
                                                          layout={VALUE_PROPOSITION_LAYOUT.TITLE_ONLY}
                                                          previousSelected={selected}
                                                          onSelect={handleShowConfirmForCard}
                                                          highlightTitle={selectedCard && selectedCard.id === vp.id}/>
                                </div>
                            )
                        })}

                        {isDemonstration && (
                            <div className="text-left mb-2">
                                <DemoPlaceholder clasz={"vp-demo-placeholder"}/>
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-4 position-relative selected-value-proposition" hidden={isMobile}>
                    <div className="w-100 text-center position-sticky top">
                        {!selectedCard && (
                            <div>
                                <h5 className="text-dark text-uppercase pt-5">{t('texts.select_card')}</h5>
                                <FontAwesomeIcon icon={['far', 'hand-point-left']} className={'fa-2x mt-2'}/>
                            </div>
                        )}

                        {selectedCard && (<>
                            <div key={selectedCard.id}
                                 className="animate__animated animate__faster animate__fadeInDown">
                                <ValuePropositionCard valueProposition={selectedCard}
                                                      layout={VALUE_PROPOSITION_LAYOUT.FULL}
                                                      selectable={false}/>
                            </div>

                            <div className="w-100 small px-3 mb-2 text-center">
                                <Trans t={t} i18nKey={'texts.change'} components={{1: <b/>}}
                                       values={{from: selected?.title || t('texts.none'), to: selectedCard.title}}/>
                            </div>

                            <button
                                className={"btn btn-primary vp-btn " + (!selectedValuePropositions || !selectedValuePropositions[number] ? 'pulsating' : '')}
                                onClick={handleSelectValueProposition}>
                                {t('buttons.select')}
                            </button>
                        </>)}
                    </div>
                </div>
            </div>
        </ModalForm>

        {isMobile && selectedCard && (
            <ValuePropositionConfirmModal onClose={() => setSelectedCard(null)}
                                          onSelect={handleSelectValueProposition}
                                          selectedCard={selectedCard}
                                          currentCard={selected}/>)}
    </>);
}