import React from 'react';
import {Modal} from "react-bootstrap";
import SkillCard from "components/cards/SkillCard";
import ServiceCard from "components/cards/ServiceCard";

import "./DetailsModal.scss";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

export default function DetailModal({type, card, onClose}) {
    const {t} = useTranslation('common');
    const t2 = useTranslation('components/cards', {keyPrefix: 'skill_card'}).t;
    const tfg = useTranslationForGame(t2);

    return (<Modal id="mobile-hired-card-details-modal" show={true} onHide={onClose} className="fade"
                   scrollable={true} size="xl">

        <Modal.Header closeButton className="">
            <Modal.Title>
                Detalhes da Contratação
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-start flex-column align-items-center">
            {card.isFounder && (<div className="mb-3 alert alert-primary font-weight-bold w-100 text-center">
                {tfg('texts.founder_info_full')}
            </div>)}


            {type === 1 &&
                <SkillCard skill={card.card} icon={card.icon} gender={card.gender} hirable={false}/>
            }

            {type === 2 &&
                <ServiceCard service={card.card}  hirable={false}/>
            }
        </Modal.Body>

        <Modal.Footer className="position-sticky bottom">
            <button className="btn btn-secondary" onClick={onClose}>{t('modals.buttons.close')}</button>
        </Modal.Footer>
    </Modal>);
}