import {v4 as uuidv4} from 'uuid';

export const MS_PER_DAY = 86400000;
export const MS_PER_HOUR = 3600000;
export const MS_PER_MINUTE = 60000;
export const MS_PER_SECOND = 1000;

const Utils = {
    isset(data) {
        return data !== undefined && data !== null && data !== '';
    },

    isFunction(param) {
        return (this.isset(param) && typeof param == "function");
    },

    uuid() {
        return uuidv4();
    },

    wait(milliseconds = 250) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    },

    randomFromArray(arr) {
        return arr[~~(Math.random() * arr.length) | 0];
    },

    /* Returns a random integer between min (inclusive) and max (inclusive).*/
    randomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    /* Return an object with the diff between date1 and date2. Ex: {days: 1, time: '00:00:00'} */
    diffBetweenDates(date1, date2) {
        const d1 = Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate(), date1.getUTCHours(), date1.getUTCMinutes(), date1.getUTCSeconds());
        const d2 = Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate(), date2.getUTCHours(), date2.getUTCMinutes(), date2.getUTCSeconds());

        const days = (d1 - d2) % MS_PER_DAY;
        const hours = days % MS_PER_HOUR;
        const minutes = hours % MS_PER_MINUTE;

        return {
            date1IsBefore: (d1 - d2) < 0,
            days: Math.floor((d1 - d2) / MS_PER_DAY),
            hours: Math.floor(days / MS_PER_HOUR),
            minutes: Math.floor(hours / MS_PER_MINUTE),
            seconds: Math.floor(minutes / MS_PER_SECOND),
        }
    },

    utcDateToLocalDate(date) {
        date = new Date(date.replace(/-/g, '/'));
        date = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(date - (new Date()).getTimezoneOffset() * 60 * 1000);
    },

    utcDateTimeToLocalDateStringWithoutSeconds(dateTime, showTimezone = false) {
        const parsedDate = this.utcDateToLocalDate(dateTime);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return `${parsedDate.toLocaleDateString()} - ${parsedDate.toLocaleTimeString().slice(0, -3)} `
            + (showTimezone ? `(${timezone})` : '');
    },

    waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    },

    numberToLetter(number) {
        return (number + 9).toString(36);
    },

    /**
     * Performs a deep merge of objects and returns new object. Does not modify
     * objects (immutable) and merges arrays via concatenation.
     *
     * @param {...object} objects - Objects to merge
     * @returns {object} New object with merged key/values
     */
    mergeObjectsDeep(...objects) {
        const isObject = obj => obj && typeof obj === 'object';

        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key];
                const oVal = obj[key];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal);
                } else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = Utils.mergeObjectsDeep(pVal, oVal);
                } else {
                    prev[key] = oVal;
                }
            });

            return prev;
        }, {});
    },

    percent(total, value) {
        if (total === 0 || value === 0)
            return 0;

        return value * 100 / total;
    },

    objToCamelCase(obj) {
        const newObj = {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = key.toLowerCase()
                    .replace(/(_\w)/g, k => k[1].toUpperCase());

                newObj[newKey] = obj[key];
            }
        }

        return newObj;
    },

    objFilterKeys(obj, filterKeys = []) {
        return filterKeys.reduce((newObj, key) => newObj[key] = obj[key], {});
    }
}

export default Utils;