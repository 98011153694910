import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Naviation({onPrevious, onNext, onClose, current, total}) {
    const infoClass = current !== 0
        ? 'animate__animated animate__fadeOutUp animate__faster'
        : 'animate__animated animate__fadeInDown animate__faster';

    return (<div className="animate__animated animate__fadeIn">
        <div className="d-flex justify-content-center mb-1 text-light">
            <button type="button" onClick={onPrevious} disabled={current === 0}
                    className={"btn btn-sm btn-outline-light border-0 px-2 py-0 mr-1 "}>
                <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-2x"/>
            </button>

            <h5 className="mb-0 mx-4 font-weight-bold">
                {current + 1}
                <span className="small">/</span>
                {total}
            </h5>

            <button type="button" onClick={onNext} disabled={current === total - 1}
                    className={"btn btn-sm btn-outline-light border-0 px-2 py-0 mr-1 "}>
                <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-2x"/>
            </button>
        </div>

        <button className="btn btn-outline-secondary border-0 mt-3" onClick={onClose}>
            Pular tutorial
        </button>

        <h6 className={`small mt-2 ${infoClass}`} style={{color: "lightgray"}}>
            Use as setas para navegar pelo tutorial
        </h6>
    </div>);
}