import React from "react";
import StepTitle from "../components/StepTitle";

export default function SmgxStep2() {

    return (<div>
        <StepTitle>
            Seu objetivo é acelerar uma startup e garantir um faturamento crescente
        </StepTitle>

        <h5>
            Para chegar lá você terá alguns desafios pela frente
        </h5>
    </div>)
}