import React, {useEffect} from "react";
import './Board.scss';

import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {GAME_MASTER_ROUTES, PLAYER_ROUTES} from "navigation/ROUTES";
import AccountingService from "services/common/AccountingService";
import AnimatedStepChange from "../../tournament/AnimatedStepChange/AnimatedStepChange";
import Mobile from "./partials/Mobile";
import Desktop from "./partials/Desktop";
import {useTranslation} from "react-i18next";
import PopUpNotificationsWatcher from "./components/PopUpNotificationsWatcher";


export default function Board() {
    // preload translation namespaces to avoid rendering components before loading the localization files
    useTranslation([
        'pages/common/board/challenges',
        'pages/common/board/game_events',
        'components/cards'
    ], {useSuspense: true});

    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const isMobile = useSelector(state => state.session.isMobile);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const showStepChangeTransition = useSelector(state => state.tournament.state.showStepChangeTransition);
    const isTournament = useSelector(state => state.game.isTournament);

    const navigate = useNavigate();

    useEffect(() => {
        if (!showBoardForTeam) {
            if (isGameMaster) navigate(GAME_MASTER_ROUTES.CONTROL_PANEL);
            else navigate(PLAYER_ROUTES.LOBBY);
        } else {
            AccountingService.calculateForTeam(showBoardForTeam);
        }
    }, []);


    return (
        <>
            {isMobile ? <Mobile/> : <Desktop/>}
            {showStepChangeTransition && <AnimatedStepChange/>}

            <PopUpNotificationsWatcher/>
        </>
    );
}

