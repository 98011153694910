import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SwalModalUtil from "utils/SwalModalUtil";
import OverlayUtil from "utils/OverlayUtil";
import AuthService from "services/player/AuthService";
import {useTranslation} from "react-i18next";

export default function ButtonLeave({className}) {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.header'});

    const handleLeaveGame = () => {
        SwalModalUtil.confirmModal(null, t('confirms.leave_session')).then(async result => {
            if (result) {
                OverlayUtil.toggleLoadingOverlay(true);

                const gameLink = await AuthService.leave();
                window.location.replace(gameLink);
            }
        });
    }

    return (<button className={className} onClick={handleLeaveGame}>
        <FontAwesomeIcon icon={['fas', 'sign-out-alt']} className="mr-2"/>
        {t('buttons.leave')}
    </button>)
}