import React, {memo, useMemo} from "react";

import "./PhaseDetails.scss";
import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import StepDetailsItem from "./StepDetailsItem";
import {useTranslation} from "react-i18next";
import ColumnView, {COLUMN_VIEW_STYLES} from "../components/ColumnView";

const PhaseDetails = ({phase, team}) => {
    const {t} = useTranslation(['pages/common/board/accounting', 'common'], {keyPrefix: "components.accounting_details_modal.components.phase_details"});
    const t2 = useTranslation('pages/common/board/accounting', {keyPrefix: 'texts'}).t;

    const accountingDetails = useSelector(state => {
        if (state.teams[team].accounting.phases && state.teams[team].accounting.phases[phase]) {
            return state.teams[team].accounting.phases[phase];
        }
        return null;
    });

    const phaseTotalsData = useMemo(() => {
        const leftItems = [
            {
                title: t2('capital_received'),
                value: i18nUtil.formatMoney(accountingDetails?.totals?.capital || 0)
            },
            {
                title: t2('revenue'),
                value: i18nUtil.formatMoney(accountingDetails?.totals?.revenue || 0)
            },
            {
                title: t2('balance'),
                value: i18nUtil.formatMoney(accountingDetails?.totals?.balance || 0)
            },
        ];

        const rightItems = [
            {
                title: t2('expenses'),
                value: i18nUtil.formatMoney(accountingDetails?.totals?.expenses || 0)
            },
            {
                title: t2('acceleration'),
                value: i18nUtil.formatPercent(accountingDetails?.totals?.acceleration || 0)
            },
            {
                title: t2('market_investment_rate'),
                value: i18nUtil.formatPercent(accountingDetails?.totals?.marketInvestmentRate || 0)
            },
        ];

        return {leftItems, rightItems};
    }, [accountingDetails]);


    return (
        <>
            <div className="table-responsive mb-0">
                <table className="table smgx-accounting-steps-details-table table-borderless table-striped" >
                    <thead>
                    <tr>
                        <th>{t('tables.details.header.step')}</th>
                        <th>{t('tables.details.header.cost_month')}</th>
                        <th>{t('tables.details.header.months')}</th>
                        <th>{t('tables.details.header.total_cost_month')}</th>
                        <th>{t('tables.details.header.services_skills')}</th>
                        <th>{t('tables.details.header.revenue')}</th>
                        <th>{t('tables.details.header.balance')}</th>
                        <th className="text-center">{t('tables.details.header.signature')}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {Object.entries(accountingDetails?.steps || {}).map(([step, stepAccounting]) => {
                        return (<StepDetailsItem step={parseInt(step)} stepAccounting={stepAccounting} team={team}
                                                 key={step}/>)
                    })}

                    <tr>
                        <td>{t('tables.details.footer.total')}</td>
                        <td><span className="text-muted small">-</span></td>
                        <td>{accountingDetails?.totals?.monthsAdvanced || 0}</td>
                        <td>{i18nUtil.formatMoney(accountingDetails?.totals?.totalCost || 0)}</td>
                        <td>{i18nUtil.formatMoney(accountingDetails?.totals?.servicesAndSkillsInvestment || 0)}</td>
                        <td>{i18nUtil.formatMoney(accountingDetails?.totals?.revenue || 0)}</td>
                        <td><span className="text-muted small">-</span></td>
                        <td><span className="text-muted small">-</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <h5 className="text-uppercase text-warning mt-2 mb-2">
                {t('texts.phase_totals', {phase})}
            </h5>

            <div className="phase-summary text-white">
                <ColumnView style={COLUMN_VIEW_STYLES.INFO_WHITE}
                            leftItems={phaseTotalsData.leftItems}
                            rightItems={phaseTotalsData.rightItems}/>
            </div>
        </>
    );
}

export default memo(PhaseDetails);