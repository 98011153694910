import CardUtil from "utils/CardUtil";
import store from "redux/store";
import Utils from "utils/Utils";
import {UNPREDICTABILITY_IMPACT} from "config/CONSTANTS";
import SkillCardModel from "../../../models/SkillCardModel";
import {SKILL_GENDER_GROUP} from "../../../config/SKILL_ICONS";
import ACCOUNTING_COMMON_CONFIG from "../../../config/ACCOUNTING_COMMON_CONFIG";

const ApiDataParserService = {
    parseGameData(data) {
        const res = {gameInfo: {}};
        const preSelectedStartups = data.preSelS || false;
        let parsedPreSelectedStartups = false;
        let parsedPreSelectedFounderIcons = {};

        if (preSelectedStartups !== false) {
            const startups = store.getState().cards.startups;
            parsedPreSelectedStartups = {};

            Object.entries(preSelectedStartups).forEach(([key, value]) => {
                parsedPreSelectedStartups[parseInt(key)] = CardUtil.getCardById(startups, value);
            });

            if (data.preSelFnI) {
                parsedPreSelectedFounderIcons = {};

                Object.entries(data.preSelFnI).forEach(([team, founderIcons]) => {
                    parsedPreSelectedFounderIcons[team] = {};

                    for (let i = 0; i < 2; i++) {
                        parsedPreSelectedFounderIcons[team][i] = founderIcons[i]
                            ? SkillCardModel.getFullPathForIcon(founderIcons[i])
                            : SkillCardModel.randomIcon();
                    }
                });
            } else {
                for (let i = 1; i < 5; i++) {
                    parsedPreSelectedFounderIcons[i] = {};

                    for (let j = 0; j < 2; j++) {
                        parsedPreSelectedFounderIcons[i][j] = SkillCardModel.randomIcon();
                    }
                }
            }
        }


        if (Utils.isset(data.step)) res.step = data.step;
        if (Utils.isset(data.tCount)) res.teamCount = data.tCount;
        if (Utils.isset(data.isPaused)) res.isPaused = data.isPaused;
        if (Utils.isset(data.closedAt)) res.closedAt = data.closedAt;
        if (Utils.isset(data.canJoinT)) res.canJoinTeam = data.canJoinT;
        if (Utils.isset(data.pAcc)) res.playerAccessControl = data.pAcc;
        if (Utils.isset(data.allowCertificateRequest)) res.allowCertificateRequest = data.allowCertificateRequest;
        if (Utils.isset(data.canHire)) res.canHireCards = data.canHire;
        if (Utils.isset(data.pocket)) res.isPocket = data.pocket;
        if (Utils.isset(data.isOwn)) res.isOwnSession = data.isOwn;
        if (Utils.isset(data.maxP)) res.maxPlayerCount = data.maxP;
        if (Utils.isset(data.visual)) res.visualPack = data.visual;

        if (Utils.isset(data.isTemp)) res.isTemporary = data.isTemp;
        if (Utils.isset(data.isTournament)) res.isTournament = data.isTournament;

        if (Utils.isset(data.usePreSelS)) res.usePreSelectedStartups = data.usePreSelS;
        if (res.usePreSelectedStartups && Utils.isset(data.preSelS)) {
            const startups = store.getState().cards.startups;
            parsedPreSelectedStartups = {};

            Object.entries(preSelectedStartups).forEach(([key, value]) => {
                parsedPreSelectedStartups[parseInt(key)] = CardUtil.getCardById(startups, value);
            });

            res.preSelectedStartups = parsedPreSelectedStartups;
            res.preSelectedFoundersIcons = parsedPreSelectedFounderIcons;
        }

        if (data.pBonus) {
            res.pitchBonus = [];

            Object.entries(data.pBonus).forEach(([step, team]) => {
                res.pitchBonus.push({step: parseInt(step), team, amount: ACCOUNTING_COMMON_CONFIG.GAME_PITCH_BONUS});
            });
        }

        // game info
        const game = res.gameInfo;
        const origin = window?.location?.origin || '';
        if (Utils.isset(data.id)) game.id = data.id;
        if (Utils.isset(data.v)) game.version = 'v' + data.v;
        if (Utils.isset(data.gm)) game.gameMaster = data.gm;
        if (Utils.isset(data.owner)) game.owner = data.owner;
        if (Utils.isset(data.startAt)) game.startAt = data.startAt;
        if (Utils.isset(data.name)) game.name = data.name;
        if (Utils.isset(data.callUrl)) game.callUrl = data.callUrl;
        if (Utils.isset(data.gameUrl)) game.gameUrl = origin + data.gameUrl;
        if (Utils.isset(data.gmUrl)) game.gameMasterUrl = origin + data.gmUrl;
        if (data.openUntil) game.openUntil = data.openUntil;
        if (data.game) game.game = data.game;


        if (Utils.isset(data.cli)) {
            game.client = {};
            game.client.name = data.cli;

            if (Utils.isset(data.cliName)) game.client.contactName = data.cliName;
            if (Utils.isset(data.cliEmail)) game.client.contactEmail = data.cliEmail;
        }

        if (Utils.isset(data.lang)) {
            game.language = data.lang;
            res.isNationalSession = data.lang.startsWith('pt');
        }


        // teams data
        if (Utils.isset(data.t1)) res.team1 = this.parseTeamData(data.t1);
        if (Utils.isset(data.t2)) res.team2 = this.parseTeamData(data.t2);
        if (Utils.isset(data.t3)) res.team3 = this.parseTeamData(data.t3);
        if (Utils.isset(data.t4)) res.team4 = this.parseTeamData(data.t4);


        // ranking
        if (Utils.isset(data.rank)) {
            res.ranking = {};

            for (let i = 1; i < 5; i++) {
                if (data.rank[i]) {
                    res.ranking[i] = {};

                    if (Utils.isset(data.rank[i].rank)) res.ranking[i].ranking = data.rank[i].rank;
                    if (Utils.isset(data.rank[i].acc)) res.ranking[i].acceleration = data.rank[i].acc;
                    if (Utils.isset(data.rank[i].ma)) res.ranking[i].monthsAdvanced = data.rank[i].ma;
                    if (Utils.isset(data.rank[i].rev)) res.ranking[i].revenue = data.rank[i].rev;
                    if (Utils.isset(data.rank[i].show)) res.ranking[i].show = data.rank[i].show;
                    if (Utils.isset(data.rank[i].globalRank)) res.ranking[i].globalRank = data.rank[i].globalRank;
                }
            }
        }

        if (data.needSetup === true)
            res.tournamentInfo = {needSetup: true};

        return res;
    },

    parseTeamData(data) {
        const res = {};

        if (Utils.isset(data.vp)) {
            const valuePropositions = store.getState().cards.valuePropositions;
            res.valuePropositions = {};
            if (data.vp['0']) res.valuePropositions[0] = CardUtil.getCardById(valuePropositions, data.vp['0']);
            if (data.vp['1']) res.valuePropositions[1] = CardUtil.getCardById(valuePropositions, data.vp['1']);
        }

        if (Utils.isset(data.st)) {
            const startups = store.getState().cards.startups;
            res.startup = CardUtil.getCardById(startups, data.st);
        }

        if (Utils.isset(data.fn)) {
            const founders = store.getState().cards.skills;

            res.founders = {};
            res.foundersIcons = {};


            // set founder icons
            for (let i = 0; i < 2; i++) {
                if (data.fn[i]) {
                    res.founders[i] = CardUtil.getCardById(founders, data.fn[i]);
                    res.foundersIcons[i] = data?.fnI?.[i]
                        ? SkillCardModel.getFullPathForIcon(data.fnI[i])
                        : SkillCardModel.randomIcon();
                }
            }
        }


        if (Utils.isset(data.sk)) {
            const skills = store.getState().cards.skills;
            res.skills = {};

            Object.entries(data.sk).forEach(([key, value]) => {
                const card = CardUtil.getCardById(skills, value.id);
                if (!card) return;

                const icon = value.i
                    ? SkillCardModel.getFullPathForIcon(value.i)
                    : SkillCardModel.randomIcon();

                let gender = null;

                switch (value.g) {
                    case 'm':
                        gender = SKILL_GENDER_GROUP.MALE;
                        break;
                    case 'f':
                        gender = SKILL_GENDER_GROUP.FEMALE;
                        break;
                    case 'o':
                        gender = SKILL_GENDER_GROUP.OTHER;
                        break;
                    default:
                        gender = null;
                }


                if (!res.skills[value.step])
                    res.skills[value.step] = [];

                res.skills[value.step].push({
                    id: key,
                    step: value.step,
                    hiredAt: value.at,
                    iconNumber: value.i,
                    card,
                    icon,
                    gender
                });
            });

            Object.keys(res.skills).forEach(key => {
                res.skills[key] = res.skills[key].sort((a, b) => a.hiredAt - b.hiredAt);
            });
        }

        if (Utils.isset(data.se)) {
            const services = store.getState().cards.services;
            res.services = {};

            Object.entries(data.se).forEach(([key, value]) => {
                const card = CardUtil.getCardById(services, value.id);
                if (!card) return;

                if (!res.services[value.step]) res.services[value.step] = [];
                res.services[value.step].push({id: key, step: value.step, hiredAt: value.at, card});
            });

            Object.keys(res.services).forEach(key => {
                res.services[key] = res.services[key].sort((a, b) => a.hiredAt - b.hiredAt);
            });
        }

        if (Utils.isset(data.q)) {
            const questions = store.getState().cards.questions;
            const questionsPerStep = {};

            Object.entries(data.q).forEach(([step, value]) => {
                const assignedQuestions = {step};

                if (value.id) assignedQuestions.card = CardUtil.getCardById(questions, value.id);
                if (value.showRes) assignedQuestions.showResult = value.showRes;
                if (value.answ) assignedQuestions.answer = value.answ;
                if (value.revealedAt) assignedQuestions.revealedAt = value.revealedAt;
                if (Utils.isset(value.isCorr)) assignedQuestions.isCorrect = value.isCorr;

                questionsPerStep[step] = assignedQuestions;
            });

            res.questions = questionsPerStep;
        }

        if (Utils.isset(data.un)) {
            const unpredictabilities = store.getState().cards.unpredictabilities;
            const unpredictabilitiesPerStep = {};

            Object.entries(data.un).forEach(([step, value]) => {
                const assignedUnpredictability = {step};

                if (value.id) assignedUnpredictability.card = CardUtil.getCardById(unpredictabilities, value.id);
                if (value.rev) assignedUnpredictability.reveal = value.rev;
                if (value.revOut) assignedUnpredictability.revealOutcome = value.revOut;
                if (value.revealedAt) assignedUnpredictability.revealedAt = value.revealedAt;

                if (value.imp) {
                    if (value.imp === 'n') assignedUnpredictability.impact = UNPREDICTABILITY_IMPACT.NEGATIVE;
                    else if (value.imp === 'p') assignedUnpredictability.impact = UNPREDICTABILITY_IMPACT.POSITIVE;
                    else if (value.imp === 'ne') assignedUnpredictability.impact = UNPREDICTABILITY_IMPACT.NEUTRAL;
                }

                unpredictabilitiesPerStep[step] = assignedUnpredictability;
            });

            res.unpredictabilities = unpredictabilitiesPerStep;
        }

        if (Utils.isset(data.sAcc)) {
            res.signedAccounting = {};

            Object.entries(data.sAcc).forEach(([key, value]) => {
                res.signedAccounting[parseInt(key)] = value;
            });
        }

        if (data.pitchData)
            res.pitchData = data.pitchData;

        return res;
    },

    parsePlayer(data) {
        const res = {};

        ['id', 'nickname', 'name', 'email'].forEach(key => {
            if (data[key] !== null && data[key] !== undefined)
                res[key] = data[key];
        });

        if (data.team !== undefined) res.team = data.team;
        if (Utils.isset(data.isConn)) res.isConnected = data.isConn;
        if (Utils.isset(data.certReq)) res.certificatesRequested = data.certReq;
        if (Utils.isset(data.npsCount)) res.npsCount = data.npsCount;

        if (data.localP) {
            res.localPlayers = Object.entries(data.localP).map(([id, data]) => {
                return ApiDataParserService.parsePlayer({id, ...data});
            }).sort((a, b) => a.nickname.localeCompare(b.nickname));
        }

        return res;
    },

    parseCertificateRequest(data) {
        return data;
    },

    parseEvent(data) {

    }
};


export default ApiDataParserService;
