import React, {useMemo, useState} from 'react';
import {useSelector} from "react-redux";

import "./HiredCards.scss";
import CardUtil from "utils/CardUtil";
import SkillItem from "../../components/SkillItem";
import ServiceItem from "../../components/ServiceItem";
import DetailModal from "./components/DetailsModal";
import {ACCELERATION_BONUSES} from "config/CONSTANTS";
import {useTranslation} from "react-i18next";
import diversityIcon from 'assets/img/board/skillsGenders/diversity.svg';
import SwalModalUtil from "utils/SwalModalUtil";
import SkillMarket from "../../components/Markets/SkillMarket";
import ServiceMarket from "../../components/Markets/ServiceMarket";
import iconBalance from "assets/img/board/ic_investment_accounting_box.svg";
import i18nUtil from "utils/i18nUtil";

const TABS = {
    NONE: 0,
    SKILLS: 1,
    SERVICES: 2
}

export default function HiredCards({}) {
    const {t} = useTranslation('pages/common/board/hired_cards');

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);
    const foundersIcons = useSelector(state => state.teams[showBoardForTeam].foundersIcons);
    const hiredSkills = useSelector(state => state.teams[showBoardForTeam].skills);
    const hiredServices = useSelector(state => state.teams[showBoardForTeam].services)
    const accelerationBonuses = useSelector(state => state.teams[showBoardForTeam].accelerationBonuses);
    const availableForMarket = useSelector(state => state.teams[showBoardForTeam].accounting?.totals?.availableForMarket);

    const [tabToShow, setTabToShow] = useState(TABS.SKILLS);
    const [showDetailFor, setShowDetailFor] = useState(false);
    const [showSkillsMarket, setShowSkillsMarket] = useState(false);
    const [showServicesMarket, setShowServicesMarket] = useState(false);

    const cardsToShow = useMemo(() => {
        const newCardsToShow = CardUtil.hiredCardsToArray(tabToShow === TABS.SKILLS ? hiredSkills : hiredServices);

        if (tabToShow === TABS.SKILLS && founders) {
            Object.entries(founders).forEach(([index, founder]) => {
                if (founder)
                    newCardsToShow.unshift({
                        id: founder.id,
                        card: founder,
                        icon: foundersIcons?.[index] ?? null,
                        isFounder: true,
                    });
            });
        }

        return newCardsToShow;
    }, [tabToShow, hiredSkills, hiredServices, founders, foundersIcons]);

    const totalDiversityBonus = useMemo(() => {
        return (accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY] ?? 0)
            + (accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY] ?? 0)
    }, [accelerationBonuses]);

    const hiredSkillsCount = useMemo(() => {
        const foundersCount = Object.keys(founders ?? {}).length;

        return CardUtil.hiredCardsToArray(hiredSkills).length + foundersCount;
    }, [hiredSkills, founders]);

    const hiredServicesCount = useMemo(() => {
        return CardUtil.hiredCardsToArray(hiredServices).length
    }, [hiredServices]);


    const handleOpenSkillMarket = () => {
        setShowSkillsMarket(true);
        setShowServicesMarket(false)
    }

    const handleOpenServiceMarket = () => {
        setShowSkillsMarket(false);
        setShowServicesMarket(true)
    }


    const handleShowBonusInfo = () => {
        const raceBonus = accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY] || '0';
        const genderBonus = accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY] || '0';

        const text = t('components.skills.texts.bonus', {genderBonus, raceBonus});
        const title = text.split('<br/>');

        SwalModalUtil.infoModalHtml(title.shift(), title.join('<br/>'))
    }

    return (<>
        <div className="hired-cards container text-white">
            <div className="d-flex justify-content-center align-items-center flex-column mb-5">
                <h6 className="mb-2">
                    {t('texts.available_for_market')}
                </h6>

                <div className="d-flex justify-content-between align-items-center">
                    <img alt="Balance icon" src={iconBalance} className="mr-2" width={30}/>
                    <h3 className="mb-0 text-warning">{i18nUtil.formatMoney(availableForMarket || 0)}</h3>
                </div>
            </div>


            <div className="d-flex justify-content-center mt-3" style={{gap: 10}}>
                <button id="btn-open-skill-market" className="btn btn-success w-50"
                        onClick={handleOpenSkillMarket}>

                    {t('components.skills.buttons.open_market_long')}
                </button>

                <button id="btn-open-service-market" className="btn btn-success w-50"
                        onClick={handleOpenServiceMarket}>

                    {t('components.services.buttons.open_market_long')}
                </button>
            </div>

            <hr className="border-light"/>

            <ul className="nav nav-tabs nav-justified mb-3" id="nav-tab" role="tablist">
                <li className="nav-item">
                    <button className={`${tabToShow === TABS.SKILLS ? 'active' : 'text-white'} nav-link w-100`}
                            onClick={() => setTabToShow(TABS.SKILLS)}>

                    <span className={`badge mr-1 ${tabToShow === TABS.SKILLS ? 'badge-dark' : 'badge-light'}`}>
                        {hiredSkillsCount}
                    </span>

                        {t('components.skills.texts.title')}
                    </button>
                </li>

                <li className="nav-item">
                    <button
                        className={`${tabToShow === TABS.SERVICES ? 'active' : 'text-white border-0'} nav-link w-100`}
                        onClick={() => setTabToShow(TABS.SERVICES)}>

                    <span className={`badge mr-1 ${tabToShow === TABS.SERVICES ? 'badge-dark' : 'badge-light'}`}>
                        {hiredServicesCount}
                    </span>

                        {t('components.services.texts.title')}
                    </button>
                </li>
            </ul>

            <div className="d-flex justify-content-between mb-3">
                <div>
                    <h5 className="mb-0">
                        {
                            tabToShow === TABS.SKILLS
                                ? t('texts.hired_skills')
                                : t('texts.hired_services')
                        }
                    </h5>
                </div>

                <h5 className="mb-0">{cardsToShow.length}</h5>
            </div>


            <div className="smgx-card p-0 m-0">
                {cardsToShow.length === 0 && (<h5 className="w-100 text-center">
                    {
                        tabToShow === TABS.SKILLS
                            ? t('components.skills.texts.no_hired')
                            : t('components.services.texts.no_hired')
                    }
                </h5>)}


                {tabToShow === TABS.SKILLS && totalDiversityBonus > 0 && (
                    <div className=" w-100 mt-n2">
                        <button className="btn btn-secondary btn-show-bonus rounded py-0 px-2 mb-3"
                                onClick={handleShowBonusInfo}>
                            <p className="mb-0 font-weight-bold pr-1 d-inline">
                                +{totalDiversityBonus}% {t('texts.bonus')}
                            </p>
                            <img src={diversityIcon} width="18"/>
                        </button>
                    </div>
                )}

                {tabToShow === TABS.SKILLS && cardsToShow.map(card =>
                    <SkillItem key={card.id} hiredSkill={card}
                               onClick={() => setShowDetailFor(card)}/>
                )}

                {tabToShow === TABS.SERVICES && cardsToShow.map(card =>
                    <ServiceItem key={card.id} hiredService={card}
                                 onClick={() => setShowDetailFor(card)}/>
                )}
            </div>
        </div>

        {showDetailFor && <DetailModal card={showDetailFor} type={tabToShow} onClose={() => setShowDetailFor(false)}/>}
        {showSkillsMarket && (<SkillMarket onClose={() => setShowSkillsMarket(false)}/>)}
        {showServicesMarket && (<ServiceMarket onClose={() => setShowServicesMarket(false)}/>)}
    </>);
}
