import React, {useMemo, useState} from 'react';
import {Tab, Tabs as Tbs} from "react-bootstrap";
import i18nUtil from "utils/i18nUtil";
import {ACCELERATION_BONUSES, GAME_CARD_TYPE, GAME_PHASES, GAME_STEPS} from "config/CONSTANTS";
import StepDetails from "./StepDetails";
import HiringsSummary from "./HiringsSummary";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SkillItem from "../../SkillItem";
import ServiceItem from "../../ServiceItem";

function mapAccelerationBonus(items, bonusType) {
    items = Array.isArray(items) ? items : Object.values(items).reduce((acc, val) => {
        acc.push(...val);
        return acc;
    }, []);

    // remove duplicated hirings that may occur for implementation bonus
    items = [...new Set(items)];


    const onClickMock = () => {
    };

    return items.map(hiredCard => {
        return hiredCard.card.__type === GAME_CARD_TYPE.SKILL
            ? (<SkillItem key={hiredCard.id + bonusType} hiredSkill={hiredCard} onClick={onClickMock}
                          showAccelerationInfo={false}/>)
            : (<ServiceItem key={hiredCard.id + bonusType} hiredService={hiredCard} onClick={onClickMock}
                            showAccelerationInfo={false}/>)
    });
}

export default function Tabs() {
    const {t} = useTranslation('pages/common/board/accounting');

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const hiredSkills = useSelector(state => state.teams[showBoardForTeam].skills);
    const hiredServices = useSelector(state => state.teams[showBoardForTeam].services);

    const accelerationBonuses = useSelector(state => state.teams[showBoardForTeam].accelerationBonuses);
    const bonusDetails = useSelector(state => state.teams[showBoardForTeam].accelerationBonusesDetails);
    const currentPhase = useSelector(state => state.game.currentPhase);
    const currentStep = useSelector(state => state.game.currentStep);

    const [tabToShow, setTabToShow] = useState('phase-1');

    const implementationBonusCards = useMemo(() => mapAccelerationBonus(bonusDetails[ACCELERATION_BONUSES.IMPLEMENTATION] ?? [], ACCELERATION_BONUSES.IMPLEMENTATION), [bonusDetails]);
    const monitoringBonusCards = useMemo(() => mapAccelerationBonus(bonusDetails[ACCELERATION_BONUSES.MONITORING] ?? [], ACCELERATION_BONUSES.MONITORING), [bonusDetails]);
    const reportingBonusCards = useMemo(() => mapAccelerationBonus(bonusDetails[ACCELERATION_BONUSES.REPORTING] ?? [], ACCELERATION_BONUSES.REPORTING), [bonusDetails]);

    const navClass = useMemo(() => {
        let navTabsClass = '';
        switch (tabToShow) {
            case 'phase-1':
                navTabsClass = 'border-danger';
                break;
            case 'phase-2':
                navTabsClass = 'border-warning';
                break;
            case 'phase-3':
                navTabsClass = 'border-info';
                break;
            case 'skills':
                navTabsClass = 'border-white';
                break;
            case 'services':
                navTabsClass = 'border-success';
                break;
        }

        return navTabsClass;
    }, [tabToShow]);

    return (<div className="accounting-details-container">
        <Tbs activeKey={tabToShow} onSelect={(k) => setTabToShow(k)} className={"phases " + navClass}>

            <Tab eventKey="phase-1" title={i18nUtil.stepName(GAME_STEPS.BETA)}
                 tabClassName="text-white border-danger">
                <StepDetails step={GAME_STEPS.BETA}
                             color={'danger'}
                             bonusDetails={implementationBonusCards}
                             bonusGiven={accelerationBonuses[ACCELERATION_BONUSES.IMPLEMENTATION || 0]}/>
            </Tab>


            <Tab eventKey="phase-2" disabled={currentStep < GAME_STEPS.GTM}
                 title={i18nUtil.stepName(GAME_STEPS.GTM)}
                 tabClassName="text-white border-warning">

                {currentStep >= GAME_STEPS.GTM && (
                    <StepDetails step={GAME_STEPS.GTM}
                                 color={'warning'}
                                 bonusDetails={monitoringBonusCards}
                                 bonusGiven={accelerationBonuses[ACCELERATION_BONUSES.MONITORING || 0]}/>
                )}
            </Tab>


            <Tab eventKey="phase-3" disabled={currentStep < GAME_STEPS.GROWTH_MODEL}
                 title={i18nUtil.stepName(GAME_STEPS.GROWTH_MODEL)}
                 tabClassName="text-white border-info">

                {currentStep >= GAME_STEPS.GROWTH_MODEL && (
                    <StepDetails step={GAME_STEPS.GROWTH_MODEL}
                                 color={'info'}
                                 bonusDetails={reportingBonusCards}
                                 bonusGiven={accelerationBonuses[ACCELERATION_BONUSES.REPORTING || 0]}/>
                )}
            </Tab>


            {currentPhase > GAME_PHASES.PHASE_1 && (
                <Tab eventKey="skills" title={t('texts.skills')}
                     tabClassName="text-white border-light">
                    <HiringsSummary hiredCards={hiredSkills} color={"secondary"}/>
                </Tab>
            )}


            {currentPhase > GAME_PHASES.PHASE_1 && (
                <Tab eventKey="services" title={t('texts.services')}
                     tabClassName="text-white border-success">
                    <HiringsSummary hiredCards={hiredServices} color={"success"}/>
                </Tab>
            )}
        </Tbs>
    </div>);
}