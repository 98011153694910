import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function ButtonToggleFullscreen({className}) {
    const {t} = useTranslation('common');
    const [isFullScreen, setIsFullScreen] = useState(!!document.fullscreenElement);

    const handleEnterFullscreen = () => document.documentElement.requestFullscreen();
    const handleExitFullscreen = () => document?.exitFullscreen();
    const handleOnFullscreenChange = () => setIsFullScreen(!!document.fullscreenElement);

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleOnFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleOnFullscreenChange);
        }
    }, []);

    return (<button className={className} onClick={isFullScreen ? handleExitFullscreen : handleEnterFullscreen}>
        {!isFullScreen && (<>
            <FontAwesomeIcon icon={['fas', 'expand-arrows-alt']} className="mr-2"/>
            {t('btn_enter_fullscreen')}
        </>)}

        {isFullScreen && (<>
            <FontAwesomeIcon icon={['fas', 'compress-arrows-alt']} className="mr-2"/>
            {t('btn_exit_fullscreen')}
        </>)}
    </button>)
}