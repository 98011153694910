import './App.css';
import "animate.css";
import "assets/plugins/sweetAlert2/sweetAlert2.scss";

import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {RouterConfig} from "./navigation/RouterConfig";
import ConnectionError from "./pages/common/errors/ConnectionError";
import {connect} from "react-redux";
import PolicesInfo from "./components/PolicesInfo";
import UnrecoverableError from "./pages/common/errors/UnrecoverableError";
import LoggerService from "./services/common/LoggerService";
import LoadingOverlay from "./components/LoadingOverlay";
import OverlayUtil from "./utils/OverlayUtil";
import {OVERLAY_THEME} from "./config/CONSTANTS";
import I18nLoadingOverlay from "./components/i18nLoadingOverlay";
import SoundEffectsWrapper from "./wrappers/SoundEffectsWrapper";
import ConnectionWatcher from "./watchers/ConnectionWatcher";
import ActivityWatcher from "./watchers/ActivityWatcher";
import IdleTimeoutError from "./pages/common/errors/IdleTimeoutError";
import SyncWatcher from "./watchers/SyncWatcher";
import I18nWrapper from "./wrappers/i18nWrapper";
import IsMobileWatcher from "./watchers/IsMobileWatcher";

function mapStateToProps(state) {
    return {
        hasConnectionError: state?.session?.hasConnectionError,
        hasIdleError: state?.session?.hasIdleError,
        hasFatalError: state?.session?.hasFatalError
    };
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: false};

        OverlayUtil.toggleLoadingOverlay(true, false, OVERLAY_THEME.DARK, false);
    }

    /**
     * Catches errors not handled by any children
     *
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error, errorInfo) {
        this.setState({error: true});
        LoggerService.error(error, {action: 'App.js error boundary'});
    }

    componentDidMount() {

        // this overwrites the css definition present in the 'index.html',
        // which is used to prevent a withe flash when the app loads
        //
        // it uses a timeout to let the loading overlay fill the screen before changing the bg to white
        setTimeout(() => {
            document.body.style.backgroundColor = '';
            document.body.classList.add('bg-white');

            const fn = () => {
                const appEl = document.getElementById('App');

                if (appEl) {
                    appEl.classList.remove('d-none');
                } else {
                    setTimeout(fn, 10);
                }
            };

            setTimeout(fn, 10);
        }, 1000);
    }

    render() {
        if (this.props.hasConnectionError) return <ConnectionError/>;
        if (this.props.hasIdleError) return <IdleTimeoutError/>;
        if (this.props.hasFatalError || this.state.error) return <UnrecoverableError/>;

        return <>
            <LoadingOverlay/>

            <React.Suspense fallback={<I18nLoadingOverlay/>}>
                <I18nWrapper>
                    <ActivityWatcher/>
                    <ConnectionWatcher/>
                    <IsMobileWatcher/>

                    <SoundEffectsWrapper>
                        <div id="App" className="App overflow-auto d-none">
                            <BrowserRouter>
                                <RouterConfig/>
                                <SyncWatcher/>
                            </BrowserRouter>
                        </div>
                    </SoundEffectsWrapper>
                    <PolicesInfo/>
                </I18nWrapper>
            </React.Suspense>
        </>
    }
}

export default connect(mapStateToProps)(App);

