import React from "react";
import StepTitle from "../components/StepTitle";

export default function SmgxStep3() {
    return (<div className="step-3">
        <StepTitle>
            Alguns dos seus desafios serão
        </StepTitle>

        <div className="text-left h5">
            <ul>
                <li>
                    Mostrar que está sintonizado com conceitos e práticas do ecossistema de inovação e startups, e assim
                    ter recursos para utilizar nos mercados de talentos e serviços
                </li>

                <li>
                    Contratar uma equipe múltipla e serviços relevantes para alavancar a aceleração e o faturamento da
                    startup escolhida
                </li>

                <li>
                    Lidar com imprevisibilidades e conseguir recursos para continuar avançando na jornada
                </li>
            </ul>
        </div>
    </div>)
}