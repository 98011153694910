import store from "redux/store";
import ApiDataParserService from "../api/ApiDataParserService";
import {batch} from "react-redux";
import GameReduxService from "../../redux/GameReduxService";
import EventProcessor from "./EventProcessor";
import AccountingService from "../AccountingService";
import {GAME_STEPS} from "../../../config/CONSTANTS";
import AuthService from "../../player/AuthService";
import {setMaxPlayerCount} from "../../../redux/slices/gameSlice";
import EventsUtil from "../../../utils/EventsUtil";
import {GAME_EVENTS} from "../../../config/EVENTS";
import TournamentService from "../TournamentService";
import {setHasSyncError} from "../../../redux/slices/sessionSlice";
import GameUtil from "../../../utils/GameUtil";

/**
 * Reloads the app if an updated was made to the current user by an external entity (i.e. game master updated the team)
 *
 * @param wsMessage
 * @param playerId
 */
function reloadWindowIfNecessary(wsMessage, playerId) {
    const currentUser = store.getState().session.user;
    const isGameMaster = store.getState().session.isGameMaster;

    if (!isGameMaster && wsMessage.updatedByGm === true && currentUser.id.toString() === playerId.toString()) {
        window.location.reload();
    }
}

function recalculateAccounting(step) {
    if (store.getState().session.isGameMaster) {
        for (let i = 1; i < store.getState().game.teamCount + 1; i++) {
            AccountingService.recalculateForStep(i, step);
        }
    } else if (store.getState().session.showBoardForTeam) {
        AccountingService.recalculateForStep(store.getState().session.showBoardForTeam, step);
    }
}

function syncGameData(syncData) {
    if (!syncData || store.getState().game.isTournament)
        return true;

    const isGameMaster = store.getState().session.isGameMaster;
    const showBoardForTeam = store.getState().session.showBoardForTeam;

    Object.entries(syncData).forEach(([team, teamSyncData]) => {
        if (!isGameMaster && showBoardForTeam !== team)
            return;

        const teamData = store.getState().teams[team];

        if (!!teamSyncData.skills && teamSyncData.skills !== teamData.skills.length)
            return reloadGameData();

        if (!!teamSyncData.services && teamSyncData.services !== teamData.services.length)
            return reloadGameData();

        if (!!teamSyncData.questionsRevealed) {
            const teamQuestionsRevealed = Object.values(teamData.questions)
                .filter(x => x.showResult === true)
                .length;

            if (teamSyncData.questionsRevealed !== teamQuestionsRevealed)
                return reloadGameData();
        }

        if (!!teamSyncData.unpredictabilityRevealed) {
            const teamUnpredictabilityRevealed = Object.values(teamData.unpredictabilities)
                .filter(x => x.revealOutcome === true)
                .length;

            if (teamSyncData.unpredictabilityRevealed !== teamUnpredictabilityRevealed)
                return reloadGameData();
        }
    });

    return true;
}

function reloadGameData() {
    console.info('GAME DATA IS OUT OF SYNC');
    console.info('SOFT REFRESHING...');

    store.dispatch(setHasSyncError(true));

    return false;
}


const GameEventProcessor = {
    playerUpdatedEventProcessor(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            const user = ApiDataParserService.parsePlayer(wsMessage.data);
            if (!user.id) return;

            reloadWindowIfNecessary(wsMessage, user.id);

            batch(() => {
                if (!GameReduxService.updatePlayer(user)) {
                    GameReduxService.addPlayer(user);
                }
            });
        });
    },

    playerNpsUpdatedEventProcessor(wsMessage) {
        const data = wsMessage.data;

        batch(() => {
            GameReduxService.updateNpsCount(data.id, data.npsCount);
        });
    },

    playerTeamUpdatedEventProcessor(wsMessage) {
        const data = wsMessage.data;

        reloadWindowIfNecessary(wsMessage, data.id);

        batch(() => {
            GameReduxService.updatePlayerTeam(data.id, data.team);
        });
    },

    playerRemovedEventProcessor(wsMessage) {
        const user = ApiDataParserService.parsePlayer(wsMessage.data);

        if (user.id === store.getState().session.user.id) {
            GameReduxService.removePlayer(user);
        }

        EventProcessor.queue(wsMessage, () => {
            batch(() => GameReduxService.removePlayer(user));
        });
    },

    async playerKickedEventProcessor(wsMessage) {
        const userId = wsMessage.data.id;
        const currentUser = store.getState().session.user;

        if (userId === currentUser.id) {
            const gameUrl = await AuthService.leave();
            const newUrl = new URL(window.location.protocol + window.location.host + gameUrl);

            newUrl.searchParams.set('kicked', 'true');
            newUrl.searchParams.set('nickname', currentUser.nickname);

            // when disconnected because a device with the ID of the user rejoined the session
            // i.e. a device with a token saved in the local storage rejoined the session
            if (wsMessage.data?.tokenRejoined === true)
                newUrl.searchParams.set('rejoined', 'true');


            window.location.replace(newUrl);
        }
    },

    advanceGameEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            if (!syncGameData(wsMessage.data.syncData))
                return;


            const config = ApiDataParserService.parseGameData(wsMessage.data);

            batch(() => GameReduxService.updateAllData(config));

            if (!config.isPocket)
                recalculateAccounting(config.step);
            else
                recalculateAccounting(GAME_STEPS.PITCH_2);


            if (store.getState().game.isTournament && config.step !== GAME_STEPS.END_GAME)
                TournamentService.touchTournamentState({showStepChangeTransition: true});

            EventsUtil.publish(GAME_EVENTS.STEP_ADVANCED, {});
        });
    },

    gameConfigUpdatedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            const data = ApiDataParserService.parseGameData(wsMessage.data || {});
            batch(() => GameReduxService.updateAllData(data));
        });
    },

    reloadEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            window.location.reload();
        });
    },

    rankingUpdatedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            const data = ApiDataParserService.parseGameData(wsMessage.data || {});
            batch(() => GameReduxService.updateAllData(data));

            recalculateAccounting(GameUtil.getLastPitchStep());
        });
    },

    maxPlayerCountUpdated(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            const data = ApiDataParserService.parseGameData(wsMessage.data || {});
            batch(() => store.dispatch(setMaxPlayerCount(data.maxPlayerCount)));
        });
    }
};

export default GameEventProcessor;