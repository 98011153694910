import React, {memo, useRef, useState} from "react";

import "./TournamentBetaNps.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TournamentBetaNps = ({data}) => {
    const iframeRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);


    return (
        <div className="tournament-beta-nps h-100 w-100 d-flex justify-content-center align-items-center flex-column">

            <iframe className={`rounded bg-white`}
                    style={{opacity: isLoading ? 0 : 1}}
                    onLoad={() => setIsLoading(false)}
                    ref={iframeRef}
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeLTR4stF5KoahEzAZb1s_9e9AJrZ5vFgyj5Q6ak2vjfKO_FQ/viewform?embedded=true"
                    frameBorder="0" marginHeight="0" marginWidth="0">
            </iframe>


            {isLoading && (<div
                className="position-absolute left right top d-flex justify-content-center align-items-center text-white w-100 h-100">
                <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-6x fa-spin"/>
            </div>)}
        </div>
    );
}

export default memo(TournamentBetaNps);