import React, {useMemo} from "react";

import TeamStepInPodium from "../componentsMobile/TeamStepInPodium";
import {useTranslation} from "react-i18next";

export default function Mobile({teamsGroupedByRanking}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.ranking'});

    const sortedTeams = useMemo(() => {
        return Object.keys(teamsGroupedByRanking).sort().reduce((result, position) => {
            result[position] = teamsGroupedByRanking[position];
            return result;
        }, {});
    }, [teamsGroupedByRanking]);

    return (
        <div
            className="ranking-player d-flex w-100 justify-content-center flex-column align-items-center text-white  border-warning">
            <div className="w-100">
                <div className="box-head bg-warning text-white text-uppercase">
                    {t('texts.podium')}
                </div>
            </div>

            <div className="d-flex flex-column w-100 px-4 pb-3" style={{gap: 10}}>
                {Object.entries(sortedTeams).map(([position, teams]) => {
                    return teams.map(teamRanking => (
                        <TeamStepInPodium teamRanking={teamRanking} key={teamRanking.team}/>
                    ));
                })}
            </div>
        </div>
    );
}