import React, {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import './Header.scss';
import {useTranslation} from "react-i18next";
import Sidebar from "../../../../components/Sidebar";
import SidebarContent from "./SidebarContent/SidebarContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TeamPlayersModal from "../components/TeamPlayers/TeamPlayersModal";
import TournamentPlayerInfoModal from "../components/TournamentPlayerInfoModal/TournamentPlayerInfoModal";

const USERS_TO_DISPLAY_IN_HEADER = 3;

const CONTACT_LINK = 'https://www.startupmundi.com';

export function Header() {
    const {t, i18n} = useTranslation('pages/common/board/header');
    const navigate = useNavigate();

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isTournament = useSelector(state => state.game.isTournament);
    const game = useSelector(state => state.game.gameInfo.game);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const player = useSelector(state => state.session.user);

    const [showSidebar, setShowSidebar] = useState(false);

    const [showTeamPlayersModal, setShowTeamPlayersModal] = useState(false);
    const handleShowTeamPlayersModal = () => setShowTeamPlayersModal(true);
    const handleCloseTeamPlayersModal = () => setShowTeamPlayersModal(false);

    const sidebarTitle = useMemo(() => {
        if (isDemonstration)
            return 'Mundi Demo Session';

        if (isTournament)
            return "TORNEIO " + (game ? game.toUpperCase() : '');

        return  t('texts.team', {team: showBoardForTeam});
    }, [isDemonstration, isTournament])

    return (
        <>
            <nav id="board-header-mobile" className={`navbar w-100 navbar-dark text-white bg-team-${showBoardForTeam}`}>
                <div className="d-flex align-items-center justify-content-between w-100 ">
                    <div>
                        <button className="navbar-toggler text-white border-white" onClick={() => setShowSidebar(true)}>
                            <span className="navbar-toggler-icon"/>
                        </button>
                    </div>

                    <button className="btn btn-outline-secondary px-2 py-1" onClick={handleShowTeamPlayersModal}>
                        <FontAwesomeIcon
                            icon={['fas', (player?.localPlayers?.length ? 'users' : 'user')]}
                            style={{fontSize: 22}}/>

                        <b className="ml-2">{player.nickname}</b>
                    </button>
                </div>
            </nav>

            <Sidebar title={sidebarTitle}
                     show={showSidebar}
                     styleClass={`bg-team-${showBoardForTeam}`}
                     onClose={() => setShowSidebar(false)}>

                <SidebarContent onClose={() => setShowSidebar(false)}/>
            </Sidebar>

            {showTeamPlayersModal && !isTournament && (<TeamPlayersModal onClose={handleCloseTeamPlayersModal}/>)}
            {showTeamPlayersModal && isTournament && (
                <TournamentPlayerInfoModal onClose={handleCloseTeamPlayersModal}/>)}
        </>
    );
}