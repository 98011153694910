import React from "react";
import i18nUtil from "utils/i18nUtil";

import "./PhaseEventSeparator.scss";
import {useSelector} from "react-redux";

export default function PhaseEventSeparator({data}) {
    const isMobile = useSelector(state => state.session.isMobile);

    const claz = isMobile ? 'mobile' : 'desktop';

    return (
        <div className={`phase-event-separator mx-4 h-100 text-white d-flex justify-content-center align-items-center ${claz}`}>
            <div>
                <h4 className="text-uppercase">{i18nUtil.phaseName(data.phase)}</h4>
            </div>
        </div>
    );
}