import React from "react";
import StepTitle from "../components/StepTitle";

export default function SmgxStep1() {

    return (<div>
        <StepTitle>
            A partir de agora você terá uma jornada cheia de desafios e aprendizados
        </StepTitle>

        <h5>
            Nela você vai descobrir como levar uma startup da concepção à escala
        </h5>
    </div>)
}