import React, {useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";

import "./NpsFormModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NpsForm from "pages/common/NpsFormModal/components/NpsForm";
import {useSelector} from "react-redux";
import PlayerService from "services/common/PlayerService";
import LoggerService from "services/common/LoggerService";
import OverlayUtil from "utils/OverlayUtil";
import {useTranslation} from "react-i18next";
import Utils from "utils/Utils";
import SessionPersistenceUtil, {PLAYER_KEY} from "utils/SessionPersistenceUtil";

const SUBMITTED_ALERT_TIMEOUT = 5000;

export default function NpsFormModal({onClose, player}) {
    const {t} = useTranslation('pages/common/nps_form');

    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const isMobile = useSelector(state => state.session.isMobile);
    const openUntil = useSelector(state => state.game.gameInfo.openUntil);

    const [playersToSendNps, setPlayersToSendNps] = useState([]);
    const [currentPlayer, setCurrentPlayer] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);

    const [showSubmittedAlert, setShowSubmittedAlert] = useState(false);
    const [hideSubmittedAlertTimeout, setHideSubmittedAlertTimeout] = useState(null);
    const [playerWhoSubmittedNps, setPlayerWhoSubmittedNps] = useState(false);

    const closingDate = useMemo(() => {
        if (!openUntil)
            return false;

        return (new Date(openUntil).toLocaleTimeString().slice(0, -3));
    }, [openUntil]);

    const handleNpsSubmit = (player) => {
        if (hideSubmittedAlertTimeout)
            clearTimeout(hideSubmittedAlertTimeout);

        setPlayerWhoSubmittedNps(player.nickname);
        setShowSubmittedAlert(true);

        const newPlayers = playersToSendNps.filter(p => p.id !== player.id);
        setPlayersToSendNps(newPlayers);

        SessionPersistenceUtil.update(PLAYER_KEY, {shown_nps_form: true});

        setHideSubmittedAlertTimeout(setTimeout(() => {
            setShowSubmittedAlert(false);
        }, SUBMITTED_ALERT_TIMEOUT));
    }

    const handleNextPlayer = () => {
        let next = currentPlayer === playersToSendNps.length - 1 ? 0 : currentPlayer + 1;
        setCurrentPlayer(next);
    };

    const handleShowPlayersForm = () => {
        const buttons = document.getElementsByClassName('btn-edit-players');

        if (buttons.length) {
            buttons[0].click();
            onClose();
        }
    };

    useEffect(() => {
        if (isGameMaster) {
            setPlayersToSendNps([{
                id: Utils.uuid(),
                nickname: 'Game master'
            }]);

            setCurrentPlayer(0);
            return;
        }

        const fetchNpsSent = async () => {
            try {
                setShowOverlay(true);

                const npsAndCertificatesData = await PlayerService.getNpsAndCertificates(player.id);
                if (npsAndCertificatesData === false)
                    throw new Error('Could not fetch player data');

                const submittedNps = npsAndCertificatesData.nps ?? {};
                const playersToSubmitNps = [];
                let allPlayers = [player];

                if (player.localPlayers)
                    allPlayers = [...allPlayers, ...player.localPlayers];

                allPlayers.forEach(p => {
                    if (!submittedNps[p.id])
                        playersToSubmitNps.push(p);
                });


                // set data
                setPlayersToSendNps(playersToSubmitNps);
                setCurrentPlayer(0);
            } catch (e) {
                console.error('Error: could not mount nps modal');
                console.error(e);
                LoggerService.error(e, {
                    action: 'fetchNpsSent - NpsModal.js component',
                    params: {player}
                });

                onClose();
            } finally {
                setShowOverlay(false);
            }
        }

        fetchNpsSent();
    }, [player]);

    useEffect(() => {
        return () => {
            if (hideSubmittedAlertTimeout)
                clearTimeout(hideSubmittedAlertTimeout);
        }
    }, []);


    useEffect(() => OverlayUtil.toggleLoadingOverlay(showOverlay), [showOverlay])


    return (<Modal id="nps-form-modal" size={isMobile ? 'xl' : "lg"} show={true}>
        <Modal.Header>
            <Modal.Title>{t('texts.title')}</Modal.Title>
        </Modal.Header>


        <Modal.Body className="overflow-auto">
            {playersToSendNps.length > 0 && (<>
                <div className="alert alert-info mb-4" hidden={!closingDate}>
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-2"/>
                    {t('texts.rate_until', {date: closingDate})}
                </div>

                <NpsForm player={playersToSendNps[currentPlayer]} rootPlayerId={player?.id ?? 'root'}
                         onSubmit={handleNpsSubmit}/>
            </>)}


            {!playersToSendNps.length && (
                <div className="text-center">
                    <FontAwesomeIcon icon={['fas', 'thumbs-up']} className="fa-5x mb-5 text-primary"/>

                    <h5>{t('texts.all_rated')}</h5>
                    <p className="text-muted mb-5">{t('texts.info_add_player')}</p>

                    <button type="button" className="btn btn-primary" onClick={handleShowPlayersForm}>
                        {t('buttons.add_player')}
                    </button>
                </div>
            )}

            <div hidden={!showSubmittedAlert}
                 className="alert alert-success alert-nps-submitted animate__animated animate__faster animate__fadeInDown mt-4 mb-0">
                {t('texts.nps_submitted', {name: playerWhoSubmittedNps})}
            </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
            <div hidden={playersToSendNps.length <= 1}>
                <p className="mb-0 d-inline mx-2">
                    {currentPlayer + 1}/{playersToSendNps.length}
                </p>

                <button type="button" className="btn btn-secondary border px-2 py-1" onClick={handleNextPlayer}>
                    {t('buttons.skip')}
                    <FontAwesomeIcon icon={['fas', 'angle-right']} className="ml-1 fa-1x"/>
                </button>
            </div>

            <div className="flex-fill d-flex justify-content-end">
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </div>
        </Modal.Footer>
    </Modal>)
}