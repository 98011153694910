import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {GAME_STEPS} from "config/CONSTANTS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./CheckinAndNpsButtons.scss";
import {useTranslation} from "react-i18next";
import PlayerFormModal from "pages/common/PlayerFormModal/PlayerFormModal";
import NpsFormModal from "pages/common/NpsFormModal/NpsFormModal";


export default function PlayerActionsButtons({
                                                 npsButtonClass = '',
                                                 checkinButtonClass = '',
                                                 editPlayerButtonClass = ''
                                             }) {
    const {t} = useTranslation('common', {keyPrefix: 'player_actions_buttons'});

    const currentStep = useSelector(state => state.game.currentStep);
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const currentUser = useSelector(state => state.session.user);
    const allowCertificateRequest = useSelector(state => state.game.allowCertificateRequest);

    const [showPlayerCertificatesRequestModal, setShowPlayerCertificatesRequestModal] = useState(false);
    const handlePlayerCertificatesRequestModalClose = () => setShowPlayerCertificatesRequestModal(false);
    const handlePlayerCertificatesRequestModalShow = () => setShowPlayerCertificatesRequestModal(true);

    const [showNpsModal, setShowNpsModal] = useState(false);
    const handleCloseNpsModal = () => setShowNpsModal(false);
    const handleShowNpsModal = () => setShowNpsModal(true);

    const disableCertificateForm = useMemo(() => {
        return !allowCertificateRequest || currentStep < GAME_STEPS.ONBOARDING || currentStep >= GAME_STEPS.END_GAME;
    }, [allowCertificateRequest, currentStep]);

    const isNpsEnabled = useMemo(()=> {
        return allowCertificateRequest && currentStep >= GAME_STEPS.END_GAME;
    }, [allowCertificateRequest, currentStep]);

    const checkInButtonPulsatingClass = useMemo(() => {
        return !isGameMaster && !currentUser?.certificatesRequested ? 'pulsating' : '';
    }, [currentUser, isGameMaster]);

    const npmButtonPulsatingClass = useMemo(() => {
        return !isGameMaster && currentUser.npsCount === 0 ? 'pulsating' : '';
    }, [currentUser, isGameMaster, currentUser.npsCount]);

    return (<>
        {!disableCertificateForm && (
            <button id="check-in-button" onClick={handlePlayerCertificatesRequestModalShow}
                    className={checkInButtonPulsatingClass + " checkin-button " + checkinButtonClass}>
                <FontAwesomeIcon icon={['fas', 'file-signature']} className="mr-2"/> {t('buttons.checkin')}
            </button>
        )}

        {isNpsEnabled && (
            <button onClick={handleShowNpsModal} className={npmButtonPulsatingClass + " nps-button " + npsButtonClass}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} className="mr-2"/> {t('buttons.nps')}
            </button>
        )}

        <button onClick={handlePlayerCertificatesRequestModalShow}
                className={'btn-edit-players ' + editPlayerButtonClass}>
            <FontAwesomeIcon icon={['fas', 'edit']} className="mr-2"/> {t('buttons.players')}
        </button>

        {showPlayerCertificatesRequestModal && (
            <PlayerFormModal player={!isGameMaster ? currentUser : null}
                             onClose={handlePlayerCertificatesRequestModalClose}/>)}

        {showNpsModal && (<NpsFormModal player={!isGameMaster ? currentUser : null} onClose={handleCloseNpsModal}/>)}
    </>);
};