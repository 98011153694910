import React from "react";

import "./GameEvents.scss";
import EventsList from "../../../components/GameEvents/EventsList";
import BtnScrollToTop from "components/BtnScrollToTop";

export default function GameEvents() {
    return (<div>
        <h5 className="w-100 text-center">
            Histórico de eventos do jogo
        </h5>

        <div className="game-events mobile pb-3 px-1">
            <p className="text-light mb-0 w-100 text-center pt-2 text-uppercase small">
                Mais recente no topo
            </p>

            <hr className="mb-4 mt-2 border-light"/>

            <EventsList className="d-flex flex-wrap justify-content-center flex-column-reverse"/>

            <BtnScrollToTop parentSelector="#sidebar-left .sidebar-scroll-container"/>
        </div>
    </div>);
}