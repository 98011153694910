import React from 'react';
import TotalsSummary from "../../../components/Accounting/esgComponents/TotalsSummary";
import Tabs from "../../../components/Accounting/esgComponents/Tabs";


export default function EsgAccountingDetails() {

    return (<>
        <TotalsSummary/>
        <hr className="border-light"/>
        <Tabs/>
    </>);
}
