import React from "react";

export default function Message({message, bg = 'light'}) {
    const style = {
        backgroundColor: bg === 'light' ? '#4E5C76' : 'var(--primary)'
    };

    return (
        <div className="px-3 py-2 w-100 text-white message mb-3" style={style}>
            {message.message}

            <div className="w-100 d-flex justify-content-end text-warning small">
                {message.time.toLocaleString()}
            </div>
        </div>
    );
}