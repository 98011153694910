import React, {useMemo} from "react";

import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "hooks/useTranslationForGame";
import ColumnView from "../components/ColumnView";

export default function StepDetails({step, bonusDetails, bonusGiven, color}) {
    const {t} = useTranslation('pages/common/board/accounting', {keyPrefix: 'texts'});
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.phases[2].steps[step]);

    const impactElementClass = useMemo(() => {
        return accountingDetails.acceleration < 0 ? 'text-danger' : 'text-success';
    }, [accountingDetails]);

    const columnViewData = useMemo(() => {
        const leftItems = [
            {
                title: t('extra_costs'),
                value: i18nUtil.formatMoney(accountingDetails.extraCosts || 0)
            },
            {
                title: t('received_for_market'),
                value: i18nUtil.formatMoney(accountingDetails.questionCapitalReceived || 0)
            },
            {
                title: tfg('acceleration_bonus'),
                value: i18nUtil.formatPercent(bonusGiven || 0)
            }
        ];

        const rightItems = [
            {
                title: t('months'),
                value: accountingDetails.monthsAdvanced
            },
            {
                title: tfg('revenue'),
                value: i18nUtil.formatDecimal(accountingDetails.revenue || 0)
            },
            {
                title: tfg('acceleration'),
                value: i18nUtil.formatPercent(accountingDetails.acceleration || 0),
                valueClass: `font-weight-bold ${impactElementClass}`
            },
        ];

        return {leftItems, rightItems};
    }, [accountingDetails, showBoardForTeam, impactElementClass]);


    return (<div className={"text-white p-2 rounded border-" + color}>
        <div>
            <h5 className={"text-uppercase text-" + color}>
                {t('step_summary', {step: i18nUtil.stepName(step)})}
            </h5>

            <div className="mb-3 pt-1">
                <ColumnView leftItems={columnViewData.leftItems} rightItems={columnViewData.rightItems}/>
            </div>
        </div>

        {bonusDetails.length > 0 && (<>
            <p className="text-info">{t('step_related_hires')}</p>

            <div className="d-flex flex-wrap hired-cards-container">
                {bonusDetails}
            </div>
        </>)}
    </div>);
}