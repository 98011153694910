import {
    ACCELERATION_BONUSES,
    GAME_PHASES,
    GAME_STEPS,
    GAME_STEPS_WITH_PITCH,
    GAME_STEPS_WITH_QUESTIONS,
    GAMES,
    IS_PRODUCTION_ENV,
    UNPREDICTABILITY_IMPACT
} from "config/CONSTANTS";

import store from "redux/store";
import CardUtil from "utils/CardUtil";
import {setAccounting} from "redux/slices/teamsSlice";
import Utils from "../../utils/Utils";
import {i18nService} from "../../i18n";
import GameUtil from "../../utils/GameUtil";

import ACCOUNTING_SMGX_CONFIG from "../../config/ACCOUNTING_SMGX_CONFIG";
import ACCOUNTING_ESG_CONFIG from "../../config/ACCOUNTING_ESG_CONFIG";
import DEMO_SESSION_CONFIG from "../../config/DEMO_SESSION_CONFIG";

let _ranking = null;
let _includedDiversityBonus = {1: false, 2: false, 3: false, 4: false};
let _accountingConfig = ACCOUNTING_SMGX_CONFIG;

function getT() {
    return i18nService.getFixedT({ns: 'common'});
}

// UTILS
function getVelocity(team, stopAtStep = GAME_STEPS.END_GAME) {
    const t = getT();

    if (stopAtStep < GAME_STEPS.CONCEPTION) stopAtStep = GAME_STEPS.CONCEPTION;
    if (stopAtStep > GAME_STEPS.PITCH_3) stopAtStep = GAME_STEPS.PITCH_3;

    const stepRules = _accountingConfig.GAME_TEAM_SPEED_BY_ADVANCED_MONTHS[stopAtStep];
    let advancedMonths = 0;

    for (let i = GAME_STEPS.ONBOARDING; i < stopAtStep; i++) {
        advancedMonths += getMonthsAdvanced(team, i);
    }

    if (stepRules) {
        if (advancedMonths <= stepRules.FAST) return t('startup_speed.fast');
        if (advancedMonths >= stepRules.FAST && advancedMonths < stepRules.SLOW) return t('startup_speed.normal');
        return t('startup_speed.slow');
    }

    return '-';
}

function getMonthsAdvanced(team, step) {
    if (GAME_STEPS_WITH_QUESTIONS.includes(step)) {
        const assignedQuestion = store.getState().teams[team]?.questions[step];
        if (assignedQuestion && assignedQuestion.showResult === true) {
            return assignedQuestion.isCorrect === true
                ? _accountingConfig.GAME_MONTHS_TO_ADVANCE_PER_STEP[step].RIGHT
                : _accountingConfig.GAME_MONTHS_TO_ADVANCE_PER_STEP[step].WRONG;
        } else {
            return 0;
        }
    }

    return _accountingConfig.GAME_MONTHS_TO_ADVANCE_PER_STEP[step] || 0;
}

function getStepQuestionInfo(team, step) {
    let hasQuestion = false;
    let investmentReceived = 0;
    let revealAnswer = false;
    let resultStatus = false;

    if (GAME_STEPS_WITH_QUESTIONS.includes(step)) {
        const assignedQuestion = store.getState().teams[team]?.questions[step];

        if (assignedQuestion && assignedQuestion.showResult === true) {
            if (_accountingConfig.GAME_MONTHS_CAPITAL_PER_STEP[step]) {
                investmentReceived = assignedQuestion.isCorrect === true
                    ? _accountingConfig.GAME_MONTHS_CAPITAL_PER_STEP[step].RIGHT
                    : _accountingConfig.GAME_MONTHS_CAPITAL_PER_STEP[step].WRONG;
            }

            revealAnswer = assignedQuestion.showResult;
            resultStatus = assignedQuestion.isCorrect;
        }

        hasQuestion = true;
    }

    return {hasQuestion, investmentReceived, revealAnswer, resultStatus};
}


// CALCULATIONS
function calculateAccountingForStep(team, step, lastStep) {
    const currentStepPhase = CardUtil.getGamePhaseForStep(step);
    const lastStepPhase = CardUtil.getGamePhaseForStep(lastStep?.step || 0);
    const teamData = store.getState().teams[team];

    const pitchBonus = GAME_STEPS_WITH_PITCH.includes(step)
        ? (teamData.pitchBonus[step] || 0)
        : 0;

    /**
     * we need to check for:
     * `lastStep && currentStepPhase !== lastStepPhase`
     *
     * because when `currentStepPhase !== lastStepPhase` we recalculate the acceleration from zero,
     * not taking into account the `lastStep.acceleration` value
     *
     * therefore, when `currentStepPhase !== lastStepPhase`, we need to apply the `initialAcceleration` value
     *
     * in summary: we need to apply `initialAcceleration` for each first step, for each phase
     */
    const initialAcceleration = step === GAME_STEPS.CONCEPTION || (lastStep && currentStepPhase !== lastStepPhase)
        ? _accountingConfig.GAME_INITIAL_ACCELERATION
        : 0;

    let capital = step === GAME_STEPS.CONCEPTION ? _accountingConfig.GAME_INITIAL_CAPITAL : 0;

    if (GAME_STEPS_WITH_PITCH.includes(step) && store.getState().game.currentStep > step) {
        let teamRanking = 1;

        if (currentStepPhase === GAME_PHASES.PHASE_3) {
            // for phase 3 the team pitch investment must be added only after the team ranking is revealed or calculated
            const isCalculated = !!_ranking;

            const ranking = isCalculated ? _ranking : store.getState().game.ranking;
            const show = isCalculated || ranking?.[team]?.show === true;

            if (ranking && show) {
                teamRanking = ranking[team].ranking;
            } else {
                teamRanking = 99;
            }
        }

        capital += _accountingConfig.GAME_PITCH_INVESTMENT[step][teamRanking] || 0;
    }


    let servicesAndSkillsInvestment = 0;
    let acceleration = initialAcceleration + pitchBonus;

    const unpredictability = teamData.unpredictabilities[step];
    if (unpredictability && unpredictability.reveal === true && unpredictability.revealOutcome === true) {
        if (unpredictability.impact === UNPREDICTABILITY_IMPACT.NEGATIVE && unpredictability.card.negativeAccelerationImpact) {
            acceleration -= unpredictability.card.negativeAccelerationImpact;
        }

        if (unpredictability.impact === UNPREDICTABILITY_IMPACT.POSITIVE && unpredictability.card.positiveAccelerationImpact) {
            acceleration += unpredictability.card.positiveAccelerationImpact;
        }
    }

    const calcFn = (hiredCards) => {
        hiredCards.forEach(hiredCard => {
            servicesAndSkillsInvestment += hiredCard.card.price;
            acceleration += (currentStepPhase === GAME_PHASES.PHASE_2
                ? hiredCard.card.accelerationPhase2
                : hiredCard.card.accelerationPhase3);
        });
    };

    calcFn(teamData?.skills[step] || []);
    calcFn(teamData?.services[step] || []);

    if (lastStep && currentStepPhase !== lastStepPhase) {
        let fn = (hiredCards) => {
            hiredCards.forEach(hiredCard => {
                if (hiredCard.step < step) {
                    acceleration += currentStepPhase === GAME_PHASES.PHASE_2
                        ? hiredCard.card.accelerationPhase2
                        : hiredCard.card.accelerationPhase3
                }
            });
        };

        fn(CardUtil.hiredCardsToArray(teamData?.skills || {}))
        fn(CardUtil.hiredCardsToArray(teamData?.services || {}))

        for (let i = GAME_PHASES.PHASE_1; i < GAME_PHASES.END_GAME && i < currentStepPhase; i++) {
            acceleration += (teamData.pitchBonus[CardUtil.getPitchStepForPhase(i)] || 0);
        }

        for (let i = GAME_STEPS.BETA; i < step; i++) {
            const unpredictability = teamData.unpredictabilities[i];

            if (unpredictability && unpredictability.reveal === true && unpredictability.revealOutcome === true) {
                if (unpredictability.impact === UNPREDICTABILITY_IMPACT.NEGATIVE && unpredictability.card.negativeAccelerationImpact) {
                    acceleration -= unpredictability.card.negativeAccelerationImpact;
                }

                if (unpredictability.impact === UNPREDICTABILITY_IMPACT.POSITIVE && unpredictability.card.positiveAccelerationImpact) {
                    acceleration += unpredictability.card.positiveAccelerationImpact;
                }
            }
        }
    } else {
        acceleration += lastStep?.acceleration || 0;
    }

    if (GameUtil.isCurrentGameEsg()) {
        // add implementation bonus
        if (step === GAME_STEPS.BETA && teamData.accelerationBonuses[ACCELERATION_BONUSES.IMPLEMENTATION]) {
            const implementationBonus = teamData.accelerationBonuses[ACCELERATION_BONUSES.IMPLEMENTATION];
            acceleration += implementationBonus ?? 0;
        }

        // add monitoring bonus
        if (step === GAME_STEPS.GTM && teamData.accelerationBonuses[ACCELERATION_BONUSES.MONITORING]) {
            const implementationBonus = teamData.accelerationBonuses[ACCELERATION_BONUSES.MONITORING];
            acceleration += implementationBonus ?? 0;
        }

        // add reporting bonus
        if (step === GAME_STEPS.GROWTH_MODEL && teamData.accelerationBonuses[ACCELERATION_BONUSES.REPORTING]) {
            const implementationBonus = teamData.accelerationBonuses[ACCELERATION_BONUSES.REPORTING];
            acceleration += implementationBonus ?? 0;
        }
    }


    // add diversification bonus
    if (step === GAME_STEPS.BETA) {
        let totalDiversityAcceleration = 0;

        if (teamData.accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY]) {
            acceleration += teamData.accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY];
            totalDiversityAcceleration += teamData.accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY];
        }

        if (teamData.accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY]) {
            acceleration += teamData.accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY];
            totalDiversityAcceleration += teamData.accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY];
        }

        _includedDiversityBonus[team] = totalDiversityAcceleration;
    }


    const stepQuestionData = getStepQuestionInfo(team, step);
    const monthsAdvanced = getMonthsAdvanced(team, step);

    const revenue = (_accountingConfig.GAME_REVENUE_PER_STEP[step] || 0) + ((_accountingConfig.GAME_REVENUE_PER_STEP[step] || 0) * (acceleration / 100));
    const costPerMonth = _accountingConfig.GAME_COST_PER_STEP[step] || 0;

    const demoBonus = store.getState().game.isDemonstration && store.getState().demo.state.hasRevealedUnpredictability && step === DEMO_SESSION_CONFIG.QUESTION_STEP
        ? DEMO_SESSION_CONFIG.UNPREDICTABILITY_BONUS
        : 0;

    const totalCost = costPerMonth * monthsAdvanced;
    const availableForMarket = stepQuestionData.investmentReceived - servicesAndSkillsInvestment + demoBonus;
    const balance = (lastStep?.balance || 0) + capital + revenue - totalCost - servicesAndSkillsInvestment;
    const expenses = totalCost + servicesAndSkillsInvestment;
    let extraCosts = 0;


    if (GameUtil.isCurrentGameEsg() && stepQuestionData.hasQuestion && stepQuestionData.revealAnswer && !stepQuestionData.resultStatus) {
        const questionInvestmentValues = _accountingConfig.GAME_MONTHS_CAPITAL_PER_STEP[step];

        if (questionInvestmentValues)
            extraCosts = questionInvestmentValues.RIGHT - questionInvestmentValues.WRONG;
    }

    return {
        step,
        costPerMonth,
        monthsAdvanced,
        totalCost,
        expenses,
        servicesAndSkillsInvestment,
        revenue,
        balance,
        capital,
        acceleration,
        availableForMarket,
        extraCosts,

        hasPitchBonus: pitchBonus > 0,
        hasRevenue: Utils.isset(_accountingConfig.GAME_REVENUE_PER_STEP[step]),
        hasAdvancedMonths: GAME_STEPS_WITH_QUESTIONS.includes(step) || _accountingConfig.GAME_MONTHS_TO_ADVANCE_PER_STEP[step],
        hasQuestion: GAME_STEPS_WITH_QUESTIONS.includes(step),
        revealQuestionAnswer: stepQuestionData.revealAnswer,
        questionResultStatus: stepQuestionData.resultStatus,
        questionCapitalReceived: stepQuestionData.investmentReceived
    }
}

function calculateTotalsForPhase(phase, stepsAccountingDetails) {
    const result = {
        costPerMonth: 0,
        monthsAdvanced: 0,
        totalCost: 0,
        servicesAndSkillsInvestment: 0,
        revenue: 0,
        balance: 0,
        capital: 0,
        marketInvestmentRate: 0,
        expenses: 0,
        acceleration: 0,
        availableForMarket: 0,
        questionCapitalReceived: 0,
        extraCosts: 0,
    };

    Object.values(stepsAccountingDetails).forEach(stepDetails => {
        result.costPerMonth += stepDetails.costPerMonth;
        result.monthsAdvanced += stepDetails.monthsAdvanced;
        result.totalCost += stepDetails.totalCost;
        result.expenses += stepDetails.expenses;
        result.servicesAndSkillsInvestment += stepDetails.servicesAndSkillsInvestment;
        result.revenue += stepDetails.revenue;
        result.capital += stepDetails.capital;
        result.extraCosts += stepDetails.extraCosts;

        result.availableForMarket += stepDetails.availableForMarket;
        result.questionCapitalReceived += stepDetails.questionCapitalReceived;

        result.acceleration = stepDetails.acceleration;
        result.balance = stepDetails.balance;
        result.burnRate = stepDetails.expenses - stepDetails.revenue;
    });

    result.marketInvestmentRate = ((result.servicesAndSkillsInvestment * 100) / (result.questionCapitalReceived || result.servicesAndSkillsInvestment)) || 0;

    return result;
}

function calculateTotals(phasesDetails) {
    const result = {
        costPerMonth: 0,
        monthsAdvanced: 0,
        totalCost: 0,
        expenses: 0,
        servicesAndSkillsInvestment: 0,
        revenue: 0,
        balance: 0,
        capital: 0,
        marketInvestmentRate: 0,
        acceleration: 0,
        questionCapitalReceived: 0,
        availableForMarket: 0,
        extraCosts: 0,
    }

    Object.values(phasesDetails).forEach(phaseDetails => {
        result.costPerMonth += phaseDetails.totals.costPerMonth;
        result.monthsAdvanced += phaseDetails.totals.monthsAdvanced;
        result.totalCost += phaseDetails.totals.totalCost;
        result.expenses += phaseDetails.totals.expenses;
        result.servicesAndSkillsInvestment += phaseDetails.totals.servicesAndSkillsInvestment;
        result.revenue += phaseDetails.totals.revenue;
        result.capital += phaseDetails.totals.capital;
        result.availableForMarket += phaseDetails.totals.availableForMarket;
        result.questionCapitalReceived += phaseDetails.totals.questionCapitalReceived;
        result.extraCosts += phaseDetails.totals.extraCosts;

        result.acceleration = phaseDetails.totals.acceleration;
        result.balance = phaseDetails.totals.balance;
        result.burnRate = phaseDetails.totals.burnRate;
    });

    result.marketInvestmentRate = ((result.servicesAndSkillsInvestment * 100) / result.questionCapitalReceived || result.servicesAndSkillsInvestment) || 0;

    return result;
}

function calculateSkillsOrServicesTotals(team, currentStep) {
    const calcFn = (hiredCards) => {
        const areaTotals = {};
        let cardCount = 0;
        let cardToUse = [];

        Object.entries(hiredCards).forEach(([step, hiredCards]) => {
            if (step <= currentStep) cardToUse = [...cardToUse, ...hiredCards];
        });

        cardToUse.forEach(hiredCard => {
            if (!areaTotals[hiredCard.card.area]) {
                areaTotals[hiredCard.card.area] = {percent: 0, meanPower: 0, investment: 0, count: 0};
            }

            cardCount++;
            areaTotals[hiredCard.card.area].count++;
            areaTotals[hiredCard.card.area].meanPower += CardUtil.getPower(hiredCard.card);
            areaTotals[hiredCard.card.area].investment += hiredCard.card.price;
        });

        if (cardCount) {
            Object.entries(areaTotals).forEach(([step, totals]) => {
                areaTotals[step].percent = totals.count * 100 / cardCount;
                areaTotals[step].meanPower = totals.meanPower / totals.count;
            });
        }

        return areaTotals;
    };

    return {
        skills: calcFn(store.getState().teams[team]?.skills || []),
        services: calcFn(store.getState().teams[team]?.services || [])
    }
}

const AccountingService = {
    calculateForTeam(team) {
        this.setAccountingData();

        const currentStep = store.getState().game.currentStep;

        const result = {
            speed: getVelocity(team, currentStep),
            phases: {},
            ...calculateSkillsOrServicesTotals(team, currentStep)
        };

        let lastStepCalculated = {};
        const isPocket = store.getState().game.isPocket;
        const stopAtStep = isPocket ? GAME_STEPS.PITCH_2 + 1 : GAME_STEPS.END_GAME;

        for (let step = GAME_STEPS.ONBOARDING; step < stopAtStep && step <= currentStep; step++) {
            const phase = CardUtil.getGamePhaseForStep(step);

            if (!result.phases[phase])
                result.phases[phase] = {steps: {}};

            result.phases[phase].steps[step] = calculateAccountingForStep(team, step, lastStepCalculated, currentStep);
            lastStepCalculated = result.phases[phase].steps[step];
        }

        Object.entries(result.phases).forEach(([phase, data]) => {
            result.phases[phase].totals = calculateTotalsForPhase(phase, data.steps)
        });

        result.totals = calculateTotals(result.phases);
        store.dispatch(setAccounting({team, accounting: result}));

        return result;
    },

    recalculateForStep(team, step) {
        step = parseInt(step);

        this.setAccountingData();

        // recalculate if the diversity acceleration bonus has changed since last accounting calculation
        //
        // we need to recalculate because the diversity bonus is added since the beta step, but it may be only achieved
        // after the beta step
        //
        const raceDiversityBonus = store.getState().teams[team].accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY] ?? 0;
        const genderDiversityBonus = store.getState().teams[team].accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY] ?? 0;
        const totalDiversity = raceDiversityBonus + genderDiversityBonus;

        if (totalDiversity !== 0 && totalDiversity !== _includedDiversityBonus[team])
            return this.calculateForTeam(team);


        const currentStep = store.getState().game.currentStep;
        const isPocket = store.getState().game.isPocket;
        let result = JSON.parse(JSON.stringify(store.getState().teams[team].accounting));

        if (GAME_STEPS_WITH_PITCH.includes(step - 1)) {
            step = step - 1;
        }

        const phasesToRecalculate = [];
        const stopAtStep = isPocket ? GAME_STEPS.PITCH_2 + 1 : GAME_STEPS.END_GAME;
        const previousPhase = CardUtil.getGamePhaseForStep(step - 1);

        let lastStepCalculated = result && result.phases && result.phases[previousPhase]
        && result.phases[previousPhase].steps && result.phases[previousPhase].steps[step - 1]
            ? result.phases[previousPhase].steps[step - 1]
            : null;

        for (let stepToCalc = step; stepToCalc < stopAtStep && stepToCalc <= currentStep; stepToCalc++) {
            const phase = CardUtil.getGamePhaseForStep(stepToCalc);

            if (!result || !result.phases)
                result = {phases: {}};

            if (!result.phases[phase])
                result.phases[phase] = {steps: {}};

            result.phases[phase].steps[stepToCalc] = calculateAccountingForStep(team, stepToCalc, lastStepCalculated);

            if (!phasesToRecalculate.includes(phase))
                phasesToRecalculate.push(phase);

            lastStepCalculated = result.phases[phase].steps[stepToCalc];
        }

        phasesToRecalculate.forEach(phase => {
            result.phases[phase].totals = calculateTotalsForPhase(phase, result.phases[phase].steps)
        });

        result.totals = calculateTotals(result.phases);
        result.velocity = getVelocity(team, currentStep);
        result.totals = calculateTotals(result.phases);

        store.dispatch(setAccounting({
            team,
            accounting: {...result, ...calculateSkillsOrServicesTotals(team, currentStep)}
        }));
    },

    calculateRanking() {
        console.debug('Calculating podium');

        this.setAccountingData();

        const teamCount = store.getState().game.teamCount;
        const teamsRanking = {};
        const rankingsUsed = {};

        for (let i = 1; i < teamCount + 1; i++) {
            const teamAccounting = this.calculateForTeam(i);

            teamsRanking[i] = {
                team: i,
                revenue: teamAccounting?.totals?.revenue || 0,
                acceleration: teamAccounting?.totals?.acceleration || 0,
                monthsAdvanced: teamAccounting?.totals?.monthsAdvanced || 0,

                // use the line bellow to simulate a real score and comment the 'revenue' above
                // revenue: Math.random() * 100,
            };
        }

        for (let i = 1; i < teamCount + 1; i++) {
            const teamA = teamsRanking[i];
            let ranking = teamCount;

            for (let j = 1; j < teamCount + 1; j++) {

                if (i !== j) {
                    const teamB = teamsRanking[j];
                    const result = teamA.revenue - teamB.revenue || teamB.monthsAdvanced - teamA.monthsAdvanced;

                    if (result >= 0) ranking--;
                }
            }

            if (!rankingsUsed[ranking]) rankingsUsed[ranking] = 0;
            rankingsUsed[ranking]++;

            teamA.ranking = ranking;
        }

        // normalize ranking so that there's no empty space between ranks
        for (let i = 1; i <= teamCount; i++) {
            if (rankingsUsed[i] && rankingsUsed[i] > 0)
                continue;

            for (let team = 1; team <= teamCount; team++) {
                if (teamsRanking[team].ranking >= i)
                    teamsRanking[team].ranking--;
            }
        }

        _ranking = teamsRanking;

        return teamsRanking;
    },

    calculateRankingForAllSteps() {
        this.setAccountingData();

        const isPocket = store.getState().game.isPocket;
        const stopAtStep = isPocket ? GAME_STEPS.PITCH_2 : GAME_STEPS.PITCH_3;

        const teamCount = store.getState().game.teamCount;
        const teamsData = store.getState().teams;
        const rankingPerStep = {};
        const accumulatedData = {};


        for (let i = 1; i <= teamCount; i++) {
            this.calculateForTeam(i);
            accumulatedData[i] = {totalRevenue: 0, totalMonthsAdvanced: 0};
        }


        for (let step = 0; step < GAME_STEPS.END_GAME && step <= stopAtStep; step++) {
            const teamsRanking = {};
            const stepPhase = CardUtil.getGamePhaseForStep(step);
            const rankingsUsed = {};

            for (let team = 1; team <= teamCount; team++) {
                const stepAccounting = teamsData[team].accounting?.phases?.[stepPhase]?.steps?.[step];

                accumulatedData[team].totalRevenue += stepAccounting?.revenue || 0;
                accumulatedData[team].totalMonthsAdvanced += stepAccounting?.monthsAdvanced || 0;

                teamsRanking[team] = {
                    team,
                    revenue: stepAccounting?.revenue || 0,
                    acceleration: stepAccounting?.acceleration || 0,
                    monthsAdvanced: stepAccounting?.monthsAdvanced || 0,

                    totalRevenue: accumulatedData[team].totalRevenue,
                    totalMonthsAdvanced: accumulatedData[team].totalMonthsAdvanced
                }
            }

            for (let i = 1; i <= teamCount; i++) {
                const teamA = teamsRanking[i];
                let ranking = teamCount;

                for (let j = 1; j < teamCount + 1; j++) {

                    if (i !== j) {
                        const teamB = teamsRanking[j];
                        const result = teamA.totalRevenue - teamB.totalRevenue
                            || teamB.totalMonthsAdvanced - teamA.totalMonthsAdvanced;

                        if (result >= 0) ranking--;
                    }
                }

                if (!rankingsUsed[ranking]) rankingsUsed[ranking] = 0;
                rankingsUsed[ranking]++;

                teamA.ranking = ranking;
            }

            // normalize ranking so that there's no empty space between ranks
            for (let i = 1; i <= teamCount; i++) {
                if (rankingsUsed[i] && rankingsUsed[i] > 0)
                    continue;

                for (let team = 1; team <= teamCount; team++) {
                    if (teamsRanking[team].ranking >= i)
                        teamsRanking[team].ranking--;
                }
            }

            rankingPerStep[step] = teamsRanking;
        }

        return rankingPerStep;
    },

    calculateFinalAccountingForAllTeams() {
        this.setAccountingData();

        const teamCount = store.getState().game.teamCount;
        const finalAccounting = {};

        for (let i = 1; i <= teamCount; i++) {
            finalAccounting[i] = structuredClone(this.calculateForTeam(i));
            finalAccounting[i].accelerationBonuses = store.getState().teams[i].accelerationBonuses;

            delete finalAccounting[i].speed;
        }

        return finalAccounting;
    },

    calculateMonthsAdvanced() {
        this.setAccountingData();

        const currentStep = store.getState().game.currentStep;
        const teamCount = store.getState().game.teamCount;
        const monthsAdvancedByTeam = {};

        for (let i = 1; i < teamCount + 1; i++) {
            monthsAdvancedByTeam[i] = 0;

            for (let step = 0; step <= currentStep; step++) {
                monthsAdvancedByTeam[i] += getMonthsAdvanced(i, step);
            }
        }

        return monthsAdvancedByTeam;
    },

    setAccountingData(gameType) {
        gameType = gameType ?? store.getState().game.gameInfo.game;

        _accountingConfig = gameType === GAMES.ESG
            ? ACCOUNTING_ESG_CONFIG
            : ACCOUNTING_SMGX_CONFIG;

        if (!IS_PRODUCTION_ENV)
            console.debug('Initialized accounting config for game: ' + gameType);
    }
};

window.AccountingService = AccountingService;

export default AccountingService;