import React from 'react';
import TotalsSummary from "../../../components/Accounting/esgComponents/TotalsSummary";
import Tabs from "../../../components/Accounting/esgComponents/Tabs";
import {useSelector} from "react-redux";
import {ACCELERATION_BONUSES, GAME_PHASES, GAME_STEPS} from "config/CONSTANTS";
import BonusBadge from "../../../components/Accounting/esgComponents/BonusBadge";
import {useTranslation} from "react-i18next";


export default function EsgAccountingDetails() {
    const {t} = useTranslation('pages/common/board/accounting');

    const currentStep = useSelector(state => state.game.currentStep);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accelerationBonuses = useSelector(state => state.teams[showBoardForTeam].accelerationBonuses);
    const currentPhase = useSelector(state => state.game.currentPhase);

    return (<div>
        <TotalsSummary/>

        <hr className="border-light"/>

        <div className="d-flex justify-content-center text-white flex-wrap" style={{gap: 15}}>
            <BonusBadge active={currentStep >= GAME_STEPS.BETA}
                        bonus={ACCELERATION_BONUSES.IMPLEMENTATION}
                        tooltip={t('texts.implementation_bonus')}
                        showText={true}
                        value={accelerationBonuses[ACCELERATION_BONUSES.IMPLEMENTATION]}/>

            <BonusBadge
                active={currentStep >= GAME_STEPS.GTM && !!accelerationBonuses[ACCELERATION_BONUSES.MONITORING]}
                bonus={ACCELERATION_BONUSES.MONITORING}
                tooltip={t('texts.monitoring_bonus')}
                showText={true}
                value={accelerationBonuses[ACCELERATION_BONUSES.MONITORING]}/>

            <BonusBadge
                active={currentStep >= GAME_STEPS.GROWTH_MODEL && !!accelerationBonuses[ACCELERATION_BONUSES.REPORTING]}
                bonus={ACCELERATION_BONUSES.REPORTING}
                tooltip={t('texts.reporting_bonus')}
                showText={true}
                value={accelerationBonuses[ACCELERATION_BONUSES.REPORTING]}/>
        </div>

        {currentPhase > GAME_PHASES.PHASE_1 && (<>
            <hr className="border-light"/>
            <Tabs/>
        </>)}
    </div>);
}
