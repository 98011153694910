import {GAME_STEPS as GAMES_STEPS, GAME_STEPS, GAMES} from "../config/CONSTANTS";
import i18n from "i18next";
import store from "../redux/store";
import {SUPPORTED_LANGUAGES} from "../i18n";

import esgBoardPt from "../assets/img/board/journey_map/esg/pt.svg";
import smgxBoardPt from "../assets/img/board/journey_map/smgx/pt.svg";
import esgBoardEs from "../assets/img/board/journey_map/esg/es.svg";
import smgxBoardEs from "../assets/img/board/journey_map/smgx/es.svg";
import esgBoardEn from "../assets/img/board/journey_map/esg/en.svg";
import smgxBoardEn from "../assets/img/board/journey_map/smgx/en.svg";
import CardUtil from "./CardUtil";

let _game = false;

/**
 * Helper class used to identify the game type.
 *
 * This class exists, so we can check the game type without using Redux (i.e. in a non reactive way), to prevent
 * errors when executing Redux actions that need to check the game type
 */
const GameUtil = {
    isCurrentGameEsg() {
        return _game === GAMES.ESG;
    },

    isCurrentGameSmgx() {
        return _game === GAMES.SMGX;
    },

    getJourneyMap() {
        if (store.getState().game.customization?.journey_map)
            return store.getState().game.customization.journey_map;

        const lang = i18n.language;
        const isEsg = this.isCurrentGameEsg();

        switch (lang) {
            case SUPPORTED_LANGUAGES.PORTUGUESE:
                return isEsg ? esgBoardPt : smgxBoardPt;
            case SUPPORTED_LANGUAGES.SPANISH:
                return isEsg ? esgBoardEs : smgxBoardEs;
            default:
                return isEsg ? esgBoardEn : smgxBoardEn;
        }
    },

    getLastPitchStep() {
        const isEsg = this.isCurrentGameEsg();
        const isPocket = store.getState().game.isPocket;

        return isEsg || isPocket ? GAME_STEPS.PITCH_2 : GAMES_STEPS.PITCH_3;
    },


    getLastPitchInvestmentReceived(team = store.getState().session.showBoardForTeam) {
        const lastPitchStep = this.getLastPitchStep();

        return this.getPitchInvestmentReceived(team, lastPitchStep);
    },

    getPitchInvestmentReceived(team = store.getState().session.showBoardForTeam, pitch = GAMES_STEPS.PITCH_1) {
        const teamData = store.getState()?.teams[team].accounting;
        const pitchPhase = CardUtil.getGamePhaseForStep(pitch);

        return teamData?.phases?.[pitchPhase]?.steps?.[pitch]?.capital || 0
    },

    setGameType(gameType) {
        _game = gameType;
    }
}

export default GameUtil;