import {useEffect, useMemo, useState} from "react";
import store from "../redux/store";
import {setHasConnectionError} from "../redux/slices/sessionSlice";

const TIMER_SECONDS = 1000 * 15; // 15 seconds

/**
 *  Watches for network status changes.
 *
 *  When the browser goes offline, starts a timer that may `setHasConnectionError(true)`
 *  if the connection isn't reestablished after 15 seconds
 */
export default function ConnectionWatcher() {
    const [timer, setTimer] = useState(undefined);

    // start a timer that will setHasConnectionError(true) after X seconds
    const startTimer = useMemo(() => {
        return () => setTimer(setTimeout(() => {
            store.dispatch(setHasConnectionError(true))
        }, TIMER_SECONDS));
    }, []);

    const clearTimer = useMemo(() => {
        return () => {
            clearTimeout(timer);
            setTimer(undefined);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('online', clearTimer);
        window.addEventListener('offline', startTimer);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('online', clearTimer);
            window.removeEventListener('offline', startTimer);
        };
    },)

    return null;
}