import React, {memo, useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import rightAnswerIcon from "assets/img/board/ic_ans__correct-small.svg";
import wrongAnswerIcon from "assets/img/board/ic_ans_wrong_small.svg";
import monthsToGoIcon from "assets/img/board/ic_month_journey_box.svg";
import bestPitchIcon from "assets/img/board/ic_best_pitch.svg";

import "./JourneyMapSummaryCard.scss";
import i18nUtil from "utils/i18nUtil";
import {GAME_STEPS} from "config/CONSTANTS";
import {useSelector} from "react-redux";
import CardUtil from "utils/CardUtil";
import Utils from "utils/Utils";
import {useTranslation} from "react-i18next";
import JourneyMapModal from "../../JourneyMap/JourneyMapModal";
import GameUtil from "utils/GameUtil";
import balanceIcon from "assets/img/board/ic_investment_journey_box.svg";


const JourneyMapSummaryCard = () => {
    const {t} = useTranslation('pages/common/board/journey_map');

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentStep = useSelector(state => state.game.currentStep);
    const isPocket = useSelector(state => state.game.isPocket);
    const isDemonstration = useSelector(state => state.game.isDemonstration);

    const [stepToShow, setStepToShow] = useState(currentStep);
    const [stepToShowPhase, setStepToShowPhase] = useState(false);

    const pitchBonus = useSelector(state => state.teams[showBoardForTeam].pitchBonus[stepToShow]);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);
    const journeyMapSvg = useMemo(() => GameUtil.getJourneyMap(), []);

    const accountingDetails = useSelector(state => {
        if (!stepToShow) return null;

        const phaseToShow = CardUtil.getGamePhaseForStep(stepToShow);

        if (stepToShow && state.teams[showBoardForTeam].accounting.phases && state.teams[showBoardForTeam].accounting.phases[phaseToShow]
            && state.teams[showBoardForTeam].accounting.phases[phaseToShow].steps && state.teams[showBoardForTeam].accounting.phases[phaseToShow].steps[stepToShow]) {
            return state.teams[showBoardForTeam].accounting.phases[phaseToShow].steps[stepToShow];
        }
        return {};
    });

    const totalAccountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting);

    const handleShowNextStep = () => {
        if (currentStep === GAME_STEPS.CONCEPTION) return;

        let nextStepToShow = stepToShow;
        if (stepToShow >= currentStep || stepToShow >= (isPocket ? GAME_STEPS.PITCH_2 : GAME_STEPS.PITCH_3)) nextStepToShow = GAME_STEPS.CONCEPTION;
        else nextStepToShow++;

        setStepToShow(nextStepToShow);
    };

    const handleShowPreviousStep = () => {
        if (currentStep === GAME_STEPS.CONCEPTION) return;

        let nextStepToShow = stepToShow;
        if (stepToShow === GAME_STEPS.CONCEPTION) {
            if (isPocket) nextStepToShow = (currentStep > GAME_STEPS.PITCH_2 ? GAME_STEPS.PITCH_2 : currentStep);
            else nextStepToShow = (currentStep > GAME_STEPS.PITCH_3 ? GAME_STEPS.PITCH_3 : currentStep);
        } else {
            nextStepToShow--;
        }

        setStepToShow(nextStepToShow);
    }

    const handleShowCurrentStep = () => {
        if (currentStep === GAME_STEPS.CONCEPTION) return;
        let nextStepToShow;

        if (isPocket)
            nextStepToShow = (currentStep > GAME_STEPS.PITCH_2 ? GAME_STEPS.PITCH_2 : currentStep);
        else
            nextStepToShow = (currentStep > GAME_STEPS.PITCH_3 ? GAME_STEPS.PITCH_3 : currentStep);


        setStepToShow(nextStepToShow);
    }

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        if (isPocket && currentStep >= GAME_STEPS.PITCH_2) setStepToShow(GAME_STEPS.PITCH_2);
        else if (currentStep > GAME_STEPS.PITCH_3) setStepToShow(GAME_STEPS.PITCH_3);
        else setStepToShow(currentStep);
    }, [currentStep, isPocket]);

    useEffect(() => {
        setStepToShowPhase(CardUtil.getGamePhaseForStep(stepToShow));
    }, [stepToShow]);

    useEffect(() => {
        setTimeout(() => document.querySelector('.step-result')?.click(), 1);
    }, [stepToShow]);

    const showContent = currentStep > GAME_STEPS.ONBOARDING;

    return (
        <>
            <div className="text-white journey-map-summary-card container text-center">
                {(showContent || isDemonstration) && (<>
                    <button type="button" id="btn-show-game-map"
                            className="btn btn-outline-secondary border-0 py-1 px-2"
                            onClick={handleShowModal}>

                        <img src={journeyMapSvg}/>

                        <span className="small">
                            {t('buttons.expand')}
                        </span>
                    </button>
                </>)}

                <hr className="border-light"/>

                <h5>
                    {t('texts.phase', {phase: stepToShowPhase})} - {t('texts.step', {step: stepToShow - 1})} {stepToShow === currentStep ? ' - ' + t('texts.current') : ''}
                </h5>

                <h3 className={`text-team-${showBoardForTeam} mb-4`}>
                    {i18nUtil.stepName(stepToShow)}
                </h3>

                <div className="step-result d-flex flex-column justify-content-center align-items-center">
                    <div className="result-col d-flex justify-content-center align-items-center">
                        {Utils.isset(pitchBonus) && (
                            <img alt='Best pitch badge' src={bestPitchIcon} className="best-pitch-badge"/>)}

                        {accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer && (<>
                            {accountingDetails.questionResultStatus && (
                                <img alt='Answer status icon' src={rightAnswerIcon}/>)}

                            {!accountingDetails.questionResultStatus && (
                                <img alt='Answer status icon' src={wrongAnswerIcon}/>)}
                        </>)}

                        {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && (
                            <FontAwesomeIcon icon={['fas', 'question-circle']}/>
                        )}

                        {!accountingDetails.hasQuestion && !Utils.isset(pitchBonus) && (
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="text-info"/>
                        )}

                        {Utils.isset(pitchBonus) && (
                            <FontAwesomeIcon icon={['fas', 'crown']} className="text-warning"/>
                        )}
                    </div>

                    {isEsg && (<div className="step-summary-item">
                        <p>
                            <FontAwesomeIcon icon={['fas', 'coins']}/>
                            {t('texts.revenue_esg')}
                        </p>

                        <div className="d-flex align-items-center justify-content-center">
                            <p>
                                {i18nUtil.formatDecimal(accountingDetails.revenue || 0)}
                            </p>
                        </div>
                    </div>)}


                    <div className="step-summary-item">
                        <p>
                            <img src={monthsToGoIcon} alt="Months to go icon"/>
                            {t('tooltips.months_advanced')}
                        </p>

                        <div className="d-flex align-items-center justify-content-center">
                            <p>
                                {((accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer)
                                    || (!accountingDetails.hasQuestion && accountingDetails.hasAdvancedMonths)) && t('texts.month', {count: accountingDetails.monthsAdvanced || 0})}

                                {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && '???'}
                                {!accountingDetails.hasQuestion && !accountingDetails.hasAdvancedMonths && ('-')}
                            </p>
                        </div>
                    </div>

                    <div className="step-summary-item">
                        <p>
                            <img src={balanceIcon} alt="Balance icon"/>
                            {t('tooltips.for_market')}
                        </p>

                        <div className="d-flex align-items-center justify-content-center">
                            {accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer && (
                                <>{i18nUtil.formatMoney(accountingDetails.questionCapitalReceived || 0)}</>
                            )}

                            {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && '???'}
                            {!accountingDetails.hasQuestion && ('-')}
                        </div>
                    </div>

                    <div className="step-summary-item">
                        <p>
                            {isEsg && <FontAwesomeIcon icon={['fas', 'percent']}/>}
                            {!isEsg && <FontAwesomeIcon icon={['far', 'minus-square']}/>}

                            {isEsg ? t('texts.acceleration_esg') : t('tooltips.total_month_cost')}
                        </p>

                        <div className="d-flex align-items-center justify-content-center">
                            {isEsg && (<>
                                {accountingDetails.acceleration}%
                            </>)}

                            {!isEsg && (<>
                                {((accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer)
                                    || (!accountingDetails.hasQuestion && accountingDetails.hasAdvancedMonths)) && (
                                    <>{i18nUtil.formatMoney(accountingDetails.totalCost || 0)}</>
                                )}

                                {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && '???'}
                                {!accountingDetails.hasQuestion && !accountingDetails.hasAdvancedMonths && ('-')}
                            </>)}
                        </div>
                    </div>
                </div>

                <div className="navigate-buttons d-flex justify-content-center mt-3">
                    <button className="btn btn-outline-light border-0" onClick={handleShowPreviousStep}>
                        <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-2x"/>
                    </button>

                    <button className="btn btn-outline-light border-0" onClick={handleShowCurrentStep}>
                        {t('buttons.current')}
                    </button>

                    <button className="btn btn-outline-light border-0" onClick={handleShowNextStep}>
                        <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-2x"/>
                    </button>
                </div>

                <hr className="border-light"/>

                <h4 className="mb-4">
                    {t('texts.overview')}
                </h4>

                <div className="step-result d-flex flex-column justify-content-center align-items-center">
                    <div className="step-summary-item">
                        <p>
                            <img src={monthsToGoIcon} alt="Months to go icon"/>
                            {t('texts.months_covered')}
                        </p>

                        <p>{totalAccountingDetails?.totals?.monthsAdvanced || '0'}</p>
                    </div>

                    <div className="step-summary-item">
                        <p>
                            <FontAwesomeIcon icon={['fas', 'tachometer-alt']}/>
                            {t('texts.startup_speed')}
                        </p>

                        <p>{totalAccountingDetails?.speed || '-'}</p>
                    </div>
                </div>
            </div>

            {showModal && <JourneyMapModal onClose={handleCloseModal}/>}
        </>
    );
}

export default memo(JourneyMapSummaryCard);