import React, {useMemo} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import store from "redux/store";
import {setSelectedMobileTab} from "redux/slices/sessionSlice";
import {useSelector} from "react-redux";
import "./Option.scss";
import {GAME_STEPS} from "config/CONSTANTS";
import {MOBILE_GAME_TABS, MOBILE_TAB_ICON} from "config/MOBILE";

export default function Option({
                                   tab,
                                   showNotification = false,
                                   title = null,
                                   onClick = null,
                                   iconSize = 32,
                                   id = null
                               }) {

    const selectedMobileTab = useSelector(state => state.session.selectedMobileTab);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentStep = useSelector(state => state.game.currentStep);
    const game = useSelector(state => state.game.gameInfo.game);
    const hasNotification = useSelector(state => state.session.notifications[tab]);

    const disabled = useMemo(() => {
        return tab === MOBILE_GAME_TABS.STARTUP_PROFILE && currentStep < GAME_STEPS.CONCEPTION
            || tab === MOBILE_GAME_TABS.JOURNEY_MAP && currentStep < GAME_STEPS.ONBOARDING
            || tab === MOBILE_GAME_TABS.ACCOUNTING && currentStep < GAME_STEPS.MVP
            || tab === MOBILE_GAME_TABS.HIRED_CARDS && currentStep < GAME_STEPS.BETA;
    }, [tab, currentStep]);

    const clasz = useMemo(() => {
        if (disabled)
            return "disabled btn-dark";

        return tab === selectedMobileTab
            ? `bg-team-${showBoardForTeam} text-white selected`
            : 'btn-primary';
    }, [showBoardForTeam, selectedMobileTab, disabled]);

    const notificationClass = useMemo(() => {
        if (!showNotification || !hasNotification)
            return 'd-none';

        return `bg-team-${showBoardForTeam}`;
    }, [showBoardForTeam, hasNotification, showNotification]);

    const icon = useMemo(() => {
        if (typeof MOBILE_TAB_ICON[tab] === 'object')
            return MOBILE_TAB_ICON[tab]?.[game] ?? 'question-circle'

        return MOBILE_TAB_ICON[tab];
    }, [tab, game]);

    const handleClick = () => {
        store.dispatch(setSelectedMobileTab(tab));

        if (onClick)
            onClick();
    }

    return (<button id={id} className={`btn ${clasz} position-relative text-center sidebar-option p-1 shadow`}
                    onClick={disabled ? null : handleClick}>


        <div className={`position-absolute top notification-badge bg-danger ${notificationClass}`}/>

        <h5 className="mb-0">
            <FontAwesomeIcon icon={['fas', icon]} style={{fontSize: iconSize}}/>
        </h5>

        {title && (
            <h5 className="mb-0 mt-3">{disabled ? '???' : title}</h5>
        )}
    </button>);
}