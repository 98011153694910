import React from "react";
import Header from "../componentsMobile/Header";
import SessionInfo from "../components/SessionInfo";
import TeamCards from "../componentsMobile/TeamCards";
import Footer from "../components/Footer";
import {useTranslation} from "react-i18next";

export default function Mobile() {
    const {t} = useTranslation('pages/player/lobby');


    return (<div id="lobby" className="mobile d-flex flex-column m-auto h-100 position-relative">
        <Header/>

        <div className="container-fluid mt-4 animate__animated animate__fast animate__fadeIn">
            <SessionInfo/>

            <p className="mb-0 small mt-2 w-100 text-center">
                {t('texts.fullscreen_info')}
            </p>

            <hr/>
            <TeamCards/>
        </div>

        <Footer/>
    </div>)
}