import React, {useEffect, useMemo, useState} from "react";

import answerCorrectIcon from "assets/img/board/ic_ans_correct_big.svg";
import answerIncorrectIcon from "assets/img/board/ic_ans_wrong_big.svg";

import "./QuestionAnswerModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QuestionCard from "components/cards/QuestionCard";
import store from "redux/store";
import {Portal} from "react-portal";
import QuestionExplanationCard from "components/cards/QuestionExplanationCard";
import {useTranslation} from "react-i18next";

export default function ShowQuestionModal({onClose, questionData}) {
    const t2 = useTranslation('common').t;

    const {card, isCorrect, answer} = questionData.data;
    const [showExplanation, setShowExplanation] = useState(false);

    const isCurrentTeam = useMemo(() => {
        return store.getState().session.showBoardForTeam === questionData.team;
    }, []);


    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        };

        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, []);

    return (<Portal>
        <div className={"show-question-modal d-flex justify-content-lg-center pt-xxl-5 pt-md-3 pt-0 px-lg-4 px-2 show"}>
            <div className="container-custom h-100 w-100">
                <div className="w-100 text-right">
                    <button type="button" className="btn btn-link float-right text-white " onClick={onClose}>
                        <FontAwesomeIcon icon={['fas', 'times']} className={'fa-2x'}/>
                    </button>
                </div>

                <div
                    className="d-flex w-100 flex-column-reverse flex-md-row justify-content-md-center content-container">
                    <div className="question-container">

                        <div className='card-container animate__animated animate__faster d-flex justify-content-center'
                             key={`${showExplanation}`}>

                            {!showExplanation && (
                                <QuestionCard questionCard={card}
                                              showResult={true}
                                              answarable={false}
                                              isLoading={false}
                                              showSubmitButton={false}
                                              isDesignatedToATeam={true}
                                              selectedOptions={answer}
                                              submittedOptions={answer}
                                              showOutcome={true}
                                              isCorrect={isCorrect}/>
                            )}

                            {showExplanation && (
                                <QuestionExplanationCard questionCard={card}/>

                            )}
                        </div>

                    </div>


                    <div
                        className="result-container ml-md-5 mb-4 mb-md-0 animate__animated animate__faster text-center">

                        {isCurrentTeam && (
                            <div className="box box-answer-result border-0 bg-transparent mt-4">
                                <img src={isCorrect ? answerCorrectIcon : answerIncorrectIcon}/>
                            </div>
                        )}

                        {card?.answers?.[0]?.explanation && (
                            <button className="btn btn-sm btn-outline-secondary mt-3"
                                    onClick={() => setShowExplanation(!showExplanation)}>

                                {showExplanation && t2('show_question')}
                                {!showExplanation && t2('show_explanation')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </Portal>);
}