import React from 'react';
import TeamCard from "../components/TeamCard";

export default function TeamCards() {
    return (<div className="team-cards-container d-flex justify-content-center align-items-center flex-wrap">
        <TeamCard number={1}/>
        <TeamCard number={2}/>
        <TeamCard number={3}/>
        <TeamCard number={4}/>
    </div>)
}