import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./BtnScrollToTop.scss";

export default function BtnScrollToTop({parentSelector = null}) {
    const handleScrollTop = () => {
        const selector = !!parentSelector ? parentSelector : '#App';

        document.querySelector(selector)?.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <button className="btn btn-primary btn-scroll-to-top position-fixed p-2 shadow" onClick={handleScrollTop}>
            <FontAwesomeIcon icon={['fas', 'arrow-up']} className="fa-2x"/>
        </button>
    )
}