import React, {useMemo, useState} from "react";
import {Portal} from "react-portal";

import "./TutorialModal.scss";
import EsgStep1 from "./stepsEsg/EsgStep1";
import EsgStep2 from "./stepsEsg/EsgStep2";
import EsgStep3 from "./stepsEsg/EsgStep3";
import EsgStep4 from "./stepsEsg/EsgStep4";
import EsgStep5 from "./stepsEsg/EsgStep5";
import GameUtil from "../../../utils/GameUtil";
import SmgxStep1 from "./stepsSmgx/SmgxStep1";
import SmgxStep2 from "./stepsSmgx/SmgxStep2";
import SmgxStep3 from "./stepsSmgx/SmgxStep3";
import SmgxStep4 from "./stepsSmgx/SmgxStep4";
import SmgxStep5 from "./stepsSmgx/SmgxStep5";
import Naviation from "./components/Naviation";
import {useSelector} from "react-redux";


export default function TutorialModal({onClose}) {
    const isMobile = useSelector(state => state.session.isMobile);
    const [step, setStep] = useState(0);

    const stepsToShow = useMemo(() => {
        return GameUtil.isCurrentGameEsg()
            ? [<EsgStep1/>, <EsgStep2/>, <EsgStep3/>, <EsgStep4/>, <EsgStep5 onClose={onClose}/>]
            : [<SmgxStep1/>, <SmgxStep2/>, <SmgxStep3/>, <SmgxStep4/>, <SmgxStep5 onClose={onClose}/>]
    }, [onClose]);

    const handleNextStep = () => {
        if (step === stepsToShow.length - 1) {
            // onClose();
        } else {
            setStep(step + 1);
        }
    };

    const handlePreviousStep = () => {
        if (step > 0)
            setStep(step - 1);
    }

    return (<Portal>
        <div id="first-access-modal"
             className="animate__animated animate__fadeIn px-4 py-4 d-flex justify-content-center">

            <div className="text-white text-center d-flex flex-column" style={{maxWidth: 1000, maxHeight: 1000}}>

                <div className="mt-1 pb-4" hidden={!isMobile}>
                    <Naviation current={step}
                               total={stepsToShow.length}
                               onClose={onClose}
                               onPrevious={handlePreviousStep}
                               onNext={handleNextStep}/>
                </div>

                <div className="flex-fill pb-5 mb-5 pb-md-0 mb-md-0 d-flex align-items-center">
                    <div className="animate__animated animate__fadeIn">
                        <div key={step} className="animate__animated animate__fadeIn animate__faster content">
                            {stepsToShow[step]}
                        </div>
                    </div>
                </div>


                <div className="mt-4 pb-5" hidden={isMobile}>
                    <Naviation current={step}
                               total={stepsToShow.length}
                               onClose={onClose}
                               onPrevious={handlePreviousStep}
                               onNext={handleNextStep}/>
                </div>
            </div>
        </div>
    </Portal>);
}

