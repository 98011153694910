import React from "react";
import SwalModalUtil from "utils/SwalModalUtil";
import OverlayUtil from "utils/OverlayUtil";
import "./Header.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AuthService from "services/player/AuthService";
import ToastUtil from "utils/ToastUtil";
import {useSelector} from "react-redux";
import Utils from "utils/Utils";
import {useTranslation} from "react-i18next";
import ButtonLeave from "../components/ButtonLeave";

export default function Header() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.header'});

    const gameUrl = useSelector(state => state.game.gameInfo.gameUrl);
    const callUrl = useSelector(state => state.game.gameInfo.callUrl);
    const sessionName = useSelector(state => state.game.gameInfo.name);
    const startAt = useSelector(state => state.game.gameInfo.startAt);

    const theme = useSelector(state => state.theme);

    const handleLeaveGame = () => {
        SwalModalUtil.confirmModal(null, t('confirms.leave_session')).then(async result => {
            if (result) {
                OverlayUtil.toggleLoadingOverlay(true);

                const gameLink = await AuthService.leave();
                window.location.replace(gameLink);
            }
        });
    }

    const handleCopyGameLink = () => {
        if (gameUrl) {
            navigator.clipboard.writeText(gameUrl);
            ToastUtil.toastSuccess(t('toasts.game_link_copied.title'), t('toasts.game_link_copied.message'));
        }
    };

    const handleOpenCallLink = () => {
        if (callUrl)
            window.open(callUrl, '_blank').focus();
    };

    return (<>
            <div id="lobby-header" className="w-100 d-flex justify-content-center">
                <div className="container-custom w-100 d-flex justify-content-between align-items-center px-3 py-2">

                    <div className="d-flex justify-content-center align-items-center py-1">
                        <img src={theme.logos.icon} alt="Mundi Game Experiences logo" className="mr-3"/>
                        <div>
                            <h4 className="m-0 font-weight-bold">{t('texts.page_title')}</h4>
                            {sessionName && (<h5 className="mb-1 text-break session-name">{sessionName}</h5>)}
                            {startAt && (<h6 className="mb-0">
                                {Utils.utcDateTimeToLocalDateStringWithoutSeconds(startAt)}
                            </h6>)}
                        </div>
                    </div>

                    <div>
                        {gameUrl && (<button type="button" className="btn btn-link btn-lg font-weight-bold p-1 mr-4"
                                             onClick={handleCopyGameLink}>
                            <FontAwesomeIcon icon={['fas', 'link']} className="mr-2"/>
                            {t('buttons.share_game_link')}
                        </button>)}

                        {callUrl && (<button type="button" className="btn btn-link btn-lg font-weight-bold p-1 mr-4"
                                             onClick={handleOpenCallLink}>
                            <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="mr-2"/>
                            {t('buttons.open_video_link')}
                        </button>)}

                        <ButtonLeave className="btn btn-link btn-lg font-weight-bold p-1"/>
                    </div>
                </div>
            </div>
        </>
    )
}