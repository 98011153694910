import React from "react";
import StepTitle from "../components/StepTitle";

export default function EsgStep5({onClose}) {
    return (<div>
        <StepTitle>
            Quando sua missão terminar, você irá descobrir qual sua colocação no ranking geral do torneio
        </StepTitle>


        <h5>
            Respire e, quando estiver confortável para iniciar, clique no botão abaixo
        </h5>

        <button className="btn btn-success shadow rounded text-uppercase mt-3" onClick={onClose}>
            Começar a minha jornada agora!
        </button>
    </div>)
}